import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from '../Services/login.service';
import { NotificationService } from '../Services/notification.service';
import { LoginMetadata } from '../Metadata/login';

@Injectable()
export class LoggedGuard implements CanActivate {
  constructor(
    private loginService: LoginService,
    private router: Router,
    private notification: NotificationService,
  ) { }

  canActivate(): boolean {
    return this._canActivate();
  }

  _canActivate(): boolean {
    const logged = this.loginService.LOGIN_TYPE !== LoginService.NOT_LOGGED;
    if (logged) {
      return true;
    }
    // ex: /ep/permit/1
    localStorage.setItem(LoginMetadata.redirectUrl, window.location.hash.substr(1));
    this.notification.warning('Login required.');
    this.router.navigate(['/mpasslogin']);
    return false;
  }
}
