import { Component } from '@angular/core';
import { FVAbstractControl, ProcessService } from '@dotgov/formviewer';
import { IDGSChangeSet } from '@dotgov/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'sup-docs-check',
  templateUrl: './sup-docs-check.component.html',
  styleUrls: ['./sup-docs-check.component.scss'],
})
export class SupDockCheckComponent extends FVAbstractControl {
  emptyGrid: boolean;

  constructor(
    private process: ProcessService,
  ) {
    super(process);

    this.process.onChange.subscribe((changeSet: IDGSChangeSet) => {
      if (changeSet.changeName !== 'grid-ApplicantDocuments-rows') {
        return;
      }
      this.emptyGrid = changeSet.changeVal === 0;
      this.process.changes = { changeName: 'show-ApplicantDocuments', changeVal: !this.emptyGrid };
    });
  }
}
