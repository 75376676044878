export class Auth {
  constructor(
    public expires: string,
    public expiresIn: number,
    public issued: string,
    public token: string,
    public tokenType: string,
    public role: string,
    public username: string,
  ) { }

  static fromResponse(response: Object) {
    return new Auth(
      response['.expires'],
      response['expires_in'],
      response['.issued'],
      response['access_token'],
      response['token_type'],
      response['role'],
      response['userName'],
    );
  }
}
