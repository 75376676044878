// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

let apiUrl = 'https://epermits.alfa-xp.com/api/';
let hideLogin = false;
let debug = {
  logs: true,
};
let enableRegistration = false;
try {
  const configs = require('./.config.json');
  if (configs) {
    if (configs.hasOwnProperty('apiUrl')) {
      apiUrl = configs['apiUrl'];
    }
    if (configs.hasOwnProperty('hideLogin')) {
      hideLogin = configs['hideLogin'];
    }
    if (configs.hasOwnProperty('debug')) {
      debug = configs['debug'];
    }
    if (configs.hasOwnProperty('enableRegistration')) {
      enableRegistration = configs['enableRegistration'];
    }
  }
} catch (e) { }

export const environment = {
  enableRegistration,
  production: false,
  apiUrl,
  hideLogin,
  debug,
  recaptchaSiteKey: '6LcAV-kUAAAAABGLf_zJE49YCV9L3ndK4kVlNM7_'
};
