import { AbstractProperty } from './abstract.tab';
import { AbstractBPMNControl } from './custom-controls';
import { EntryFactory } from '../helpers/bpmn-js';
import { BuilderService } from '../services/builder.service';

export class BindingTab extends AbstractProperty {
  private builder: BuilderService;

  constructor(element, elementRegistry, bpmnFactory, modeler, translate) {
    super(element, elementRegistry, bpmnFactory, modeler, translate, 'BindingTab', 'Binding');

    this.groups = [this.bindingGroup];
  }

  get visibleIn() {
    return ['bpmn:UserTask', 'bpmn:ServiceTask'];
  }

  get bindingGroup() {
    const group = {
      id: 'binding',
      label: 'Binding',
      entries: [],
    };

    this.taskTypeProp(group);
    this.customProps(group);
    this.builderProps(group);
    return group;
  }

  get taskTypes() {
    if (this.element.type === 'bpmn:ServiceTask') {
      return [
        { name: 'Call Stored Procedure', value: 'StoredProcedure' },
        { name: 'Read Data (DataCall)', value: 'DataCall' },
        { name: 'Write Data (DataSave)', value: 'DataSave' },
        { name: 'Generate PDF Document (PdfSave)', value: 'PdfSave' },
        { name: 'Custom Task', value: 'CustomTask' },
      ];
    }
    if (this.element.type === 'bpmn:UserTask') {
      return [
        { name: 'PDF Viewer', value: 'pdf' },
        { name: 'Form Viewer', value: 'form' },
        { name: 'Grid Viewer', value: 'grid' },
      ];
    }
  }

  get bindings() {
    const textField = (name, label, settings = {}) => ({
      name,
      label,
      type: 'textField',
      settings: Object.assign({ customValue: name }, settings),
    });
    const checkBox = (name, label, settings = {}) => ({
      name,
      label,
      type: 'checkbox',
      custom: true,
      settings: Object.assign({ customValue: name }, settings),
    });
    const selector = (name, label, options, settings = {}) => ({
      name,
      label,
      type: 'selector',
      custom: true,
      settings: Object.assign({ customValue: name, selectValues: options }, settings),
    });

    const provider = textField('provider', 'Provider');
    const recordId = textField('recordId', 'Record ID');
    const templateName = textField('templateName', 'Template Name');
    const storedProcedure = textField('storedProcedure', 'Stored Procedure');
    const targetTableName = textField('targetTableName', 'Target Table Name');
    const targetFieldName = textField('targetFieldName', 'Target Field Name');
    const targetParentName = textField('targetParentName', 'Target Parent Name');
    const targetParentRecordId = textField('targetParentRecordId', 'Target Parent Record ID');
    const stepOfProcess = textField('stepOfProcess', 'Step Of Process');

    const usePdfService = checkBox('usePdfService', 'Use PDF Service');
    const readOnly = checkBox('readonly', 'Readonly Form');

    const selectTable = selector('tableName', 'Select Table', this.tableOptions);
    const selectView = selector('viewName', 'Select View', this.viewOptions);
    const selectForm = selector('formId', 'Select Form', this.formOptions);

    return {
      StoredProcedure: [storedProcedure],
      DataCall: [selectTable],
      DataSave: [selectTable],
      PdfSave: [
        selectTable,
        templateName,
        recordId,
        targetTableName,
        targetFieldName,
        targetParentName,
        targetParentRecordId,
        usePdfService,
      ],
      CustomTask: [provider],
      form: [selectTable, selectForm, selectView, stepOfProcess, readOnly],
      pdf: [selectTable, templateName, stepOfProcess],
      grid: [selectTable, selectView],
    };
  }

  get tableOptions() {
    return this.tables.map(table => this.optionSanitize(table.name));
  }

  get viewOptions() {
    return (this.activeTable.views || ['default']).map(key => this.optionSanitize(key));
  }

  get formOptions() {
    const forms = (this.activeTable.forms || []).map(key => this.optionSanitize(key));
    return forms.length ? forms : [this.optionSanitize('default')];
  }

  get optionSanitize() {
    return (name: string, val?: string) => ({ name, value: val || name });
  }

  get tables() {
    return (this.download('models') || {}).tables || [];
  }

  get activeTableName() {
    return this.targetField.tableName || (this.tables[0] && this.tables[0].name);
  }

  get activeTable() {
    return this.tables.find(table => table.name === this.activeTableName) || {};
  }

  private get toRemove() {
    if (!window[this.toRemoveName]) {
      window[this.toRemoveName] = [];
    }
    return window[this.toRemoveName];
  }

  private set toRemove(uuids) {
    window[this.toRemoveName] = uuids;
  }

  private get toRemoveName() {
    return '____DYNAMIC_BINDING_TAB_BINDINGS_____';
  }

  private taskTypeProp(group: any) {
    const taskType = new AbstractBPMNControl(
      this.element,
      'selector',
      'taskType',
      'Task Type',
      'taskType',
      this.targetField,
      { selectValues: this.taskTypes, customValue: 'taskType' },
    );
    group.entries.push(taskType.control);
  }

  private builderProps(group: any) {
    const field = this.targetField || {};
    if (!this.element || !this.element.type || this.element.type !== 'bpmn:UserTask') {
      return;
    }
    const html = `Open Builder <i class="fa fa-pencil"></i>`;
    const click = () => {
      this.builder.builderUrl = `${field.tableName || this.activeTableName}/${field.formId ||
        'default'}`;
    };
    const openBuilderBtn = new AbstractBPMNControl(
      this.element,
      'button',
      'btn',
      'Open Builder',
      'btn',
      this.targetField,
      { actions: { click }, customHtml: html },
    );
    group.entries.push(openBuilderBtn.control);
  }

  private customProps(group: any) {
    if (!this.isVisible) {
      return;
    }
    let field = this.targetField;
    if (!field) {
      const bElem = this.extensionElements(this.element);
      field = this.generateItem(this.modeler, 'properties', bElem);
      let properties = this.dotgovElements[0];
      if (!properties) {
        properties = this.generateItem(this.modeler, 'properties', bElem);
      }
      return this.update(this.element);
    }
    if (!field.taskType && this.taskTypes) {
      field.taskType = this.taskTypes[0].value;
    }
    const toRender: any[] = this.bindings[field.taskType];
    if (!toRender) {
      return;
    }

    this.toRemove = toRender.map(target => {
      if (target.custom) {
        const change = ($event, changedControl) => {
          if (control.type === 'selector') {
            this.toRemove
              .filter(control => control.type === 'selector')
              .forEach(_control => {
                const options = this.customSelectorOptions(_control.id);
                _control.settings.selectValues = options;
                if (changedControl.id === _control.id) {
                  _control.update({ [_control.id]: $event.target.value });
                } else {
                  _control.update();
                }
              });
          }
        };
        console.log(this.targetField);
        const control = new AbstractBPMNControl(
          this.element,
          target.type,
          target.name,
          target.label,
          target.name,
          field,
          {
            strictUUID: `customcontrol-${target.name}`,
            selectValues: target.options,
            actions: Object.assign({}, { change }, target.actions),
            customHtml: target.customHtml,
            classes: target.classes,
            ...target.settings,
          },
        );
        group.entries.push(control.control);
        return control;
      }
      const control = new AbstractBPMNControl(
        field,
        '',
        target.name,
        target.label,
        target.name,
        field,
      );
      group.entries.push(
        EntryFactory[target.type]({
          id: target.name,
          label: target.label,
          modelProperty: target.name,
          set: control.customSetter.bind(control),
          get: control.customGetter.bind(control),
        }),
      );
      return control;
    });
    return group;
  }

  private customSelectorOptions(selectorName: string) {
    if (selectorName === 'tableName') {
      return this.tableOptions;
    }
    if (selectorName === 'viewName') {
      return this.viewOptions;
    }
    if (selectorName === 'formId') {
      return this.formOptions;
    }
  }
}
