import { Component } from '@angular/core';
import { ApiService } from '@dotgov/core';
import { LoginService } from '../../Services/login.service';
import { Notification } from '../../Models/notification';

@Component({
  selector: 'app-notifications-page',
  templateUrl: './notifications-page.component.html',
  styleUrls: ['./notifications-page.component.scss'],
})
export class NotificationsPageComponent {
  loading: boolean;
  notifications: Notification[] = [];
  unreaded = 0;
  page = 0;
  itemsOnPage = 10;

  constructor(
    private api: ApiService,
    private loginService: LoginService,
  ) {
    this.loading = true;
    this.loginService.loginChanged.subscribe((loginData) => {
      this.loading = false;
      this.loadNotifications();
    });
    this.loadNotifications();
  }

  markAsRead(notification: Notification) {
    if (notification.Readed) {
      return;
    }
    this.api.post('notifications', ['markasread'], {}, { notifications: [notification.Id] }).then((res) => {
      const targetNotification = this.notifications.find((_notification: Notification) => _notification.Id === notification.Id);
      if (!res || !targetNotification) {
        return;
      }
      targetNotification.Readed = true;
    });
  }

  markAllAsRead() {
    const unreadedIds = this._unreaded.filter((notification) => !notification.Readed).map((notification) => notification.Id);
    if (!unreadedIds.length) {
      return;
    }
    this.api.post('notifications', ['markasread'], {}, { notifications: unreadedIds }).then((res) => {
      if (!res) {
        return;
      }
      this._unreaded.forEach((notification) => notification.Readed = true);
    });
  }

  get logged() {
    return this.loginService.LOGIN_TYPE !== LoginService.NOT_LOGGED;
  }

  get _unreaded() {
    return this.notifications.filter((_notification: Notification) => !_notification.Readed);
  }

  private loadNotifications() {
    this.notifications = [];
    // if (this.logged) {
    //   this.api.get('notifications').then((response) => {
    //     this.notifications = (response.data && response.data.rows || []).sort((a, b) => <any>new Date(b.Date) - <any>new Date(a.Date));
    //   });
    // } else {
    //   this.notifications = [];
    // }
  }
}
