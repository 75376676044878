import { Component, OnInit } from '@angular/core';
import { IssuedPermit } from '../../Models/issuedPermit';
import { ActivatedRoute } from '@angular/router';
import { ApiService, ApiResponse } from '@dotgov/core';
import { LanguageService } from '../../Services/language.service';
import { Permit } from '../../Models/permit';
import { Lang } from '../../Models/language';
import { environment } from '../../../environments/environment';
import { DownloadHelper } from '../../Helpers/download.helper';
import { ApiHelper } from '../../Helpers/api.helper';
import '../../../assets/cookie.notice.js';

@Component({
  selector: 'app-issued-permit',
  templateUrl: './issued-permit.component.html',
  styleUrls: ['./issued-permit.component.scss'],
})
export class IssuedPermitComponent implements OnInit {
  externalKey: string;
  loading = true;
  showModal: boolean;
  permit: IssuedPermit;
  lang: Lang;
  topPermits: Permit[] = [];
  authority: Object = {};

  statusesId = [
    'Valid',
    'Prolonged',
    'Canceled',
    'Withdrawal',
    'Suspended',
    'Expired',
  ];

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private language: LanguageService,
    private api: ApiService,
  ) { }

  ngOnInit() {
    this.fetchRouteInfo();
  }

  get langSuffix() {
    return this.lang && this.lang.Code !== 'en' ? `_${this.lang.Code}` : ``;
  }

  get statusClass() {
    // @todo: Talk with backend to have some convention about statuses (now color is unreadable for frontend)
    const id = this.permit.StatusId - 1;
    const colors = ['badge badge-success', 'badge badge-success', 'badge badge-primary',
      'badge badge-warning', 'badge badge-info', 'badge badge-danger', 'badge badge-default'];
    return colors[id] || 'badge badge-default';
  }

  get permitTitle() {
    return this.permit && this.permit[`PermitTitle_${this.language.language.Code}`];
  }

  get permitStatus() {
    return this.permit && this.permit[`PermitStatus_${this.language.language.Code}`];
  }

  downloadPdf() {
    if (!this.externalKey) {
      return;
    }
    this.loading = true;
    const options = {
      headers: {
        'Accept': '*/*',
      },
      skipCache: true,
      responseType: 'arraybuffer',
    };
    this.api.get('PDFReport',
      ['vw_EP_Permits_External', 'PermitExtract', this.permit['Keyfield'].toString()],
      {},
      options,
    ).then((data: ApiResponse) => {
      if (data.error) {
        if (environment.debug) {
          console.warn('Could not download issued permit pdf', data.error);
        }
        this.loading = false;
        return;
      }

      const file = new Blob([data.data], { type: 'application/pdf' });
      const filename = `issued_permit_${this.permit.PermitNumber}.pdf`;
      DownloadHelper.triggerDownload(file, filename);
      this.loading = false;
    });
  }

  private fetchRouteInfo() {
    this.route.params.subscribe(params => {
      this.externalKey = params['externalKey'].replace('_', '/');
      this.lang = this.language.language;
      if (this.language.loaded) {
        this.fetchAll();
      }
      this.language.languageChanged.subscribe((lang: Lang) => {
        this.lang = lang;
        this.fetchAll();
      });
    });
  }

  private fetchAll() {
    return Promise.all([
      this.loadTop3(),
      this.loadPermit(),
    ]).then((response) => {
      this.loading = false;
    }).catch((e) => {
      console.error(e);
      this.loading = false;
    });
  }

  private loadPermit() {
    const _case = this.externalKey;
    this.loading = true;
    this.apiService.get('public', ['data', `IssuedPermits`], ApiHelper.setupLanguage({
      page: 1,
      limit: 1,
      start: 0,
      filter: [{ value: _case, property: 'ExternalKey' }],
    }, this.lang, true)).then((response) => {
      if (response.error) {
        return Promise.reject(response.error);
      }
      this.permit = response.data && response.data.data[0];
      this.loading = false;
      return Promise.resolve(this.permit);
    });
  }

  private loadTop3() {
    this.apiService.get('public', ['data', `TopPermits`], { page: 1, start: 0, limit: 3 }).then((response) => {
      if (response.error) {
        return Promise.reject(response.error);
      }
      this.topPermits = response.data && response.data.data;
      return Promise.resolve(this.topPermits);
    });
  }
}
