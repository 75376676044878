import { Component, Input } from '@angular/core';
import { ApiService, ApiResponse } from '@dotgov/core';
import { Lang, IssuedPermit } from '../../../Models';
import { LanguageService } from '../../../Services/language.service';
import { ApiHelper } from '../../../Helpers/api.helper';

@Component({
  selector: 'app-permits-issued',
  templateUrl: './permits-issued-list.component.html',
  styleUrls: ['../../Home/permits/permits.component.scss'],
})
export class PermitsIssuedListComponent {
  permits: IssuedPermit[] = [];
  currentPage = 0;
  sortCol: string = this.NAME_SORT;
  sortAsc = true;
  loading = true;

  @Input() itemsOnPage: number;
  @Input() set filter(filter: object) {
    this.loadPermits(this.language.language, filter);
  }

  constructor(private apiService: ApiService, private language: LanguageService) {
    this.language.languageChanged.subscribe((lang: Lang) => {
      this.loadPermits(lang);
    });
  }

  /**
   * Update sorting type, triggered by click
   * @param {String} type default 'title'
   */
  updateSort(type: string = this.NAME_SORT) {
    const sortCol = type === 'date' ? this.DATE_SORT : this.NAME_SORT;

    if (this.sortCol === sortCol) {
      this.sortAsc = !this.sortAsc;
    }

    this.sortCol = sortCol;
    this.sort(this.permits);
  }


  /**
   * Sorting function used by permits sort
   * @param {Any} a
   * @param {Any} b
   * @return {Number}
   */
  sortAscBy(a, b): number {
    const target = this.sortCol === this.DATE_SORT
      ? 'AvailableFrom'
      : 'SortOrder';

    let asc;
    if (typeof a[target] === 'string') {
      asc = a[target].localeCompare(b[target]);
    } else {
      asc = a[target] - b[target];
    }

    return asc;
  }

  getRightSortIcon(sortCol: string) {
    sortCol = sortCol === 'date' ? this.DATE_SORT : this.NAME_SORT;
    return (
      (sortCol === this.sortCol)
      && (this.sortAsc && 'fa fa-sort-amount-asc' || 'fa fa-sort-amount-desc'))
      || 'fa fa-sort-amount-asc';
  }

  permitLabel(permit: IssuedPermit) {
    return `${permit.CaseNumber} ${permit[`PermitTitle_${this.language.language.Code}`]}`;
  }

  sanitizedCaseNumber(caseNumber: string) {
    return caseNumber.replace('\/', '_');
  }

  get NAME_SORT(): string {
    return 'title';
  }

  get DATE_SORT(): string {
    return 'AvalaibleFrom';
  }

  private sort(rawPermits: IssuedPermit[]) {
    let permits = rawPermits.sort(this.sortAscBy.bind(this));

    if (!this.sortAsc) {
      permits = permits.reverse();
    }

    this.currentPage = 0;
    return permits;
  }

  private loadPermits(lang?: Lang, filter?: Object) {
    this.loading = true;
    const defaultParams = ApiHelper.setupLanguage({ page: 0, limit: 0, start: 0 }, lang, true);
    this.apiService.get('public', ['data', `IssuedPermits`], defaultParams).then((response: ApiResponse) => {
      let permits = response.data && response.data.data || [];
      if (filter) {
        this.permits = this.filterPermits(permits, filter);
      } else {
        this.permits = permits;
      }
      this.loading = false;
    });
  }

  private filterPermits(permits: IssuedPermit[], filter: Object) {
    const keySet = (key, obj = filter) => {
      const item = (obj || {})[key];
      if (!obj.hasOwnProperty(key)) {
        return true;
      }
      if (Array.isArray(item)) {
        return item.length;
      }
      return item !== null && item !== undefined && !Array.isArray(item);
    };
    const searchFilter = (permit: IssuedPermit) => {
      if (!keySet('searchControl') || !keySet('PermitTitle', permit)) {
        return true;
      }

      const searchIn = (`${permit['PermitNumber']} ${permit['CaseNumber']} ${permit[`PermitTitle_${this.language.language.Code}`]} ${permit['SeriaNumber']} ${permit['CustomNumber']} ${permit['IDNO']} ${permit['LegalEntityName']}` || '').toLowerCase();
      return searchIn.indexOf(filter['searchControl'].toLowerCase()) !== -1;
    };
    const authorityFilter = (permit: IssuedPermit) => {
      if (!keySet('authorityControl') || !keySet('AuthorityId', permit)) {
        return true;
      }

      return Number(permit.AuthorityId) === Number(filter['authorityControl']);
    };
    const issuedFromFilter = (permit) => {
      if (!keySet('dateOfIssue') || !keySet('PermitDate', permit)) {
        return true;
      }

      const filterFrom = filter['dateOfIssue'][0];
      const filterTo = filter['dateOfIssue'][1];

      return this.isDateInRange(filterFrom, filterTo, [permit['PermitDate']]);
    };
    const expiredFromFilter = (permit) => {
      if (!keySet('dateOfExpire') || !keySet('ValidFrom', permit)) {
        return true;
      }

      const validFrom = filter['dateOfExpire'][0];
      const validTo = filter['dateOfExpire'][1];
      return this.isDateInRange(validFrom, validTo, [permit['ValidFrom'], permit['ValidTo']]);
    };
    const statusFilter = (permit) => {
      if (!keySet('statusControl') || !keySet('StatusId', permit)) {
        return true;
      }

      return Number(permit.StatusId) === Number(filter['statusControl']);
    };

    let _permits = permits;
    if (filter['searchControl']) {
      _permits = _permits.filter(searchFilter);
    }
    if (filter['authorityControl']) {
      _permits = _permits.filter(authorityFilter);
    }
    if (filter['statusControl']) {
      _permits = _permits.filter(statusFilter);
    }
    if (filter['dateOfIssue']) {
      _permits = _permits.filter(issuedFromFilter.bind(this));
    }
    if (filter['dateOfExpire']) {
      _permits = _permits.filter(expiredFromFilter.bind(this));
    }
    _permits = this.sort(_permits);

    return _permits;
  }

  /**
   * @param from
   * @param to
   * @param date
   * @returns {boolean}
   */
  private isDateInRange(from: Date, to: Date, dates: Date[]): boolean {
    const isValidFrom = this.datesCompare(from, dates[0]) !== 1;
    const isValidTo = this.datesCompare(to, dates[1] || dates[0]) !== -1;
    return isValidFrom && isValidTo;
  }

  private convert(d) {
    if (d === undefined || d === null) {
      return NaN;
    }
    const isDate = d.constructor === Date;
    const isArray = d.constructor === Array;
    const isNum = d.constructor === Number;
    const isString = d.constructor === String;
    const isObject = typeof d === 'object';
    return (
      isDate && d
      || isArray && new Date(d[0], d[1], d[2])
      || (isNum || isString) && new Date(d)
      || isObject && new Date(d.year, d.month, d.date)
      || NaN
    );
  }

  /**
   * Return number based on compare result
   * a > b => 1
   * b < a => -1
   * a = b => 0
   * @param a
   * @param b
   */
  private datesCompare(a: any, b: any): Number {
    return (
      isFinite(a = this.convert(a).valueOf()) && isFinite(b = this.convert(b).valueOf())
        ? (a > b as any) - (a < b as any)
        : NaN
    );
  }

}
