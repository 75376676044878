import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from '../../Services/data.service';
import { User } from '../../Models/user';
import { LoginService } from '../../Services/login.service';

@Component({
  selector: 'app-applicant-info',
  templateUrl: './applicant-info.component.html',
  styleUrls: ['./applicant-info.component.scss'],
})
export class ApplicantInfoComponent {
  helloMessage = 'This is your personal area for profile and permitting documents management.';
  private userContext: User = {};
  constructor(private _sanitizer: DomSanitizer, private dataService: DataService, private loginService: LoginService) {
    this.dataService.userContext.then((userContext) => {
      this.userContext = userContext;
    });
    this.dataService.userContextChanged.subscribe((userContext) => {
      this.userContext = userContext;
    });
  }

  get fullName() {
    let team;
    if (this.dataService.currentUserTeam) {
      team = this.userContext.teams.find(t => t.teamId === this.dataService.currentUserTeam);
    }
    return team ? team.name : this.userContext.fullName;
  }

  get email() {
    return this.userContext.email;
  }

  getBackground(image) {
    return this._sanitizer.bypassSecurityTrustStyle(`linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${image})`);
  }
}
