export class DownloadHelper {
  /**
 * Creates a fake 'a' tag with pdf download, trigger it then delete
 * @param file
 * @param filename
 */
  static triggerDownload(file: Blob, filename: string) {
    // IE10+
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, filename);
    } else { // Others
      const a = document.createElement('a');
      const url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }
}
