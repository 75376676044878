import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ApiService, ApiResponse } from '@dotgov/core';
import { InitialDiagram } from '@dotgov/processbuilder';

@Component({
  selector: 'app-bpmn',
  templateUrl: './bpmn.component.html',
  styleUrls: ['./bpmn.component.scss', '../Home/filter/filter.component.scss', '../Home/permits/permits.component.scss'],
})
export class BPMNComponent implements OnInit {
  preview: boolean;
  url: string;
  itemsOnPage = 15;
  currentPage = 1;
  actionButtons = [];
  selected = [];
  columns = [];
  processList = [];
  config = {};

  @ViewChild('actionsCell') actionsCell: TemplateRef<any>;

  private _searchText: string;
  constructor(private router: Router, private api: ApiService) {
    this.fetchRouteInfo();
  }

  ngOnInit() {
    this.actionButtons = [
      {
        name: 'Edit',
        action: 'open',
        class: 'btn btn-primary',
        iconClass: 'fa fa-pencil',
        innerHtml: '<i class="fa fa-pencil"></i>',
        renderInline: true,
        renderOutline: true,
      },
      {
        name: 'Preview',
        action: 'preview',
        class: 'btn btn-primary',
        iconClass: 'fa fa-eye',
        renderInline: true,
        renderOutline: true,
      },
      {
        name: 'New',
        action: 'create',
        class: 'btn btn-primary',
        iconClass: 'fa fa-plus-square',
        renderInline: false,
        renderOutline: true,
      },
      {
        name: 'Remove',
        action: 'delete',
        class: 'btn btn-danger',
        iconClass: 'fa fa-eraser',
        renderInline: true,
        renderOutline: true,
      },
    ];

    this.config = {
      availableColumns: [
        { name: 'name', title: 'Process Name' },
        // { name: 'actions', title: 'Actions', maxWidth: 200, cellTemplate: this.actionsCell },
      ],
      displayedColumns: [
        { name: 'name' },
        // { name: 'actions' },
      ]
    };
  }

  userAction(action) {
    if (action.type === 'dblclick') {
      this.openPreview();
    }
  }

  gotoList() {
    this.router.navigate(['/ep/bpmn']);
  }

  buttonClicked(button, fromInline?: string) {
    // in new version of datagrid emitted object is different
    button = (button.action) ? button : button.button;
    const active = fromInline || this.selectedRow;
    if (button.action === 'open') {
      return this.openEdit(active);
    } if (button.action === 'preview') {
      return this.openPreview(active);
    } if (button.action === 'create') {
      return this.createRow();
    } if (button.action === 'delete') {
      return this.deleteRow(active);
    } else {
      alert(`button ${button.name} clicked.`);
    }
  }

  createRow(processName = window.prompt('Process name?')) {
    if (!processName) {
      return;
    }
    const nameValidator = /^[a-zA-Z_0-9]+$/;
    if (!nameValidator.test(processName)) {
      return alert(`Cannot create process with name ${processName}. You can use only letters, numbers and underscore.`);
    }
    this.api.get('Process', [processName]).then((response: ApiResponse) => {
      if (response.data) {
        console.log(response);
        return alert(`Process with name ${processName} already exists.`);
      }
      this.api.put('Process', [processName], {}, InitialDiagram).then((putResponse) => {
        if (putResponse.error) {
          console.log(putResponse);
          return;
        }
        this.api.remove(`${this.api.getURL('process')}|get`);
        setTimeout(() => {
          return this.router.navigate(['/ep/bpmn', processName]);
        }, 100);
      });
    });
  }

  deleteRow(active = this.selectedRow) {
    if (!active) {
      return;
    }
    alert('Cannot perform this action.');
  }

  openEdit(active = this.selectedRow) {
    if (!active) {
      return;
    }
    return this.router.navigate(['/ep/bpmn', active.name]);
  }

  openPreview(active = this.selectedRow) {
    if (!active) {
      return;
    }
    return this.router.navigate(['/ep/bpmn', active.name, 'preview']);
  }

  get search(): string {
    return this._searchText;
  }

  get selectedRow() {
    return this.selected && this.selected[0] || {};
  }

  set search(searchText: string) {
    this._searchText = searchText;
  }

  private fetchRouteInfo() {
    this.router.events.subscribe(event => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      const [url, process, preview] = event.url.split('/').splice(2);
      if (!process) {
        this.loadList();
        return;
      }
      this.preview = !!preview;
      this.url = `Process/${process}`;
    });
  }

  private loadList() {
    this.preview = false;
    this.url = null;
    this.processList = [];
    this.api.get('process').then((response) => {
      this.ngOnInit();
      if (response.error) {
        console.error(response);
        return;
      }
      this.processList = (response.data && response.data.items).map((processName) => ({ name: processName, actions: this.actionButtons }));
    });
  }
}
