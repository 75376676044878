import { Component, Input, ViewChild, Output, EventEmitter, AfterViewInit, HostListener } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/';
import { Subscription } from 'rxjs';
import { Task, ProcessService } from '@dotgov/formviewer';
import { NotificationService } from '../../../Services/notification.service';
import { LanguageService } from '../../../Services/language.service';
import { Permit } from '../../../Models/permit';
import { Lang } from '../../../Models/language';
import { ApiResponse } from '@dotgov/core';

declare function clearFrmGlobals();

@Component({
  selector: 'app-permit-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class PermitModalComponent implements AfterViewInit {
  subscriptions: Subscription[] = [];
  loading: boolean;
  currentTask: Task;
  title: string;
  lang: Lang;
  error: string;

  @ViewChild('modalRef', { static: true }) modalRef: BsModalRef;
  @Output() modalClosed: EventEmitter<any> = new EventEmitter();

  @Input() set permit(permit: Permit) {
    this._permit = permit;
    this.modalRef = this.modalService.show(this.modalRef, { class: 'modal-lg', ignoreBackdropClick: true, keyboard: false });
    this._modalClosed = false;
  }

  private _modalClosed: boolean;

  private _permit: Permit;
  private processInstanceId: string;
  constructor(
    private modalService: BsModalService,
    private process: ProcessService,
    private notification: NotificationService,
    private language: LanguageService,
  ) {

    this.lang = this.language.language;
    this.language.languageChanged.subscribe((lang: Lang) => {
      this.lang = lang;
    });
    this.process.onAnyError.subscribe((response: { processId: string, error: string }) => {
      if (response.processId !== this.processInstanceId && this.processInstanceId) {
        return;
      }
      this.error = response.error;
    });
    this.subscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        this.modalClosed.emit(true);
        this._modalClosed = true;
      }),
    );
  }

  @HostListener('window:keydown.esc', ['$event'])
  onEsc(event: any): void {
    if (this.modalService.getModalsCount() > 1) {
      return;
    }
    if (event.keyCode === 27) {
      event.preventDefault();
    }

    if (!this._modalClosed && window.confirm('Are you sure you want to close the modal window?')) {
      this.hide();
    }
  }

  ngAfterViewInit() {
    this.loadPermitForm();
  }

  updateTitle(title: string) {
    this.title = title;
  }

  hide() {
    this.modalRef.hide();
    clearFrmGlobals();
  }

  onTaskDone(taskStatus) {
    if (taskStatus && !taskStatus.done) {
      return;
    }
    this.hide();
    this.notification.success('Permit successfully closed.');
  }

  get permit(): Permit {
    return this._permit;
  }

  private loadPermitForm() {
    this.loading = true;
    this.process.start(['EF_CreateCase'], { PermitTypeId: this._permit.ID }).then((startResponse: ApiResponse) => {
      this.processInstanceId = startResponse.data['processInstanceId'];
      this.error = this.process.errorFromResponse(startResponse);
      if (this.error) {
        return;
      }
      this.process.next(this.processInstanceId).then((nextResponse: ApiResponse) => {
        this.error = this.process.errorFromResponse(nextResponse);
        if (this.error) {
          return;
        }
        this.loading = false;
        const task: Task = nextResponse.data;
        this.currentTask = task;
        if (task && task.clientComponent && task.componentConfig.title) {
          this.title = task.componentConfig.title;
        }
      });
    });
  }
}
