import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LogoutComponent } from '../Components/Logout/logout.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollToModule } from 'ng2-scroll-to';
import { SharedModule } from './shared/shared.module';
import {LazyModule} from './lazy/lazy.module'
import { AppComponent } from '../Components/Layout/app.component';
import { FooterComponent } from '../Components/Layout/footer/footer.component';
import { HeaderComponent } from '../Components/Layout/header/header.component';
import { AppRoutingModule } from './app.routing';
import { LoginComponent } from '../Components/Login/login.component';
import { ProfileSelectorComponent } from '../Components/Layout/header/profile-selector/profile-selector.component';
import { LanguageSelectorComponent } from '../Components/Layout/header/language-selector/language-selector.component';
import { RolesSelectorComponent } from '../Components/Layout/header/roles-selector/roles-selector.component';
import { TeamSelectorComponent } from '../Components/Layout/header/team-selector/team-selector.component';
import { FAQComponent } from '../Components/FAQ/faq.component';
import { StaticComponent } from '../Components/Static/static.component';
import { MPassLoginComponent } from '../Components/MPassLogin/mpass.component';
import { MPassLoggedinComponent } from '../Components/MPassLoggedIn/mpass-loggedin.component';
import { IssuedPermitComponent } from '../Components/issued-permit/issued-permit.component';
import { NotificationsPageComponent } from '../Components/notifications-page/notifications-page.component';
import { NotificationsComponent } from '../Components/Layout/header/notifications/notifications.component';
import { HttpClientModule } from '@angular/common/http';
import { LanguageService } from '../Services/language.service';
import { LoginService } from '../Services/login.service';
import { DataService } from '../Services/data.service';
import { NotificationService } from '../Services/notification.service';
import { LoggedGuard } from '../Guards/logged.guard';
import { MonitorService } from '../Services/monitor.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { ChangePasswordComponent } from '../Components/ChangePwd/change-password.component';
import { ConfirmEmailComponent } from '../Components/ConfirmEmail/confirm-email.component';
import { UnlockAccountComponent } from '../Components/UnlockAccount/unlock-account.component';
import { RestorePasswordComponent } from '../Components/RestorePwd/restore-password.component';
import { ForeignRegSuccessComponent } from '../Components/ForeignRegSuccess/foreignregsuccess.component';
import { HelpComponent } from '../Components/help/help.component';

const toastrDefaultOptions = {
  positionClass: 'toast-bottom-right',
  preventDuplicates: true,
  enableHtml: true,
};

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    LoginComponent,
    LogoutComponent,
    LanguageSelectorComponent,
    ProfileSelectorComponent,
    RolesSelectorComponent,
    FAQComponent,
    StaticComponent,
    MPassLoginComponent,
    ForeignRegSuccessComponent,
    MPassLoggedinComponent,
    IssuedPermitComponent,
    NotificationsComponent,
    NotificationsPageComponent,
    ChangePasswordComponent,
    RestorePasswordComponent,
    ConfirmEmailComponent,
    TeamSelectorComponent,
    UnlockAccountComponent,
    HelpComponent
  ],
  imports: [
    ToastrModule.forRoot(toastrDefaultOptions),
    SharedModule,
    LazyModule,
    HttpClientModule,
    AppRoutingModule,
    ScrollToModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
  ],
  providers: [
    LanguageService,
    LoginService,
    DataService,
    NotificationService,
    LoggedGuard,
    MonitorService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
