import { Injectable, EventEmitter } from '@angular/core';
import { ApiService, ApiResponse } from '@dotgov/core';
import { DataService } from './data.service';
import { environment } from '../../environments/environment';
import { Auth, User } from '../Models';
import { LoginMetadata } from '../Metadata/login';
import { Router } from '@angular/router';
import { NotificationService } from './notification.service';
import { get } from 'lodash';

@Injectable()
export class LoginService {

  static get NOT_LOGGED() {
    return DataService.NOT_LOGGED;
  }

  // also foreign user login
  static get CUSTOM_LOGIN() {
    return 1;
  }

  static get MPASS_LOGIN() {
    return 2;
  }

  static get HIDE_LOGIN() {
    return environment.hideLogin;
  }

  readonly NO_TEAM = '';
  // userContextChanged = new EventEmitter<any>();
  lastUserContext: any = {};
  private _loginChanged = new EventEmitter<any>();

  constructor(
    private apiService: ApiService,
    private dataService: DataService,
    private router: Router,
    private notification: NotificationService
  ) {

    this.dataService.userContext.then((userContext) => {
      // sometimes after mpass log in user is logged out
      const shouldValidate = !window.location.href.includes('mpassloggedin');
      if (!this.isValidLogin(userContext) && shouldValidate) {
        this.logout();
        return;
      }
      // this.userContextChanged.emit(userContext);
      this.lastUserContext = userContext;
    });
  }

  get LOGIN_TYPE() {
    return this.dataService.loggedType;
  }

  get loginChanged() {
    return this._loginChanged;
  }

  set loggedBy(value: any) {
    this.updateLoginStatus(value);
  }

  private updateLoginStatus(loginStatus: number) {
    this.dataService.loggedType = loginStatus
    this.loginChanged.emit(loginStatus);
  }

  get userContext() {
    return localStorage.getItem('userContext') && JSON.parse(localStorage.getItem('userContext'));
  }

   /**
   * @param formData
   */
  customLogin(formData: Object): Promise<{ auth: Auth, userContext: User, appData: Object }> {
    const body = `grant_type=password&userName=${formData['userName']}&password=${formData['password']}`;
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    return this.apiService.post('token', [], undefined, body, { headers, skipCache: true }).then((response: ApiResponse) => {
      this.dataService.setDefaultUserTeam();
      const responseErr = response.error;
      if (responseErr) {
        // this.notification.error(responseErr.error.error_description);
        return Promise.reject(get(response.error, 'error.error_description', ''));
      }
      return this.onLoginSuccess(LoginService.CUSTOM_LOGIN, response.data);
    });
  }

  getMPassLoggedIn(loginResults: Object): Promise<Object> {
    this.dataService.clearData();
    return new Promise((resolve, reject) => {
      this.apiService.post('loggedin', [], undefined, loginResults).then((response: ApiResponse) => {
        this.dataService.setDefaultUserTeam();
        const userData = response.data;

        if (userData && userData.canceled) {
          return resolve('Canceled');
        }

        if (response.error) {
          console.log(response.error);
          return reject(get(response.error, 'error.message', ''));
        }

        if (!userData.userName || !userData.access_token) {
          console.log('Username missing from loggedin response.');
          return reject('MPass login failed!');
        }

        return this.onLoginSuccess(LoginService.MPASS_LOGIN, userData).then(resolve, reject);
      }).catch((e) => {
        console.log(e);
        this.dataService.clearData();
        return reject(e);
      });
    });
  }

  logout() {
    this.dataService.clearData();
    this.apiService.clearCache();
    return Promise.all([this.dataService.forceLoadUserContext(), this.dataService.forceLoadAppData()]).then(() => {
      // this.userContextChanged.emit(null);
      this.lastUserContext = null;
      this.updateLoginStatus(LoginService.NOT_LOGGED);
    });
  }

  isValidLogin(userContext): boolean {
    if ( !userContext['email'] || !userContext['role']) {
      return false;
    }
    return true;
  }

  checkForPasswordReset() {
    this.dataService.userContext.then((userContext) => {
      if (userContext.needResetPassword) {
        this.router.navigate(['/changepwd']);
      }
    });
  }

  private onLoginSuccess(loginType: number, data: any): Promise<any> {
    this.dataService.clearData();
    this.apiService.clearCache();
    const auth = Auth.fromResponse(data);
    localStorage.setItem('auth', JSON.stringify(auth));
    // mpass logic
    if (data.externalSessionIndex) {
      localStorage.setItem(LoginMetadata.externalSessionIndexKey, JSON.stringify(data.externalSessionIndex));
    }

    const headers = { Authorization: `Bearer ${data.access_token}` };
    return this.dataService.forceLoadUserContext(headers).then((userContext: User) => {
      if (!this.isValidLogin(userContext)) {
        this.notification.error('Invalid login');
        return Promise.reject();
      }
      return this.dataService.forceLoadAppData(headers).then((appData) => {
        // this.userContextChanged.emit(userContext);
        this.lastUserContext = userContext;
        this.updateLoginStatus(loginType);
        const result = { auth, userContext, appData };
        // return Promise.resolve({ auth, userContext, appData });
        return new Promise(res => res(result));
      });
    });
  }

}
