import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from '../../Services/login.service';
import { Router } from '@angular/router';
import { DataService } from '../../Services/data.service';
import { NotificationService } from '../../Services/notification.service';
import { environment } from '../../../environments/environment';
import { LoginMetadata } from '../../Metadata/login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginForm: FormGroup;
  registrationEnabled: boolean;
  submitted = false;
  private appData = {};

  constructor(
    private loginService: LoginService,
    private router: Router,
    private dataService: DataService,
    private notification: NotificationService,
  ) {
    this.loginForm = new FormGroup({
      userName: new FormControl('', [<any>Validators.required]),
      password: new FormControl('', [<any>Validators.required]),
    });
    this.dataService.clearData();
    this.loginService.loggedBy = LoginService.NOT_LOGGED;
    this.registrationEnabled = environment.enableRegistration;
    this.dataService.appDataChanged.subscribe((data) => this.appData = data);
  }

  login() {
    this.submitted = true;
    if (!this.loginForm.valid) {
      return;
    }

    const data = this.loginForm.value;
    const redirectUrl = localStorage.getItem(LoginMetadata.redirectUrl);
    const defaultUrl = ['/ep', 'container', 'app-ApplicantDashboard'];
    const redirect = () => {
      this.router.navigate(redirectUrl ? [redirectUrl] : defaultUrl);
      localStorage.removeItem(LoginMetadata.redirectUrl);
    };
    this.loginService.customLogin(data).then(() => {
    redirect();
    this.loginService.checkForPasswordReset();
    }).catch((e) => {
        this.notification.error(e || 'The email or password is incorrect');
    });
  }

  get appTitle() {
    return this.appData['title'];
  }

}
