const stringAttr = name => {
  return {
    name,
    isAttr: true,
    type: 'String',
  };
};

const boolAttr = name => {
  return {
    name,
    isAttr: true,
    type: 'Boolean',
  };
};

const customAttr = (type: string) => {
  return {
    name: type.toLowerCase(),
    type,
  };
};

export const DotGovModdle = {
  name: `DotGovServer`,
  prefix: 'dotgov',
  uri: 'http://dotGovServer.com/bpmn/2017',
  xml: {
    tagAlias: 'lowerCase',
  },
  types: [
    {
      name: 'Properties',
      extends: [
        'bpmn:UserTask',
        'bpmn:ServiceTask',
        'bpmn:ScriptTask',
        'bpmn:ExclusiveGateway',
        'bpmn:SequenceFlow',
        'bpmn:Lane',
        'bpmn:SendTask',
      ],
      superClass: ['Element'],
      properties: [
        stringAttr('taskType'),
        stringAttr('referenceTableName'),
        stringAttr('tableName'),
        stringAttr('formId'),
        stringAttr('viewName'),
        stringAttr('storedProcedure'),
        stringAttr('templateName'),

        stringAttr('recordId'),
        stringAttr('targetTableName'),
        stringAttr('targetFieldName'),
        stringAttr('targetParentFieldName'),
        stringAttr('targetParentRecordId'),
        boolAttr('usePdfService'),
        stringAttr('provider'),
        stringAttr('stepOfProcess'),

        stringAttr('color'),

        customAttr('Input'),
        customAttr('Output'),
        customAttr('Buttons'),
        customAttr('Switch'),
        customAttr('Conditions'),

        {
          name: 'readonly', // formIsReadonly',
          isAttr: true,
          type: 'Boolean',
          value: false,
        },
      ],
    },
    {
      name: 'Input',
      properties: [
        {
          name: 'values',
          type: 'Field',
          isMany: true,
        },
      ],
    },
    {
      name: 'Output',
      properties: [
        {
          name: 'values',
          type: 'Field',
          isMany: true,
        },
      ],
    },
    {
      name: 'Field',
      properties: [
        {
          name: 'name',
          isAttr: true,
          type: 'String',
        },
        {
          name: 'value',
          isAttr: true,
          type: 'String',
        },
        {
          name: 'source',
          isAttr: true,
          type: 'String',
        },
        {
          name: 'destination',
          isAttr: true,
          type: 'String',
        },
      ],
    },

    {
      name: 'Buttons',
      properties: [
        {
          name: 'values',
          type: 'Button',
          isMany: true,
        },
      ],
    },
    {
      name: 'Button',
      properties: [
        {
          name: 'command',
          isAttr: true,
          type: 'String',
        },
        {
          name: 'text',
          isAttr: true,
          type: 'String',
        },
      ],
    },
    {
      name: 'Switch',
      properties: [customAttr('Case'), customAttr('Otherwise')],
    },
    {
      name: 'Case',
      properties: [
        {
          name: 'condition',
          isAttr: true,
          type: 'String',
        },
        {
          name: 'action',
          isAttr: true,
          type: 'String',
        },
      ],
    },
    {
      name: 'Otherwise',
      properties: [
        {
          name: 'action',
          isAttr: true,
          type: 'String',
        },
      ],
    },
    {
      name: 'Conditions',
      properties: [
        {
          name: 'values',
          type: 'Condition',
          isMany: true,
        },
      ],
    },
    {
      name: 'Condition',
      properties: [
        {
          name: 'logical',
          isAttr: true,
          type: 'String',
        },
        {
          name: 'name',
          isAttr: true,
          type: 'String',
        },
        {
          name: 'operator',
          isAttr: true,
          type: 'String',
        },
        {
          name: 'value',
          isAttr: true,
          type: 'String',
        },
        {
          name: 'items',
          type: 'Condition',
          isMany: true,
        },
      ],
    },
    {
      name: 'NESendTask',
      extends: ['bpmn:SendTask'],
      properties: [
        {
          name: 'template-1',
          isAttr: true,
          type: 'String',
          value: '11111111111',
        },
        {
          name: 'Task Priority 2',
          isAttr: true,
          label: 'Task Priority lbl',
          type: 'Dropdown',
          value: '50',
          choices: [
            {
              name: 'low',
              value: '20',
            },
            {
              name: 'medium',
              value: '50',
            },
            {
              name: 'height',
              value: '100',
            },
          ],
        },
      ],
    },
    {
      name: 'NEGW',
      extends: ['bpmn:ExclusiveGateway'],
      properties: [
        {
          name: 'condition',
          isAttr: true,
          type: 'String',
        },
      ],
    },
    {
      name: 'NESubProcess',
      extends: ['bpmn:SubProcess'],
      properties: [
        {
          name: 'ReferencedProcessDefinition',
          isAttr: true,
          type: 'String',
        },
      ],
    },
  ],
  emumerations: [],
  associations: [],
};
