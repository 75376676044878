import { Component, Input } from '@angular/core';
import { ApiService, ApiResponse } from '@dotgov/core';
import { Permit, Lang } from '../../../Models';
import { LanguageService } from '../../../Services/language.service';
import { ApiHelper } from '../../../Helpers/api.helper';

@Component({
  selector: 'app-home-permits',
  templateUrl: './permits.component.html',
  styleUrls: ['./permits.component.scss'],
})
export class HomePermitsComponent {
  permits: Permit[] = [];
  currentPage = 0;
  sortCol: string = this.NAME_SORT;
  sortAsc = true;
  loading = true;

  @Input('showBA') showBA: boolean;
  @Input() itemsOnPage: number;
  @Input() set filter(filter: object) {
    this.loadPermits(this.language.language, filter);
  }

  constructor(private apiService: ApiService, private language: LanguageService) {
    this.language.languageChanged.subscribe((lang: Lang) => {
      this.loadPermits(lang);
    });
  }

  /**
   * Update sorting type, triggered by click
   * @param {String} type default 'title'
   */
  updateSort(type: string = this.NAME_SORT) {
    const sortCol = type === 'date' ? this.DATE_SORT : this.NAME_SORT;

    if (this.sortCol === sortCol) {
      this.sortAsc = !this.sortAsc;
    }

    this.sortCol = sortCol;
    this.sort(this.permits);
  }


  /**
   * Sorting function used by permits sort
   * @param {Any} a
   * @param {Any} b
   * @return {Number}
   */
  sortAscBy(a, b): number {
    const target = this.sortCol === this.DATE_SORT
      ? 'AvailableFrom'
      : 'SortOrder';

    let asc;
    if (typeof a[target] === 'string') {
      asc = a[target].localeCompare(b[target]);
    } else {
      asc = a[target] - b[target];
    }

    return asc;
  }

  getRightSortIcon(sortCol: string) {
    sortCol = sortCol === 'date' ? this.DATE_SORT : this.NAME_SORT;
    return (
      (sortCol === this.sortCol)
      && (this.sortAsc && 'fa fa-sort-amount-asc' || 'fa fa-sort-amount-desc'))
      || 'fa fa-sort-amount-asc';
  }

  permitTitle(permit) {
    return permit[`Title${this.langSuffix}`] || permit['Title'];
  }

  get showDateFilter() {
    return false;
  }

  get langSuffix() {
    const code = this.language && this.language.language.Code;
    return code && code !== 'en' ? `_${code}` : '';
  }

  get NAME_SORT(): string {
    return 'title';
  }

  get DATE_SORT(): string {
    return 'AvalaibleFrom';
  }

  private sort(rawPermits: Permit[]) {
    let permits = ((rawPermits['data'] || rawPermits || []).sort(this.sortAscBy.bind(this)));

    if (!this.sortAsc) {
      permits = permits.reverse();
    }

    this.currentPage = 0;
    return permits;
  }

  private loadPermits(lang?: Lang, filters?: Object) {
    this.loading = true;
    const defaultParams = ApiHelper.setupLanguage({ page: 0, limit: 0, start: 0 }, lang, true);
    this.apiService.get('public', ['data', `AvailablePermits`], defaultParams).then((result: ApiResponse) => {
      const permits = result.data;
      const hasFilters = filters && Object.keys(filters).some((key) => !!filters[key]);
      if (hasFilters) {
        this.permits = this.filterPermits(permits.data, filters);
      } else {
        this.permits = permits.data;
      }
      this.loading = false;
    });
  }

  private filterPermits(permits: Permit[], filter: Object) {
    const keySet = (key, obj = filter) => {
      const item = (obj || {})[key];
      if (!obj.hasOwnProperty(key)) {
        return false;
      }
      if (Array.isArray(item)) {
        return item.length;
      }
      return item !== null && item !== undefined;
    };
    const searchFilter = (permit: Permit) => {
      const titleKey = `Title${this.langSuffix}`;
      if (!keySet('searchControl', filter) || !keySet(titleKey, permit)) {
        return true;
      }

      const index = permit[titleKey].toLowerCase().indexOf(filter['searchControl'].toLowerCase());
      return index !== -1;
    };
    const authorityFilter = (permit: Permit) => {
      if (!keySet('authorityControl') || !keySet('AuthorityId', permit)) {
        return true;
      }

      return Number(permit.AuthorityId) === Number(filter['authorityControl']);
    };
    const typeFilter = (permit: Permit) => {
      if (!keySet('typeOfPermitControl') || !keySet('DocumentTypeId', permit)) {
        return true;
      }

      return Number(permit.DocumentTypeId) === Number(filter['typeOfPermitControl']);
    };
    const bActivitiesFilter = (permit: Permit) => {
      if (!keySet('bussinessActivitiesControl') || !keySet('ActivityIDs', permit)) {
        return filter['bussinessActivitiesControl'] && !filter['bussinessActivitiesControl'].length;
      }
      let found = false;
      const activities = permit.ActivityIDs.split(',');
      filter['bussinessActivitiesControl'].forEach(activityId => {
        if (activities.indexOf(String(activityId)) !== -1) {
          found = true;
          return;
        }
      });
      return found;
    };

    let _permits = permits;
    if (filter['searchControl']) {
      _permits = _permits.filter(searchFilter);
    }
    if (filter['authorityControl']) {
      _permits = _permits.filter(authorityFilter);
    }
    if (filter['typeOfPermitControl']) {
      _permits = _permits.filter(typeFilter);
    }
    if (filter['bussinessActivitiesControl']) {
      _permits = _permits.filter(bActivitiesFilter);
    }
    _permits = this.sort(_permits);

    return _permits;
  }
}
