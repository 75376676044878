import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { PermitComponent } from '../../Components/Permit/permit.component';
import { ProfileComponent } from '../../Components/Profile/profile.component';
import { LoggedGuard } from '../../Guards/logged.guard';
import { ContainerControlComponent } from '../../Components/container-control/container-control.component';
import { BPMNComponent } from '../../Components/bpmn/bpmn.component';
import { RegistrationComponent } from '../../Components/Registration/registration.component';
import { RegisterCompanyComponent } from '../../Components/RegisterCompany/register-company.component';

export function bpmnMatcher(url: UrlSegment[]) {
  if (url.length > 0 && url.length < 4 && url[0].path === 'bpmn') {
    return { consumed: url };
  }
  return null;
}

const lazyRoutes: Routes = [
  {
    path: 'permit/:id',
    component: PermitComponent,
  },
  {
    path: 'MyProfile',
    component: ProfileComponent,
    canActivate: [LoggedGuard],
  },
  {
    matcher: bpmnMatcher,
    component: BPMNComponent,
    canActivate: [LoggedGuard],
  },
  {
    path: 'clientcontrol/:selector',
    component: ContainerControlComponent,
  },
  {
    path: 'container/:selector',
    component: ContainerControlComponent,
  },
  {
    path: 'profile/:selector',
    component: ContainerControlComponent,
  },
  {
    // View for MPay component in generally
    path: 'dashboard/custom/view/:selector',
    component: ContainerControlComponent,
  },
  {
    path: 'register',
    component: RegistrationComponent,
  },
  {
    path: 'register-company',
    component: RegisterCompanyComponent,
    canActivate: [LoggedGuard],
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(lazyRoutes),
  ],
  exports: [
    RouterModule,
  ],
})
export class LazyRoutingModule { }
