export class CookieHelper {
  static get(name) {
    const value = '; ' + document.cookie;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }

  static set(name, value, days = 0) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = '; expires=' + date.toUTCString();
    document.cookie = `${name}=${value || ''}${expires}; path=/`;
  }

  static remove(cname) {
    document.cookie = cname + '=;max-age=0;domain=' + location.hostname;
  }

  static all() {
    const pairs = document.cookie.split(';');
    const cookies = {};
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      cookies[(pair[0] + '').trim()] = encodeURI(pair[1]);
    }
    return cookies;
  }

}
