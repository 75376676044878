import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';

export class Link {
  constructor(public href: string, public text?: string, public rel: string = 'none') {
    this.text = text || href;
  }
}

@Injectable()
export class BPMNStoreService {
  constructor(private http: HttpClient) { }

  getDiagram(url: string) {
    return this.http.get(url)
      .toPromise();
      // .then(response => response.text());
  }

  listDiagrams(): Observable<Link[]> {
    return of([
      new Link('Process/EF_CancelPermit'),
    ]);
  }
}
