export const IO_TYPES = {
  INPUT: 'input',
  OUTPUT: 'output',
  BUTTONS: 'buttons',
  /**
   * Field in buttons section
   */
  COMMAND: 'command'
};

export type IOtypes = 'input' |'output' |'buttons' |'command';


export const DOTGOV_TYPES = {
  properties: 'Properties',
  input: 'Input',
  output: 'Output',
  field: 'Field',
  conditions: 'Conditions',
  condition: 'Condition',
  buttons: 'Buttons',
  button: 'Button'
};

export const DOTGOV_TYPES_PREFIXED = {
  properties: 'dotgov:' + DOTGOV_TYPES.properties,
  input: 'dotgov:' + DOTGOV_TYPES.input,
  output: 'dotgov:' + DOTGOV_TYPES.output,
  field: 'dotgov:' + DOTGOV_TYPES.field,
  conditions: 'dotgov:' + DOTGOV_TYPES.condition,
  condition: 'dotgov:' + DOTGOV_TYPES.condition,
  buttons: 'dotgov:' + DOTGOV_TYPES.buttons,
  button: 'dotgov:' + DOTGOV_TYPES.button
};
