import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  ViewEncapsulation,
} from '@angular/core';

import { CustomPropertiesProvider } from '../../helpers/props-provider.helper';
import {
  InjectionNames,
  Modeler,
  OriginalPaletteProvider,
  OriginalPropertiesProvider,
  PropertiesPanelModule,
} from '../../helpers/bpmn-js';
import { DotGovModdle } from '../../models/dotgov-moddle.model';
import { SVGDownloader } from '../../helpers/downloader.helper';
import { ApiService } from '@dotgov/core';

import * as propertiesProviderModule from 'bpmn-js-properties-panel/lib/provider/bpmn';
import { CustomPaletteProvider } from '../../helpers/palette.helper';
import { AbstractProperty } from '../../models/abstract.tab';
import { BuilderService } from '../../services/builder.service';
import { BindingTab } from '../../models/binding.tab';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

// import { environment } from 'src/environments/environment';

const toastrDefaultOptions: any = {
  positionClass: 'toast-bottom-right',
  preventDuplicates: true,
  enableHtml: true,
};

@Component({
  selector: 'bpmn-modeler',
  templateUrl: './modeler.component.html',
  styleUrls: ['./modeler.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class BPMNModelerComponent implements AfterViewInit {
  modeler: any;
  extraPaletteEntries: any;
  commandQueue = new Subject<any>();
  rawXml: any;
  loading = false;
  warningText = false;

  constructor(
    private api: ApiService,
    private ref: ChangeDetectorRef,
    private builder: BuilderService,
    private toastrService: ToastrService,
    @Inject('environment') private environment,
  ) { }

  private _url: string;

  get url() {
    return this._url;
  }

  @Input('url')
  set url(url: string) {
    if (url && url !== this._url) {
      this.loadBPMN(url);
    }
    this._url = url;
  }

  private _preview: boolean;

  get preview() {
    return this._preview;
  }

  @Input('preview')
  set preview(preview: boolean) {
    if (preview !== this._preview) {
      this.loadBPMN(this._url);
    }
    this._preview = preview;
  }

  get canvas() {
    return this.modeler && this.modeler.get('canvas');
  }

  get predownloads() {
    return {
      models: 'designer/models',
    };
  }

  get developer() {
    return this.environment['developmentMode'];
  }

  get allowSave() {
    return this.environment['allowSave'];
  }

  get modelerSettings() {
    const settings = {
      container: '#js-canvas',

      moddleExtensions: {
        custom: DotGovModdle,
      },
    };
    if (!this.preview) {
      settings['propertiesPanel'] = {
        parent: '#js-properties-panel',
      };
      settings['additionalModules'] = [
        { commandQueue: ['type', () => this.commandQueue] },
        PropertiesPanelModule,
        propertiesProviderModule,
        // Re-use original bpmn-properties-module, see CustomPropsProvider
        {
          [InjectionNames.bpmnPropertiesProvider]: [
            'type',
            OriginalPropertiesProvider.propertiesProvider[1],
          ],
        },
        { [InjectionNames.propertiesProvider]: ['type', CustomPropertiesProvider] },
        // Re-use original palette, see CustomPaletteProvider
        { [InjectionNames.originalPaletteProvider]: ['type', OriginalPaletteProvider] },
        { [InjectionNames.paletteProvider]: ['type', CustomPaletteProvider] },
      ];
    }
    return settings;
  }

  ngAfterViewInit() {
    this.commandQueue.subscribe(({ action }) => {
      if (action === 'save') {
        this.modeler.saveXML((err: any, xml: any) => this.save(xml, err));
      } else if (action === 'reset-zoom') {
        this.resetZoom();
      } else if (action === 'export') {
        this.exportToSVG();
      }
    });

    this.createModeler().then(() => {
      this.subscribeToEvents();
    });
  }

  private save(xml, err) {
    if (err) {
      console.error(err);
      return;
    }
    this.rawXml = this.prettifyXML(xml);
    if (this.developer && !this.allowSave) {
      console.warn('NO SAVE! Just to be sure you will not save while dev.');
      return;
    }
    const sureToSave = window.confirm('Sure you want to save? Check twice before continue.');
    if (!sureToSave) {
      return;
    }
    this.api.post(this.url, [], {}, this.rawXml).then(response => {
      if (response.error) {
        console.error(console.error(response));
        this.toastrService.error('Request failed');
        return;
      }
      this.toastrService.success('Success', '', toastrDefaultOptions);
      this.api.removeMatched(this.url);
    });
  }

  private createModeler() {
    return this.loadPredownloads().then(downloads => {
      // Bundler transforms this
      if (this.modeler) {
        this.modeler.destroy();
      }
      this.modeler = new Modeler(this.modelerSettings);
      CustomPropertiesProvider.prototype['modeler'] = this.modeler;
      AbstractProperty.prototype['_downloads'] = downloads;
      BindingTab.prototype['builder'] = this.builder;
      // Start with an empty diagram:
      this.ref.detectChanges();
      return Promise.resolve();
    });
  }

  private subscribeToEvents() {
    const eventBus = this.modeler.get('eventBus');
    const elementRegistry = this.modeler.get('elementRegistry');
    /**
     * Remove ExtensionRpopertiy from business object when object type is changed
     */
    eventBus.on('commandStack.execute', (event) => {
      if (event.command === 'shape.replace') {
        const oldShape = event.context.oldShape;
        const newShape = event.context.newShape;
        if (newShape.type !== oldShape.type) {
          const element = elementRegistry.get(newShape.id);
          delete element.businessObject.extensionElements;
        }
      }
    });
  }

  private loadPredownloads() {
    const options = {};
    const result = {};
    const promises = Object.keys(this.predownloads).map(key => {
      return this.api.get(this.predownloads[key], [], {}, options).then(response => {
        if (response.error) {
          return null;
        }
        result[key] = response.data;
        return key;
      });
    });
    return Promise.all(promises).then(() => Promise.resolve(result));
  }

  private loadBPMN(url?: string) {
    if (!url && !this.url) {
      return;
    }
    this.loading = true;
    this.warningText = false;
    return this.createModeler().then(() => {
      const settings = { responseType: 'text', headers: { Accept: 'text/plain' } };
      this.api
        .get(url || this.url, [], {}, settings)
        .then(response => {
          if (!response.data) {
            this.loading = false;
            this.warningText = true;
            return;
          }
          return this.modeler.importXML(this.sanitizeXML(response.data), this.postLoad.bind(this));
        })
        .then(error => this.postLoad(error));
    });
  }

  private postLoad(err: any) {
    this.loading = false;
    this.warningText = false;
    if (err) {
      this.warningText = true;
      console.log('error rendering', err);
    }
    if (!this.modeler) {
      return;
    }
    this.resetZoom();
    this.ref.detectChanges();
  }

  private resetZoom() {
    // Skip render before fitting viewport
    setTimeout(() => {
      this.canvas.zoom('fit-viewport', true);
    }, 1);
  }

  private exportToSVG() {
    const fileName = this.url
      .split('/')
      .pop()
      .split('.')
      .shift();
    SVGDownloader.ExportSVG(this.canvas._svg, fileName);
  }

  private sanitizeXML(xml?: string) {
    try {
      return JSON.parse(xml);
    } catch (e) {
      return xml;
    }
  }

  private prettifyXML(xml) {
    const xmlDoc = new DOMParser().parseFromString(xml, 'application/xml');
    const xsltDoc = new DOMParser().parseFromString(
      [
        // describes how we want to modify the XML - indent everything
        '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
        '  <xsl:strip-space elements="*"/>',
        '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
        '    <xsl:value-of select="normalize-space(.)"/>',
        '  </xsl:template>',
        '  <xsl:template match="node()|@*">',
        '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
        '  </xsl:template>',
        '  <xsl:output indent="yes"/>',
        '</xsl:stylesheet>',
      ].join('\n'),
      'application/xml',
    );

    const xsltProcessor = new XSLTProcessor();
    xsltProcessor.importStylesheet(xsltDoc);
    const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
    const resultXml = new XMLSerializer().serializeToString(resultDoc);
    return resultXml;
  }
}
