import { Injectable } from '@angular/core';
import { ApiService } from '@dotgov/core';
import { LoginService } from './login.service';

@Injectable({ providedIn: 'root' })
export class ForeignUserService {
  constructor(private apiService: ApiService, private loginService: LoginService) { }

  getRegisterUserField() {
    return this.apiService.get('foreignUser', ['getuserfields']);
  }

  getRegisterCompanyFields() {
    return this.apiService.get('foreignUser', ['getcompanyfields']);
  }

  resetPassword(resetPasswordObj: any) {
    return this.apiService.post('foreignUser', ['resetpassword'], {}, resetPasswordObj);
  }

  sendPasswordResetLink(resetPasswordObj: any) {
    return this.apiService.post('foreignUser', ['sendPwdResetlink'], {}, resetPasswordObj);
  }

  ConfirmEmail(confirmEmailObj: any) {
    return this.apiService.post('foreignUser', ['confirmhash'], {}, confirmEmailObj);
  }

  CheckHash(hash: string) {
    return this.apiService.get('foreignUser', ['checkHash'], { hash });
  }

  UnlockAccount(unlockaccountObj: any) {
    return this.apiService.post('foreignUser', ['confirmhash'], {}, unlockaccountObj);
  }

  register(registerObj) {
    return this.apiService.post('foreignUser', ['register'], {}, registerObj);
  }

  registerCompany(companyObj) {
    companyObj['userId'] = this.loginService.userContext && this.loginService.userContext['personId'];
    return this.apiService.post('foreignUser', ['registercompany'], {}, companyObj);
  }
  getCountryList(): Promise<any> {
    return this.apiService.get('foreignUser', ['CountryList'], {});
  }

  getPositionsList(): Promise<any> {
    return this.apiService.get('foreignUser', ['positions'], {});
  }

  getDistrictList(countryId: number): Promise<any> {
    return this.apiService.get('foreignUser', ['Districtlist'], { countryId });
  }

  registerDocument(file: File, requestId: string, documentTypeId: string) {
    const extension = file.name.split('.').pop();
    const formData: FormData = new FormData();
    formData.append('FileBody', file, file.name);
    formData.append('Extention', extension);
    formData.append('FileSize', file.size.toString());
    formData.append('FileTitle', file.name);
    formData.append('FileName', file.name);
    formData.append('ContentType', file.type);
    formData.append('RequestId', requestId);
    formData.append('DocumentTypeId', documentTypeId);

    return this.apiService.post('foreignUser', ['registerdocs'], {}, formData);
  }

  checkEmail(email) {
    return this.apiService.get('foreignUser', ['checkemail'], { email });
  }

  checkCorporateEmail(email) {
    return this.apiService.get('foreignUser', ['checkCorporateMail'], { email });
  }

  getAvailableAuthorities(): Promise<any> {
    return this.apiService.get('foreignUser', ['getForeignPermits']);
  }
}
