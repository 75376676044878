import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGE_PREFIXES } from '../../Models/constants';
import { defaults } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit, OnDestroy {
  MANUAL_FILE_PATHS: any = {};

  readonly MANUAL_FILE_PATHS_EN = {
    applicantUserGuide: '/assets/Manuals/MMIP User Guides/EN/Applicant User Guide en.pdf',
    foreignApplciantGuide: '/assets/Manuals/MMIP User Guides/EN/00 MMIP eFiling for Foreign Applicants - User Guide en.pdf',
    corporateMultiAccountGuide: '/assets/Manuals/MMIP User Guides/EN/03 MMIP Corporate multi-user accounts - User Guide en.pdf',
    howToApplyVideoGuide: '/assets/Manuals/MMIP Video Guides/MMIP How to Apply P7Eng v3.0.mp4'
  };

  readonly MANUAL_FILE_PATHS_RO = {
    applicantUserGuide: '/assets/Manuals/MMIP User Guides/Ro/Ghidul solicitantului.pdf',
    howToApplyVideoGuide: '/assets/Manuals/MMIP Video Guides/MMIP Apply P7 RO Video Ro.mp4'
  };

  readonly MANUAL_FILE_PATHS_RU = {
    applicantUserGuide: '/assets/Manuals/MMIP User Guides/RO/Ghidul solicitantului.pdf',
    howToApplyVideoGuide: '/assets/Manuals/MMIP Video Guides/Permits Presentation RU.mp4'
  };

  private onDestroy$ = new Subject<boolean>();
  constructor(private translate: TranslateService) { }

  ngOnInit() {
    this.configureLinks();
    this.translate.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe((_) => {
      this.configureLinks();
    });
  }


    ngOnDestroy(): void {
      this.onDestroy$.next(true);
      this.onDestroy$.complete();
    }

  private configureLinks() {
    const currentLanguage = this.translate.currentLang;
    switch (this.translate.currentLang) {
      case LANGUAGE_PREFIXES.ro:
        this.MANUAL_FILE_PATHS = defaults(this.MANUAL_FILE_PATHS_RO, this.MANUAL_FILE_PATHS_EN);
        break;
      case LANGUAGE_PREFIXES.ru:
        this.MANUAL_FILE_PATHS = defaults(this.MANUAL_FILE_PATHS_RU, this.MANUAL_FILE_PATHS_EN);
        break;
      default:
        this.MANUAL_FILE_PATHS = defaults({}, this.MANUAL_FILE_PATHS_EN);
    }
  }
}
