import { Component, Input, OnInit } from '@angular/core';
import { LoginService } from '../../../../Services/login.service';
import { Router } from '@angular/router';
import { DataService } from '../../../../Services/data.service';


@Component({
  selector: 'app-header-team-selector',
  templateUrl: './team-selector.component.html',
  styleUrls: ['./team-selector.component.scss'],
})
export class TeamSelectorComponent implements OnInit {
  @Input() teams;
  selected = '';
  personal = { teamId: '', name: '' };
  constructor(private loginService: LoginService, private dataService: DataService, private router: Router) { }

  ngOnInit() {
    if (this.dataService.currentUserTeam && this.teams) {
      this.selected = this.teams.find(t => t.teamId === this.dataService.currentUserTeam).name;
    }
  }

  click(team: { name: string, teamId: string }) {
    this.dataService.currentUserTeam = team.teamId;
    this.selected = team.name
    this.router.navigate(['/ep', 'container', 'app-ApplicantDashboard'], );
  }
  get isForeignUser() {
    return this.loginService.LOGIN_TYPE !== LoginService.MPASS_LOGIN;
  }
}
