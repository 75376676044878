import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@dotgov/core';
import { LanguageService } from '../../Services/language.service';
import { Permit, Lang } from '../../Models';
import { LoggedGuard } from '../../Guards/logged.guard';
import { ApiHelper } from '../../Helpers/api.helper';

@Component({
  selector: 'app-permit',
  templateUrl: './permit.component.html',
  styleUrls: ['./permit.component.scss'],
})
export class PermitComponent implements OnInit {
  loading = true;
  supportingDocuments = [];
  lexes = [];
  topPermits: Permit[] = [];
  authority: any = {};
  permitNumber: number;
  permit: Permit;
  showModal: boolean;
  lang: Lang;
  authorityImage: any;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private language: LanguageService,
    private ref: ChangeDetectorRef,
    private loggedGuard: LoggedGuard,
  ) { }

  ngOnInit() {
    this.fetchRouteInfo();
  }

  modalShow() {
    const allowed = this.loggedGuard._canActivate();
    if (!allowed) {
      return;
    }
    this.showModal = true;
    this.ref.detectChanges();
  }

  get langSuffix() {
    return this.lang && this.lang.Code !== 'en' ? `_${this.lang.Code}` : ``;
  }

  private loadPermit(lang?: Lang) {
    this.loading = true;
    const filter = (params) => {
      return Object.assign({
        page: 0,
        start: 0,
        limit: 0,
      }, params);
    };

    const filteredQueryParams = filter({
      filter: JSON.stringify([{
        value: this.permitNumber,
        property: 'PermitTypeId',
      }]),
    });
    const promises = [
      this.apiService.get('public', ['data', 'PermitInfo', this.permitNumber.toString()]),
      this.apiService.get(
        'public',
        ['data', `PermitSupportingDocuments`],
        ApiHelper.setupLanguage(filter(filteredQueryParams), lang, true),
      ),
      this.apiService.get('public', ['data', `PermitLex`], filteredQueryParams),
      this.apiService.get('public', ['data', `TopPermits`], filter({ page: 1, limit: 3 })),
    ];
    Promise.all(promises).then((results) => {
      const permit = results[0].data || {};
      const supportingDocuments = results[1].data || {};
      const lexes = results[2].data || {};
      const topPermits = results[3].data || {};

      this.permit = permit;
      const firstOfLevel = {};
      this.supportingDocuments = supportingDocuments['data']
        .filter(item => item.SupportingDocumentTypeId_ReferenceTitle)
        .map((item, index) => {
          if (!firstOfLevel.hasOwnProperty(item.level)) {
            firstOfLevel[item.level] = index;
          }
          return Object.assign(item, { firstOfLevel: firstOfLevel[item.level] === index });
        });
      this.lexes = lexes['data'];
      this.topPermits = topPermits['data'];
      const authorityFilter = JSON.stringify([{ value: this.permit.AuthorityId, property: 'ID' }]);
      this.apiService.get(
        'public',
        ['data', `Authorities`],
        ApiHelper.setupLanguage(filter({ limit: 1, filter: authorityFilter }), lang, true),
      ).then((response) => {
        this.authority = response.data.data[0];
        if (!this.authority['Name' + this.langSuffix]) {
          return null;
        }
        return this.apiService.get('public', ['data', 'AuthorityLogo'], filter({ filter: authorityFilter }));
      }).then((response) => {
        if (!response) {
          this.loading = false;
          return;
        }
        this.authorityImage = response.data.data[0];
        const logo = this.authorityImage.logo;
        if (logo && !logo.startsWith('data:image')) {
          this.authorityImage['logo'] = `data:image/png;base64,${this.authorityImage['logo']}`;
        }
        this.loading = false;
      }).catch((err) => {
        console.error(err);
        this.loading = false;
      });
    }).catch((e) => {
      console.log(e);
      this.loading = false;
    });
  }

  private fetchRouteInfo() {
    this.route.params.subscribe(params => {
      this.permitNumber = +params['id'];
      if (this.language.loaded) {
        this.lang = this.language.language;
        this.loading = true;
        this.loadPermit(this.lang);
      }
      this.language.languageChanged.subscribe((lang: Lang) => {
        this.lang = lang;
        this.loadPermit(lang);
      });
      this.ref.detectChanges();
    });
  }
}
