import { ApiService, StorageService } from '@dotgov/core';
import { Injectable, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { User } from '../Models';
import { LoginMetadata } from '../Metadata/login';

@Injectable()
export class DataService {

  static get NOT_LOGGED() {
    return -1;
  }

  currentUserTeamChanged = new EventEmitter<any>();
  appDataChanged: EventEmitter<any> = new EventEmitter();
  userContextChanged: EventEmitter<any> = new EventEmitter();

  private _userContext: User = undefined;
  private _appData: Object = undefined;
  readonly NO_TEAM = '';
  private _currentUserTeam: string;
  private _loggedBy: number;

  constructor(
    private apiService: ApiService,
    private storage: StorageService,
    private titleService: Title,
  ) {
    this._currentUserTeam = sessionStorage.getItem('currentTeam') || this.NO_TEAM;
      this._loggedBy = Number(localStorage.getItem('_loggedby')) || DataService.NOT_LOGGED;

  }

  get userContext(): Promise<User> {
    return this._userContext && Promise.resolve(this._userContext) || this.loadUserContext();
  }

  get appData(): Promise<any> {
    return this._appData && Promise.resolve(this._appData) || this.loadAppData();
  }

  get isAdmin(): boolean {
    return !!(this._userContext || {}).isAdmin;
  }

  set appData(appData) {
    this._appData = appData;
    this.appDataChanged.emit(appData);
  }

  get currentUserTeam(){
    return this._currentUserTeam;
  }

  set currentUserTeam(team: string){
    this._currentUserTeam = team;
    sessionStorage.setItem('currentTeam', team);
    this.currentUserTeamChanged.emit(team);
  }

  setDefaultUserTeam(){
    this._currentUserTeam = this.NO_TEAM;
  }

  get loggedType(){
    return this._loggedBy;
  }

  set loggedType(loginStatus: number){
    localStorage.setItem('_loggedby', loginStatus.toString());
    this._loggedBy = loginStatus;
  }

  get isLogged(): boolean {
    return this.loggedType !== DataService.NOT_LOGGED;
  }

  updateUserContext(userContext: User) {

    this._userContext = userContext;
    localStorage.setItem(LoginMetadata.userContext, JSON.stringify(userContext));
    this.userContextChanged.emit(userContext);
    this.forceLoadAppData();
  }

  forceLoadAppData(options: Object = {}): Promise<any> {

    return this.loadAppData(Object.assign({}, options, { skipCache: true }));
  }

  forceLoadUserContext(options: Object = {}): Promise<any> {

    return this.loadUserContext(Object.assign({}, options, { skipRole: true, skipCache: true }));
  }

  clearData() {

    this._appData = {};
    this._userContext = {};
    this.userContextChanged.emit(this._userContext);
    this.appDataChanged.emit(this._appData);
    localStorage.removeItem(LoginMetadata.userContext);
    localStorage.removeItem(LoginMetadata.externalSessionIndexKey);
    localStorage.removeItem('activeRole');
    localStorage.removeItem('_loggedby');
    localStorage.removeItem('auth');
  }

  private loadUserContext(options?: Object): Promise<User | any> {

    const auth = JSON.parse(localStorage.getItem('auth'));
    if (auth) {
      const defaultOptions = {
        skipRole: true,
      };
      return this.apiService.get('user', ['current'], undefined, options || defaultOptions).then((result) => {
        const userContext = result.data && result.data.data || result.data;
        this._userContext = userContext;
        localStorage.setItem(LoginMetadata.userContext, JSON.stringify(userContext));
        this.storage.set(LoginMetadata.userContext, userContext);
        this.userContextChanged.emit(userContext);
        return Promise.resolve(userContext);
      });
    }
    return Promise.resolve({});
  }

  private loadAppData(options?: Object): Promise<Object> {

    return this.apiService.get('app', [], undefined, options || {}).then(response => {
      const data = response.data;
      this.titleService.setTitle(data.title);
      this.appData = data;

      return Promise.resolve(data);
    });
  }
}
