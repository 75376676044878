import { Component, ViewChild, ViewContainerRef, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FactoryService } from '@dotgov/core';
import { environment } from '../../../environments/environment';
import { Tab } from '../../Models/tab';
import { DataService } from '../../Services/data.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, switchMap } from 'rxjs/operators';
import { NotFoundComponent } from '../404NotFound/404.component';
import { PermitsIssuedListComponent } from '../permits-issued/permits-issued-list/permits-issued-list.component';
import { HomeComponent } from '../Home/home.component';
import { ApplicantInfoComponent } from '../applicant-info/applicant-info.component';
import { MyCertificatesComponent } from '../my-certificates/my-certificates.component';
import { PermitsIssuedComponent } from '../permits-issued/permits-issued.component';
import { ProfileComponent } from '../Profile/profile.component';
import { BPMNComponent } from '../bpmn/bpmn.component';
import { MSignComponent } from '../CustomControls/m-sign/m-sign.component';
import { MSignResponseComponent } from '../CustomControls/m-sign-response/m-sign-response.component';

@Component({
  selector: 'app-container-control',
  templateUrl: './container-control.component.html',
  styleUrls: ['./container-control.component.scss'],
})
export class ContainerControlComponent implements AfterViewInit, OnDestroy {
  @ViewChild('container', { static: true, read: ViewContainerRef }) container;
  private _onLoad = new Subject();
  private destroyed$ = new Subject();
  private readonly componentFactories: {selector:string, factoryClass: any}[] = [
    {
      selector: 'app-permits-issued-list',
      factoryClass: PermitsIssuedComponent
    },
    {
      selector: 'app-permit-list',
      factoryClass: HomeComponent
    },
    {
      selector: 'app-MyCertificates',
      factoryClass: MyCertificatesComponent
    },
    {
      selector: 'app-ApplicantDashboard',
      factoryClass: ApplicantInfoComponent
    },
    {
      selector: 'app-applicant-info',
      factoryClass: ApplicantInfoComponent
    },
    {
      selector: 'app-my-profile',
      factoryClass: ProfileComponent
    },
    {
      selector: 'app-bpmn',
      factoryClass: BPMNComponent
    },
    {
      selector: 'app-404',
      factoryClass: NotFoundComponent
    },
    {
      selector: 'm-sign',
      factoryClass: MSignComponent
    },
    {
      selector: 'm-sign-response',
      factoryClass: MSignResponseComponent
    }
  ]
  private subscriptions = [];

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private factoryService: FactoryService,
  ) { }

  ngAfterViewInit() {
    this.fetchRouteInfo();
    this._onLoad
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
        takeUntil(this.destroyed$),
      )
      .subscribe(({ app, params }) => {
        this.container.clear();
        const targetTabName = params.selector;
        const tab = ((app || {}).tabs || []).find((_tab: Tab) => `app-${_tab.id}` === targetTabName);
        const link = ((app || {}).links || []).find((_tab: Tab) => `app-${_tab.id}` === targetTabName);
        const target = tab || link;
        if (!target) {
          this.createComp(targetTabName);
          return;
        }
        const type = target.type.toLowerCase();
        if (type === 'profile') {
          this.createComp(`app-my-profile`, { profile: target.id });
          return;
        }
        if (!target.nodes) {
          this.createComp(`app-${target.id}`);
          return;
        }
        target.nodes.forEach(node => {
          this.createComp(`app-${node.id}`);
        });
      });
  }

  private fetchRouteInfo() {
    this.route.params.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      // Remove dynamic components if exists
      this.container.clear();
      this.dataService.forceLoadAppData().then(app => {
        this._onLoad.next({ app, params })
      });
      this.dataService.appDataChanged
        .pipe(takeUntil(this.destroyed$))
        .subscribe(app => {
          this._onLoad.next({ app, params })});
      this.dataService.userContextChanged
        .pipe(takeUntil(this.destroyed$))
        .subscribe(_ => {
          this.dataService.appData.then(app => {
            this._onLoad.next({ app, params })});
          });
    });
  }

  private createComp(selector, params = {}){
    if (selector === 'app-404') {
      this.container.clear();
    }

    if (selector === 'app-CorporateCertificates') {
      return;
    }

    const debug = environment.debug;
    let factory = this.factoryService.componentbySelector(selector, this.componentFactories, this.container, params);

    if (factory === FactoryService.NOT_FOUND) {
      if (debug) {
        console.warn('Started client-control with not a valid target component.');
      }
      factory = this.factoryService.componentbySelector('app-404', this.componentFactories, this.container);
      return;
    }
    if (debug) {
      console.log(`Trying to create ${selector}`);
    }
    if (factory !== FactoryService.HAS_ROUTE) {
      this.container.createComponent(factory);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    //this.subscriptions.forEach((subs) => subs && subs.unsubscribe && subs.unsubscribe());
  }
}
