import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LanguageService } from './language.service';

@Injectable()
export class NotificationService {
  constructor(private toastr: ToastrService, private language: LanguageService) { }

  error(message: string, title?: string, override?: Object) {
    this.notify('error', message, title, override);
  }

  info(message: string, title?: string, override?: Object) {
    this.notify('info', message, title, override);
  }

  success(message: string, title?: string, override?: Object) {
    this.notify('success', message, title, override);
  }

  warning(message: string, title?: string, override?: Object) {
    this.notify('warning', message, title, override);
  }

  private notify(method: string, message?: string, title?: string, override?: Object) {
    this.getTranslates(message, title).then((translates) => {
      this.toastr[method](translates.message, translates.title, override);
    });
  }

  private getTranslates(message: string = '', title: string = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      this.language.getTranslate(message).then((translatedMessage) => {
        if (title) {
          this.language.getTranslate(title).then((translatedTitle) => {
            return resolve({ message: translatedMessage, title: translatedTitle });
          });
        } else {
          return resolve({ message: translatedMessage, title: undefined });
        }
      });
    });
  }
}
