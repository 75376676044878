import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'm-pay-response',
  templateUrl: './m-pay-response.component.html',
  styleUrls: ['./m-pay-response.component.scss'],
})
export class MPayResponseComponent {
  securityIssue: boolean;

  constructor(activatedRoute: ActivatedRoute) {
    activatedRoute.queryParams.subscribe((params: object) => {
      this.secureCheck(params['orderKey']);
    });
  }

  /**
   * Send message to opener ( 'ui: true' means that message was sent by ui )
   */
  private secureCheck(orderKey: string) {
    this.securityIssue = window.opener === null || window.opener === undefined;
    if (window.opener !== null) {
      if (!orderKey) {
        window.opener.postMessage({ error: 'No Key', ui: true }, '*');
        window.close();
        return;
      }
      window.opener.postMessage({ ui: true }, '*');
      window.close();
      return;
    }
    if (environment.debug) {
      console.log('This window does not have a parent.');
    }
  }
}
