import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { flatMap, flatMapDeep } from 'lodash';
import { ForeignUserService } from '../../Services/foreign-user.service';
import { NotificationService } from '../../Services/notification.service';
import { Router } from '@angular/router';
import { Observable, of, from } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { RegistrationField } from '../../Models/registration-field.model';
import { environment } from '../../../environments/environment';
import { RecaptchaCheckService } from '../../Services/recaptcha.service';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { LanguageService } from '../../Services/language.service';
import { RegistrationSection } from '../../Models/registration-section.model';
import * as moment from 'moment';

@Component({
  selector: 'app-register-company',
  templateUrl: './register-company.component.html',
  styleUrls: ['./register-company.component.scss']
})
export class RegisterCompanyComponent implements OnInit {
  siteKey = environment.recaptchaSiteKey;
  processInstanceId: any;
  error: any;
  task: any;
  submitted = false;
  rows: [RegistrationField[]];
  sections: RegistrationSection[];
  selectedAuthority;
  constructor(public recaptchaCheckService: RecaptchaCheckService , private fb: FormBuilder, private foreignUserService: ForeignUserService,
    private noficationService: NotificationService, private router: Router, private languageService: LanguageService) { }



  registrationForm: FormGroup;
  filesToUpload: {file: File, field: RegistrationField}[] = [];
  private readonly COUNTRY_FIELDS = ['companyResidentAdrCountry', 'companyPostalAdrCountry'];
  private readonly DISTRICT_FIELDS = ['companyResidentAdrDistrict', 'companyPostalAdrDistrict'];
  private countryList = [];
  private positionsList = [];

  ngOnInit() {
    this.foreignUserService.getRegisterCompanyFields().then((res: {data: RegistrationSection[]}) => {
      const ff = {};
      if (res && res.data) {
        this.sections = res.data;
        const allRows = res.data.map(r => r.rows);
        this.rows = flatMap(allRows) as [RegistrationField[]];
        flatMapDeep<RegistrationField>(allRows).forEach((f) => {
          switch (f.id) {
            case 'companyEmail': {
              ff[f.id] = new FormControl('', { validators: Validators.compose(f.required ? [Validators.required, Validators.email] : null),
                asyncValidators: this.corpotateEmailValidator(f),
                 updateOn: 'blur' });
              break;
            }
            case 'companyRegDate': {
              ff[f.id] = new FormControl('', Validators.compose([f.required && Validators.required, this.dayInFuture() ]));
              break;
            }
            default: {
              ff[f.id] = this.fb.control(f.value || '', Validators.compose(f.required ? [Validators.required] : null));
            }
          }
        });
                ff['recaptcha'] = new FormControl('');

        this.registrationForm = this.fb.group(ff);
        return;
      }
    }).then(() => {

      this.bindDistrictOptionsToCountry();
    });

    this.foreignUserService.getPositionsList().then((res) => {
      if (res && res.data) {
        const data = res.data.map((c) => {
          const translatedName = c[`name_${this.languageService.language.Code}`];
          return {
            label: !translatedName ? c.name : translatedName,
            value: c.id
          };
        });
        this.positionsList = data;
      }
    });

    this.foreignUserService.getCountryList().then((res) => {
      if (res && res.data) {
        const data = res.data.map((c) => {
          const translatedName = c[`name_${this.languageService.language.Code}`];
          return {
            label: !translatedName ? c.name : translatedName,
            value: c.id
          };
        });
        this.countryList = data;
      }
    });

  }

  onSubmit(captchaElem: InvisibleReCaptchaComponent) {
    this.submitted = true;
    if (this.registrationForm.invalid || !this.selectedAuthority) {
      window.scrollTo(0, 0);
      return;
    }
    const ff = this.registrationForm.value;
    ff['teamId'] = this.selectedAuthority;
    //parse date to string
    Object.keys(ff).forEach(key => {
      if (key.toLowerCase().includes('date')) {
        ff[key] = moment(ff[key]).format('YYYY-MM-DD');
      }
    })
    this.recaptchaCheckService.execute(captchaElem).subscribe(() => {
      this.foreignUserService.registerCompany(this.registrationForm.value).then((res) => {
        if (res && res.data) {
          Promise.all(this.filesToUpload.map(f => {
            return this.foreignUserService.registerDocument(f.file, res.data.message, f.field.documentTypeId);
          })).then(success => {
            if (success) {
              this.noficationService.success('Form submitted successfully');
              this.router.navigate(['/foreignregsuccess']);
            }
          });
        }
      });
    });

  }

  getOptions(field): any {
    return new Observable(obs => {
      if (this.COUNTRY_FIELDS.includes(field.id) && !field.options.length) {
        obs.next(this.countryList);
        field.options = this.countryList;
        obs.complete();
      } else {
        if (field.id === 'companyPosition') {
          field.options = this.positionsList;
        }
        obs.next(field.options);
        obs.complete();
      }
    });
  }


  bindDistrictOptionsToCountry() {
    this.COUNTRY_FIELDS.forEach(countryId => {
      const predicate = countryId.replace('Country', '');
      const districtId = this.DISTRICT_FIELDS.find(d => d.includes(predicate));
      this.registrationForm.get(countryId).valueChanges
        .pipe(filter(v => !!v), distinctUntilChanged())
        .subscribe((id) => {
          this.foreignUserService.getDistrictList(id).then((res) => {
            const data = res.data.map((c) => {
              const translatedName = c[`name_${this.languageService.language.Code}`];
              return {
                label: !translatedName ? c.name : translatedName,
                value: c.id
              };
            });
            const field = flatMap(this.rows).find(f => f.id === districtId);
            field.options = data;
          });
        });
    });
  }

  private findCountryField(districtId) {
    const predicate = districtId.replace('District', '');
    const countryId = this.COUNTRY_FIELDS.find(d => d.includes(predicate));
    return this.registrationForm.get(countryId);
  }


  shouldShow(field) {
    // if (field.id.includes('company') && this.userType !== 2) {
    //   return false;
    // }
    return true;
  }

  corpotateEmailValidator(control) {
    const validator = this.foreignUserService.checkCorporateEmail.bind(this.foreignUserService);
    return function (formControl): Observable<any> {
      return new Observable((obs) => {
        if (!formControl.value) {
          obs.next(null);
          obs.complete();
          return;
        }
        validator(formControl.value).then(res => {
          // if true than email is not unique
          if (res && res.data) {
            obs.next({ emailNotUnique: true });
            obs.complete();
          }
          obs.next(null);
          obs.complete();
        });
      });
    };
  }

  shouldDisplayError(fieldId) {
    return (this.registrationForm.get(fieldId) && this.registrationForm.get(fieldId).touched) || this.submitted;
  }

  handleFileInput(files: FileList, field) {
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        this.filesToUpload.push({
          file: files.item(i),
          field: field
      });
    }
  }
  }

  authoritySelected(ev) {
    this.selectedAuthority = ev;
  }

  dayInFuture() {
    return  (formControl: FormControl) => {
      if (formControl && formControl.value) {
        const date = moment(formControl.value);
        const currentDay = moment(new Date());
        if (date.isSameOrAfter(currentDay, 'day')) {
          return {
            dateInFuture: true
          };
        }
      }
      return null;
    };
  }
}
