import { Component } from '@angular/core';
import { ApiService } from '@dotgov/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  private _appVersion: string;
  private appData: Object = {};

  constructor(private apiService: ApiService, private http: HttpClient) {
    this.apiService.get('app').then((data) => {
      this.appData = data.data;
    });
    this.http.get('/assets/version.json').toPromise().then((response: { version: string }) => {
      this._appVersion = response.version;
    });
  }

  get appVersion() {
    return this._appVersion;
  }

  get apiVersion() {
    return this.appData['apiVersion'];
  }
}
