import { Component, AfterViewInit, ChangeDetectorRef, OnDestroy, Input, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FVAbstractControl, ProcessService, Task, Control } from '@dotgov/formviewer';
import { ApiService, IDGSChangeSet } from '@dotgov/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'pdf-widget',
  templateUrl: './pdf-widget.component.html',
  styleUrls: ['./pdf-widget.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class PdfWidgetComponent extends FVAbstractControl implements AfterViewInit, OnDestroy {
  pdfUrl: string;
  loading: boolean;
  iframeUrl: SafeResourceUrl;
  finalUrl: string;
  height: number;

  protected _task: Task;
  @Input() set task(task: Task) {
    this._task = task;
  }

  protected _control: Control;
  @Input() set control(control: Control) {
    this._control = control;
  }

  protected _stateId: string;
  @Input() set stateId(stateId: string) {
    this._stateId = stateId;
  }

  protected _processId: string;
  @Input() set processId(processId: string) {
    this._processId = processId;
  }

  protected _group: FormGroup;
  @Input() set group(group: FormGroup) {
    this._group = group;
  }

  get task(): Task {
    return this._task;
  }

  get control(): Control {
    return this._control;
  }

  get stateId(): string {
    return this._stateId;
  }

  get processId(): string {
    return this._processId;
  }

  get group() {
    return this._group;
  }

  @Input() fullWidth: boolean;
  @ViewChild('iframeTemplate') iframeTemplate;

  private _destroyed: boolean;

  constructor(
    private process: ProcessService,
    private api: ApiService,
    private domSanitizer: DomSanitizer,
    private ref: ChangeDetectorRef,
  ) {
    super(process);
    this.loading = true;
    this.subscriptions.push(this.process.onChange.subscribe((changes: IDGSChangeSet) => {
      if (changes.changeName === 'm-sign') {
        this.iframeUrl = undefined;
        this.ref.detectChanges();
        this.fetchPDF();
      }
      if (changes.changeName === 'pdfHide' && this.control) {
        this.control.isHidden = changes.changeVal;
      }
    }));
  }

  ngAfterViewInit() {
    this.fetchPDF();
    this.process.set(`control-pdf-widget`, this.control);
    this.updatePdfHeight();
  }

  toString() {
    return `${super.toString()}
    pdf url: ${this.pdfUrl}
    iframe url: ${this.iframeUrl}`;
  }

  private fetchPDF() {
    this.iframeUrl = undefined;
    this.ref.detectChanges();
    setTimeout(() => {
      const data = this.task.componentConfig.recordData;
      const params = {
        folder: data['FileTableName'],
        field: data['FileTableField'],
        id: data['FileId'],
      };
      const options = {
        headers: {
          'Accept': '*/*',
        },
        skipCache: true,
        responseType: 'arraybuffer',
      };
      this.api.get('filedownload', [], params, options).then((data) => {
        const objectURL = URL.createObjectURL(new Blob([data.data], { type: 'application/octet-binary;charset=utf-8' }));
        this.finalUrl = `${window.location.origin}/assets/pdfjs/web/viewer.html?file=${encodeURIComponent(objectURL)}`;
        this.iframeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.finalUrl);
        this.ref.detectChanges();
      });
    }, 1);
  }

  private updatePdfHeight() {
    if (this._destroyed) {
      return;
    }
    setTimeout(() => {
      if (this.iframeTemplate && this.iframeTemplate.nativeElement && this.iframeTemplate.nativeElement.contentDocument
        && this.iframeTemplate.nativeElement.contentDocument.body) {
        this.height = this.iframeTemplate.nativeElement.contentDocument.body.offsetHeight;
      }
      this.updatePdfHeight();
    }, 10);
  }

  ngOnDestroy() {
    this._destroyed = true;
    this.OnDestroy();
  }
}

