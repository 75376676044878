import * as modelUtils from 'bpmn-js/lib/util/ModelUtil';
import * as cmdHelper from 'bpmn-js-properties-panel/lib/helper/CmdHelper';
import { EventEmitter } from '@angular/core';
import { AbstractProperty } from './abstract.tab';

let abstractRUNTIMEID = 1;

function abstractID() {
  return abstractRUNTIMEID++;
}

export class CustomControlSettings {
  public updates?: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public strictUUID?: string,
    public customValue?: string | number,
    public selectValues?: any[],
    public index?: number,
    public actions?: Object,
    public classes?: string[],
    public customHtml?: string,
    public placeholder?: string,
  ) {}
}

export class AbstractBPMNControl {
  inputChanged = new EventEmitter<{ oldId?: string; value: string; field: AbstractBPMNControl }>();
  UUID: string;

  private _oldHtml;

  constructor(
    public element: any,
    public type?: string,
    public id?: string,
    public label?: string,
    public modelProperty?: string,
    public originalField?: any,
    public settings?: CustomControlSettings,
  ) {
    this.settings = Object.assign(new CustomControlSettings(), this.settings || {});
    this.id = id || element.id;
    this.settings.classes = this.settings.classes || ['custom'];
    this.label = label || this.id;
    this.modelProperty = modelProperty || this.id;

    this.UUID = this.settings.strictUUID || `${this.id}${abstractID()}`;
    if (this.settings.updates) {
      this.settings.updates.subscribe(() => {
        if (!this || !this.id || !this.settings) {
          return;
        }
      });
    }
  }

  get html() {
    const html = this.generateHtml();
    if (html === this._oldHtml) {
      return html;
    }
    setTimeout(() => {
      this.bindDelete();
      this.bindNameEdit();
      this.bindActions();
      this.bindDefault();
    }, 5);
    this._oldHtml = html;
    return html;
  }

  get control() {
    return Object.assign({}, this.model, {
      html: this.html,
      get: this.customGetter.bind(this),
      set: this.customSetter.bind(this),
    });
  }

  get customGetter() {
    // @ts-ignore
    return (element: any, node?: any) => {
      const field = this.originalField || this.model;
      const bo = modelUtils.getBusinessObject(element);
      const ref = field.id || field.name || this.id;
      const res = {};
      if (this.type === 'command') {
        res[this.id] = field[this.customTarget];
        return res;
      }

      if (this.customTarget && this.type !== 'ioBox') {
        res[this.customTarget] = field[this.customTarget];
        return res;
      }
      res[ref] = bo.get(ref) || field.value || field[ref];
      return res;
    };
  }

  get customSetter() {
    // @ts-ignore
    return (element: any, values: any, node?: any) => {
      if (this.type === 'checkbox') {
        const target = this.customTarget || this.id;
        values[target] = !this.originalField[target];
      }
      const field = this.originalField || this.model;
      const res = {};

      const pristine = Object.keys(values).every(key => {
        if (!values.hasOwnProperty(key)) {
          return true;
        }
        if (key === this.id && this.customTarget) {
          this.log(`was ${field[this.customTarget]}, now is ${values[key]}`);
          field[this.customTarget] = values[key];
          return false;
        }
        this.log(`was ${field[key]}, now is ${values[key]}`);
        field[key] = res[key] = values[key];
        return false;
      });
      this.log(
        `Changed ${Object.keys(values).join(',')} to ${Object.keys(values).map(
          key => values[key],
        )}`,
      );
      if (pristine) {
        return cmdHelper.updateProperties(element, res);
      }
    };
  }

  get node() {
    return document.getElementById(this.UUID);
  }

  get model() {
    return {
      id: this.id,
      label: this.label,
      modelProperty: this.modelProperty,
      cssClasses: this.settings.classes,
      options: this.settings.selectValues,
    };
  }

  get customTarget() {
    if (!this.originalField || !this.settings.customValue) {
      return;
    }
    return this.settings.customValue;
  }

  get customValue() {
    if (!this.customTarget) {
      return;
    }
    return this.originalField[this.customTarget] || '';
  }

  generateHtml() {
    let html = '';
    switch (this.type) {
      case 'button': {
      html = `<button id="${this.UUID}">${this.settings.customHtml || this.label || ''}</button>`;
      break;
      }

      case 'label': {
      html = `<label id="${this.UUID}">${this.label}</label>`;
      break;
      }

      case 'ioBox': {
      const renderLabel = !this.settings.index;
      const isGenericInput = this.id && this.id.includes(AbstractProperty.GENERIC_NAME);
      html = `${
        renderLabel
          ? `
      <span class="variableName">${this.label}</span><span class="inputName">${this.customValue ||
              'value'}</span>`
          : ''
      }
      <div class="ioProperty">
        <div class="bpp-field-wrapper label">
        <input id="${this.UUID}-label" value="${isGenericInput ? '' : this.id}" placeholder="${isGenericInput ? '' : this.id}">
        </div>
        <div class="bpp-field-wrapper input">
          <input id="${this.UUID}" type="text" value="${this.originalField.text || '' }" name="${this.id}" aria-label="${this.id}">
        </div>
        <div id="${this.UUID}-delete" class="deleteBtn"><i class="fa fa-times"></i></div>
      </div>`;
      break;
      }

      case 'command': {
        const renderLabel = !this.settings.index;
        const isGenericInput = this.id && this.id.includes(AbstractProperty.GENERIC_NAME);
        html = `${
          renderLabel
            ? `
        <span class="variableName">Command</span><span class="inputName"> Caption</span>`
            : ''
        }
        <div class="ioProperty">
          <div class="bpp-field-wrapper label">
          <input id="${this.UUID}-label" value="${isGenericInput ? '' : this.id}" placeholder="${isGenericInput ? '' : this.id}">
          </div>
          <div class="bpp-field-wrapper input">
            <input id="${this.UUID}" type="text" value="${this.originalField.text || '' }" name="${this.id}" aria-label="${this.id}">
          </div>
          <div id="${this.UUID}-delete" class="deleteBtn"><i class="fa fa-times"></i></div>
        </div>`;
        break;
      }

      case 'textBox': {
      const valueProp =
        this.customValue !== undefined ? `value="${this.customValue}"` : `name="${this.id}"`;
      const placeholder = this.settings.placeholder
        ? `placeholder="${this.settings.placeholder}"`
        : '';
      html = `<div class="bpp-field-wrapper">
          <label>${this.label}</label>
        </div>
        <div class="bpp-field-wrapper">
          <input id="${this.UUID}" type="text" ${valueProp} ${placeholder}>
        </div>`;
      break;
      }

      case 'selector': {
      const getter = this.customGetter(this.element, this.node);
      const value =
        this.customValue ||
        getter[this.customTarget] ||
        getter[this.id] ||
        (this.originalField && this.originalField[this.customTarget]);
      const options = (this.settings.selectValues || [])
        .map(option => {
          const selected = value === option.value ? 'selected' : '';
          return `<option ${selected} value="${option.value}">${option.name}</option>`;
        })
        .join('');
      html = `<div><label>${this.label}</label>
      <select id="${this.UUID}" name="${this.id}">
        ${options}
      </select></div>`;
      break;
      }

      case 'colorSelector': {
      html = `<div><label>${this.label}</label>
      <input type="color" id="${this.UUID}" value="${this.settings.customValue || ''}">
      </div>`;
      break;
      }

      case 'checkbox': {
      const checked = this.customValue ? 'checked' : '';
      this.log(this.customTarget, this.customValue);
      html = `<div class="bpp-properties-entry bpp-checkbox ${(this.settings.classes || []).join(
        ' ',
      )}">
      <input id="${this.UUID}" type="checkbox" name="${this.id}" ${checked}>
      <label for="${this.UUID}">${this.label || this.id || this.settings.customHtml}</label>
      </div>`;
      break;
      }

      case 'html': {
      html = this.settings.customHtml;
      break;
      }

      default:
      console.warn('Unknown html type meet for custom-control.', this.type);
    }

    return html;
  }

  bindDefault() {
    if (this.type === 'ioBox' && !!this.originalField.text && !this.originalField.value) {
      const target = document.getElementById(`${this.UUID}`) as HTMLInputElement;
      if (!target) {
        return;
      }
      target.value = this.originalField.text;
      this.originalField.value = this.originalField.text;
    }


    if (this.type === 'colorSelector') {
      this.bindColorEdit();
    }

    if (
      !this.id ||
      !this.originalField ||
      !(this.originalField[this.id] || this.originalField[this.customTarget])
    ) {
      return;
    }
    if (
      this.type === 'selector' &&
      this.settings.selectValues &&
      this.settings.selectValues.length
    ) {
      this.originalField[this.id] =
        this.originalField[this.id] || this.settings.selectValues[0].value;
    }

    if (this.type === 'textBox') {
      this.bindTextLoad();
    }
  }

  /**
   * Updates the HTML
   * @param values
   */
  update(values?: any) {
    const element = this.node.parentNode;
    if (!element) {
      return;
    }
    if (values) {
      this.customSetter(this.element, values, element);
    }
    this._oldHtml = '';
    element['outerHTML'] = this.html;
  }

  bindDelete() {
    const target = document.getElementById(`${this.UUID}-delete`);
    if (!target) {
      return;
    }
    target.addEventListener('click', () => {
      this.inputChanged.emit({ value: null, field: this });
    });
  }

  bindNameEdit() {
    const target = document.getElementById(`${this.UUID}-label`);
    if (!target || target.tagName.toLowerCase() !== 'input') {
      return;
    }
    target.addEventListener('change', event => {
      const value = event.target['value'];
      if (!value) {
        return; /* Skip this case. */
      }
      // Value === null => delete
      this.inputChanged.emit({ oldId: this.id, value, field: this });
    });
  }

  bindActions() {
    Object.keys(this.settings.actions || {}).map(action => {
      const target = this.node;
      if (!target) {
        return;
      }
      target.addEventListener(action, event => {
        this.settings.actions[action].bind(this)(event, this);
      });
    });
  }

  private bindColorEdit() {
    const target = document.getElementById(`${this.UUID}`);
    if (!target) {
      return;
    }
    target.addEventListener('change', event => {
      if (this.originalField) {
        this.originalField[this.id] = event.target['value'];
      }
      this.inputChanged.emit(event.target['value']);
    });
  }

  private bindTextLoad() {
    const target = document.getElementById(`${this.UUID}`);
    if (!target) {
      return;
    }
    const targetProp = this.customTarget;
    target.attributes['value'] = this.originalField[targetProp];
    target.addEventListener('change', event => {
      this.customSetter(this.element, { [targetProp]: event.target['value'] }, this.node);
    });
  }

  private log(...args) {
    if (!!window['___bpmnDev']) {
      console.log(...args);
    }
  }
}
