import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ForeignUserService } from '../../Services/foreign-user.service';
import { NotificationService } from '../../Services/notification.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-unlock-account',
  templateUrl: './unlock-account.component.html',
  styleUrls: ['./unlock-account.component.scss'],
})
export class UnlockAccountComponent implements AfterViewInit, OnDestroy {
  submitted: boolean = false;
  emailConfirmForm: FormGroup;
  hashLink: string;
  loaded = false;

  constructor(private formBuilder: FormBuilder, private foreignUserService: ForeignUserService,
    private notificationService: NotificationService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.hashLink = params['hash'];
    });

    this.foreignUserService.CheckHash(encodeURIComponent(this.hashLink)).then(res => {
      if (!res || !res.data) {
        this.notificationService.warning('Invalid hash or your account is already activated.');
        this.router.navigate(['/']);
      }
      this.loaded = true;
    }, (err) => console.log('on error', err));

    this.emailConfirmForm = this.formBuilder.group({
      hash: [this.hashLink, Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: this.MustMatch('password', 'confirmPassword')
    });
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
  }

  // convenience getter for easy access to form fields
  get f() { return this.emailConfirmForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.emailConfirmForm.invalid) {
      return;
    }
    this.foreignUserService.UnlockAccount(this.emailConfirmForm.value).then(res => {
      if (res && res.data) {
        this.notificationService.success('Password applied successfully! Now you can login in system.');
        this.router.navigate(['/login']);
      }
      if (res.error) {
        this.notificationService.error(`${res.error.message}`);
      }
    }, (err) => console.log('on error', err));
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

}
