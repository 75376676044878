
import {debounceTime} from 'rxjs/operators';
import {
  Component,
  ChangeDetectorRef,
  AfterViewInit,
  ElementRef,
  HostListener,
  EventEmitter,
  Output,
} from '@angular/core';
import { ApiService } from '@dotgov/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LanguageService } from '../../../Services/language.service';
import { Lang } from '../../../Models/language';
import { ruLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';

import { ApiHelper } from '../../../Helpers/api.helper';

@Component({
  selector: 'app-permits-issued-filter',
  templateUrl: './permits-issued-filter.component.html',
  styleUrls: ['../../Home/filter/filter.component.scss'],
})
export class PermitsIssuedFilterComponent implements AfterViewInit {
  static STATUSES = {
    'VALID': 1,
    'PROLONGED': 2,
    'CANCELED': 3,
    'WITHDRAWAL': 4,
    'SUSPENDED': 5,
    'EXPIRED': 6,
  };

  authorities: Object[] = [];
  permitTypes: Object[] = [];
  resultsPerPage: Object[] = [
    { value: '10', label: '10' },
    { value: '15', label: '15' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
  ];
  statuses: Object[] = [
    { value: PermitsIssuedFilterComponent.STATUSES.VALID, label: 'Valid' },
    { value: PermitsIssuedFilterComponent.STATUSES.PROLONGED, label: 'Prolonged' },
    { value: PermitsIssuedFilterComponent.STATUSES.CANCELED, label: 'Canceled' },
    { value: PermitsIssuedFilterComponent.STATUSES.WITHDRAWAL, label: 'Withdrawal' },
    { value: PermitsIssuedFilterComponent.STATUSES.SUSPENDED, label: 'Suspended' },
    { value: PermitsIssuedFilterComponent.STATUSES.EXPIRED, label: 'Expired' },
  ];
  maxWidth = '100%';

  // Selected inputs
  searchText: string;
  authorityId: number;
  permitTypeId: number;
  dateOfIssue: string;
  dateOfExpire: string;
  displayPerPage = '25';
  activeStatus: string;

  pageWidth: number;
  isCollapsed: boolean;

  filterForm: FormGroup;

  bsConfig = { containerClass: 'theme-default', showWeekNumbers: false, customTodayClass: 'custom-today-class' };

  @Output() itemsOnPage: EventEmitter<any> = new EventEmitter();
  @Output() filterChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    private apiService: ApiService,
    private el: ElementRef,
    private ref: ChangeDetectorRef,
    private language: LanguageService,
    private _localeService: BsLocaleService,
  ) {
    this.loadAuthorities(this.language.language);
    this.loadDocumentTypes();
    this.languageSubscribe();
    this.initFilter();
    // Waiting for RO to be included
    defineLocale('ru', ruLocale);
  }

  ngAfterViewInit() {
    this.onResize(window);
  }

  clearFilters() {
    this.filterForm.setValue({
      searchControl: null,
      authorityControl: null,
      typeOfPermitControl: null,
      dateOfExpire: null,
      dateOfIssue: null,
      statusControl: null,
    });
    this.authorityId = null;
    this.permitTypeId = null;
    this.dateOfExpire = null;
    this.dateOfIssue = null;
    this.searchText = null;
  }

  updateItemsOnPage(onPage) {
    this.itemsOnPage.emit(onPage);
  }

  collapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  get isMobile() {
    return Number(this.pageWidth) <= 991;
  }

  private languageSubscribe() {
    this.updateStatuses();
    this.language.languageChanged.subscribe((lang: Lang) => {
      this.loadAuthorities(lang);
      this.loadDocumentTypes();
      this.updateStatuses();
      if (lang.Code === 'ru' || lang.Code === 'en') {
        this._localeService.use(lang.Code);
      } else {
        this._localeService.use('en');
      }
    });
  }

  private updateStatuses() {
    const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);
    const _statuses = PermitsIssuedFilterComponent.STATUSES;
    const promises = Object.keys(_statuses).map((key) => this.language.getTranslate(key));
    Promise.all(promises).then((translates) => {
      Object.keys(this.statuses).forEach((_key, index) => {
        if (translates[index] === Object.keys(_statuses)[index]) {
          translates[index] = capitalize(Object.keys(_statuses)[index].toLocaleLowerCase());
        }
        this.statuses[_key].label = translates[index];
      });
    });
  }

  private loadAuthorities(lang?: Lang) {
    const defaultParams = ApiHelper.setupLanguage({ page: 0, limit: 0, start: 0 }, lang);
    this.apiService.get('public', ['data', 'Authorities'], defaultParams).then((response) => {
      const authorities = response.data && response.data.data || [];
      this.authorities = authorities.map((authority) => {
        return {
          value: authority.ID,
          label: authority[`Name_${this.language.language && this.language.language.Code}`] || authority.Name || authority.ShortName,
        };
      });
    });
  }

  private loadDocumentTypes() {
    const defaultParams = { page: 0, limit: 0, start: 0 };
    this.apiService.get('public', ['data', 'DocumentTypes'], defaultParams).then((response) => {
      const permitTypes = response.data && response.data.data || [];
      const promises = permitTypes.map((permitType) => {
        return this.language.getTranslate(permitType.Name).then((label) => {
          return { value: permitType.ID, label };
        });
      });
      Promise.all(promises).then((translatedPermitTypes) => {
        this.permitTypes = translatedPermitTypes;
      });
    });
  }

  private initFilter() {
    this.filterForm = new FormGroup({
      searchControl: new FormControl(null),
      authorityControl: new FormControl(null),
      typeOfPermitControl: new FormControl(null),
      dateOfIssue: new FormControl(null),
      dateOfExpire: new FormControl(null),
      statusControl: new FormControl(null),
    });

    this.filterForm.valueChanges.pipe(debounceTime(400)).subscribe((value) => {
      this.filterChanged.emit(value);
    });
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.pageWidth = event && event.innerWidth || event.target.innerWidth;
    this.maxWidth = `${this.el.nativeElement.offsetWidth}px`;
    this.isCollapsed = this.isMobile;
    this.ref.detectChanges();
  }
}
