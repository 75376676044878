import { Injectable } from '@angular/core';
import { AppInsights } from 'applicationinsights-js';
import { environment } from '../../environments/environment';

@Injectable()
export class MonitorService {
  private config: Microsoft.ApplicationInsights.IConfig = {};

  constructor() {
    if (!environment['appInsights']) {
      return;
    }
    this.config = { instrumentationKey: environment['appInsights'].instrumentationKey };
    if (!AppInsights.config) {
      AppInsights.downloadAndSetup(this.config);
    }
  }

  logPageView(name?: string, url?: string, properties?: any, measurements?: any, duration?: number) {
    if (!environment['appInsights']) {
      return;
    }
    AppInsights.trackPageView(name, url, properties, measurements, duration);
  }

  logEvent(name: string, properties?: any, measurements?: any) {
    if (!environment['appInsights']) {
      return;
    }
    AppInsights.trackEvent(name, properties, measurements);
  }
}
