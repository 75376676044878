import { AbstractProperty } from './abstract.tab';
import { AbstractBPMNControl } from './custom-controls';

export class ConditionTab extends AbstractProperty {
  constructor(element, elementRegistry, bpmnFactory, modeler, translate) {
    super(element, elementRegistry, bpmnFactory, modeler, translate, 'ConditionTab', 'Condition');

    this.groups = [this.conditionGroup];
  }

  get visibleIn() {
    return ['bpmn:SequenceFlow'];
  }

  get conditionGroup() {
    const group = {
      id: 'conditions',
      label: 'Conditions',
      entries: [],
    };

    this.conditionProps(group);
    return group;
  }

  get tableOptions() {
    return this.tables.map(table => this.optionSanitize(table.name));
  }

  get viewOptions() {
    return (this.activeTable.views || ['default']).map(key => this.optionSanitize(key));
  }

  get formOptions() {
    const forms = (this.activeTable.forms || []).map(key => this.optionSanitize(key));
    return forms.length ? forms : [this.optionSanitize('default')];
  }

  get optionSanitize() {
    return (name: string, val?: string) => ({ name, value: val || name });
  }

  get tables() {
    return (this.download('models') || {}).tables || [];
  }

  get activeTableName() {
    return this.targetField.tableName || (this.tables[0] && this.tables[0].name);
  }

  get activeTable() {
    return this.tables.find(table => table.name === this.activeTableName) || {};
  }

  private conditionProps(group: any) {
    if (!this.isVisible) {
      return;
    }
    const bElem = this.extensionElements(this.element);
    // @ts-ignore
    const click = ($event, abstractControl: AbstractBPMNControl) => {
      let properties = this.dotgovElements[0];
      if (!properties) {
        properties = this.generateItem(this.modeler, 'properties', bElem, {type: this.element && this.element.type});
      }
      if (!properties['conditions']) {
        properties['conditions'] = this.generateItem(this.modeler, 'conditions', properties);
      }
      properties['conditions'].values.push(
        this.generateItem(this.modeler, 'condition', properties['conditions']),
      );
      this.update(this.element);
    };
    const html = `<b class="text-capitalize">Add Condition</b> <i class="fa fa-plus"></i>`;
    const addConditions = new AbstractBPMNControl(
      this.element,
      'button',
      `addConditions`,
      '',
      null,
      null,
      { customHtml: html, actions: { click } },
    );
    group.entries.push(addConditions.control);

    const field = this.targetField;
    if (!field || !field.conditions || !field.conditions.values) {
      return;
    }
    field.conditions.values.forEach((condition, index) =>
      this.generateCondition(group, condition, index),
    );
    return group;
  }

  private generateCondition(group, condition, index) {
    if (!condition) {
      return;
    }
    const inputs = [
      {
        type: 'textBox',
        label: 'Target',
        id: 'name',
        placeholder: 'Your name here.',
      },
      {
        type: 'textBox',
        label: 'Operator',
        id: 'operator',
        placeholder: 'Your operator here.',
      },
      {
        type: 'textBox',
        label: 'Value',
        id: 'value',
        placeholder: 'Your value here.',
      },
      {
        type: 'textBox',
        label: 'Logical Condition',
        id: 'logical',
        placeholder: 'Your logical here.',
      },
    ];
    const separator = new AbstractBPMNControl(
      this.element,
      'html',
      `separator${index}`,
      '',
      '',
      '',
      { customHtml: `Condition ${index + 1}` },
    );
    group.entries.push(separator.control);
    inputs.forEach(input => {
      // @ts-ignore
      const change = (val: { target: Event; control: AbstractBPMNControl }) => {
        condition[input.id] = event.target['value'];
      };
      const control = new AbstractBPMNControl(
        this.element,
        'textBox',
        `${input.id}${index}`,
        input.label,
        `${input.id}${index}`,
        condition,
        { customValue: input.id, placeholder: input.placeholder, actions: { change } },
      );
      group.entries.push(control.control);
    });
  }
}
