// Require all properties you need from existing providers.
// In this case all available bpmn relevant properties without camunda extensions.
import { GeneralTab } from '../models/general.tab';
import { InputOutputTab } from '../models/input-output.tab';
import { BindingTab } from '../models/binding.tab';
import { ConditionTab } from '../models/condition.tab';
import { IPropertiesProvider } from './bpmn-js';

export class CustomPropertiesProvider implements IPropertiesProvider {
  static $inject = [
    'eventBus',
    'canvas',
    'bpmnFactory',
    'elementRegistry',
    'translate',
    'bpmnPropertiesProvider',
  ];
  private modeler;

  constructor(
    public eventBus: any,
    public canvas: any,
    public bpmnFactory: any,
    public elementRegistry: any,
    public translate: any,
    public bpmnPropertiesProvider: any,
  ) {}

  getTabs(element: any) {
    if (this.modeler) {
      this.bpmnFactory = this.bpmnFactory || this.modeler.get('bpmnFactory');
      this.elementRegistry = this.elementRegistry || this.modeler.get('elementRegistry');
    }

    const generalTab = new GeneralTab(
      element,
      this.elementRegistry,
      this.bpmnFactory,
      this.modeler,
      this.translate,
    );
    const inputOutputTab = new InputOutputTab(
      element,
      this.elementRegistry,
      this.bpmnFactory,
      this.modeler,
      this.translate,
    );
    const bindingTab = new BindingTab(
      element,
      this.elementRegistry,
      this.bpmnFactory,
      this.modeler,
      this.translate,
    );
    const conditionTab = new ConditionTab(
      element,
      this.elementRegistry,
      this.bpmnFactory,
      this.modeler,
      this.translate,
    );
    // add binding groups to general tab
    generalTab.tab.groups = [...generalTab.tab.groups, ...bindingTab.tab.groups];
    return [generalTab.tab, inputOutputTab.tab, conditionTab.tab];
  }
}
