import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '../Components/Home/home.component';
import { NotFoundComponent } from '../Components/404NotFound/404.component';
import { LoginComponent } from '../Components/Login/login.component';
import { LogoutComponent } from '../Components/Logout/logout.component';
import { FAQComponent } from '../Components/FAQ/faq.component';
import { StaticComponent } from '../Components/Static/static.component';
import { MPassLoginComponent } from '../Components/MPassLogin/mpass.component';
import { MPassLoggedinComponent } from '../Components/MPassLoggedIn/mpass-loggedin.component';
import { IssuedPermitComponent } from '../Components/issued-permit/issued-permit.component';
import { NotificationsPageComponent } from '../Components/notifications-page/notifications-page.component';
import { ChangePasswordComponent } from '../Components/ChangePwd/change-password.component';
import { ConfirmEmailComponent } from '../Components/ConfirmEmail/confirm-email.component';
import { UnlockAccountComponent } from '../Components/UnlockAccount/unlock-account.component';
import { RestorePasswordComponent } from '../Components/RestorePwd/restore-password.component';
import { ForeignRegSuccessComponent } from '../Components/ForeignRegSuccess/foreignregsuccess.component';
import { HelpComponent } from '../Components/help/help.component';

const appRoutes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'issued-permit/:externalKey',
    component: IssuedPermitComponent,
  },
  {
    path: 'mpasslogin',
    component: MPassLoginComponent,
  },
  {
    path: 'foreignregsuccess',
    component: ForeignRegSuccessComponent,
  },
  {
    path: 'mpassloggedin',
    component: MPassLoggedinComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'faq',
    component: FAQComponent,
  },
  {
    path: 'static',
    component: StaticComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'logout/:session',
    component: LogoutComponent,
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: 'notifications',
    component: NotificationsPageComponent,
  },
  {
    path: 'changepwd',
    component: ChangePasswordComponent,
  },
  {
    path: 'restorepwd',
    component: RestorePasswordComponent,
  },
  {
    path: 'confirmemail/:hash',
    component: ConfirmEmailComponent,
  },
  {
    path: 'unlockaccount/:hash',
    component: UnlockAccountComponent,
  },
  {
    path: 'ep',
    loadChildren: './lazy/lazy.module#LazyModule',
  },
  // TODO: check if we can move those components outside of lazy module to avoid those redirects
  {
    path: 'register',
    redirectTo: 'ep/register'
  },
  {
    path: 'register-company',
    redirectTo: 'ep/register-company'
  },
  {
    path: 'help',
    component: HelpComponent
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { useHash: true }),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule { }
