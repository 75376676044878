import { Injectable, EventEmitter, ApplicationRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@dotgov/core';
import { Lang } from '../Models/language';

@Injectable()
export class LanguageService {
  translations = {};
  defaultLanguage: Lang = new Lang('English', 'en');
  languageChanged = new EventEmitter<Lang>();

  private _initted = false;
  private _languages: Lang[] = [new Lang('English', 'en'), new Lang('Romanian', 'ro'), new Lang('Russian', 'ru')];
  private _language: Lang;

  constructor(
    private translate: TranslateService,
    private apiService: ApiService,
    private ref: ApplicationRef,
  ) {
    this._language = this.loadSavedLanguage() || this.defaultLanguage;
  }

  init() {
    this.setupLanguage(this.language);
  }

  loadLanguageTranslation(lang: Lang) {
    if (this.translate.getLangs().indexOf(lang.Code) === -1) {
      this.setupLanguage(lang);
    }
  }

  loadSavedLanguage() {
    return JSON.parse(localStorage.getItem('language'));
  }

  getTranslate(key: string): Promise<string> {
    if (typeof key !== 'string') {
      key = String(key);
    }
    return new Promise((resolve, reject) => {
      const origin = key && key.toLowerCase() || '';
      this.translate.get(key && key.toLowerCase() || '').subscribe((translated) => {
        // Translate not found, return to initial case
        if (translated === origin) {
          return resolve(key);
        }
        return resolve(translated);
      }, reject);
    });
  }

  get languages(): Lang[] {
    return this._languages;
  }

  get language(): Lang {
    return this._language;
  }

  get loaded(): boolean {
    return this._initted;
  }

  set language(lang: Lang) {
    this._language = lang;
    this.setupLanguage(lang);
  }

  private setupLanguage(lang: Lang) {
    this.translate.use(this.language.Code);
    this.translate.addLangs([this.language.Code]);
    this.apiService.get('translate', [this.language.Code]).then((result) => {
      const translations = result.data;
      this.translations[lang.Code] = translations;
      this.translate.setTranslation(this.language.Code, translations);
      localStorage.setItem('language', JSON.stringify(lang));
      this.languageChanged.emit(lang);
      this._initted = true;
      this.ref.tick();
    });
  }
}
