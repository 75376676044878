export class Link {
  constructor(
    public iconCls: string,
    public id: string,
    public link: string | boolean,
    public title: string,
    public type: string,
    public disabled: boolean = false,
  ) { }
}
