import { AbstractProperty } from './abstract.tab';
import { AbstractBPMNControl } from './custom-controls';
import { IO_TYPES, IOtypes, DOTGOV_TYPES_PREFIXED } from './constants';


export class InputOutputTab extends AbstractProperty {
  constructor(element, elementRegistry, bpmnFactory, modeler, translate) {
    super(
      element,
      elementRegistry,
      bpmnFactory,
      modeler,
      translate,
      'InputOutputTab',
      'Input/Output',
    );

    this.groups = [this.inputGroup, this.outputGroup, this.buttonsGroup];
  }

  get visibleIn() {
    return [
      'bpmn:UserTask',
      'bpmn:ServiceTask',
      'bpmn:ScriptTask',
      'bpmn:SendTask',
      'bpmn:SubProcess',
      'bpmn:Process',
    ];
  }

  get inputGroup() {
    const group = {
      id: 'input',
      label: 'Inputs',
      entries: [],
    };

    this.props(group, 'input');
    return group;
  }

  get outputGroup() {
    const group = {
      id: 'output',
      label: 'Outputs',
      entries: [],
    };

    this.props(group, 'output');
    return group;
  }

  get buttonsGroup() {
    if (!this.element || this.element.type !== 'bpmn:UserTask') {
      return;
    }
    const group = {
      id: 'buttons',
      label: 'Buttons',
      entries: [],
    };

    this.props(group, 'buttons');
    return group;
  }



  props(group: any, ioType: IOtypes) {
    const bElem = this.extensionElements(this.element);
    if (!bElem) {
      return;
    }
    const IOField = (field, index) => {
      const id =   field.command || field.name ;
      const label = field.label || ((ioType === IO_TYPES.BUTTONS && IO_TYPES.COMMAND) || ioType);
      const customValue = (ioType === IO_TYPES.BUTTONS) ? 'text' : 'value';
      const controlType = (ioType === IO_TYPES.BUTTONS) ? 'command' : 'ioBox';
      // Create input component on property tab ( e.g: input boxes in binding, input tabs etc.)
      const control = new AbstractBPMNControl(
        this.element,
        controlType,
        id,
        label,
        field.modelProperty || field.command,
        field,
        { customValue, index },
      );
      control.originalField = field;
      control.inputChanged.subscribe(
        (val: { oldId: string; value: string; field: AbstractBPMNControl }) => {
          if (val.value === null) {
            field.$parent.values = field.$parent.values.filter(_field => {
              return Object.keys(_field).some(key => field[key] !== _field[key]);
            });
            this.update(this.element);
            return;
          }
          if (field.$type === DOTGOV_TYPES_PREFIXED.button) {
            field.command = val.value;
            return;
          }

          field.name = val.value;
        },
      );
      return control.control;
    };
    // @ts-ignore
    const click = ($event, abstractControl: AbstractBPMNControl) => {
      let properties = dotgovElements[0];
      if (!properties) {
        properties = this.generateItem(this.modeler, 'properties', bElem);
      }
      if (!properties[ioType]) {
        properties[ioType] = this.generateItem(this.modeler, ioType, properties);
      }

      if (!properties[ioType].values) {
        properties[ioType].values = [];
      }

      const key = ioType === 'buttons' ? 'button' : 'field';
      properties[ioType].values.push(this.generateItem(this.modeler, key, properties[ioType]));
      this.update(this.element);
    };
    const dotgovElements = (bElem.values || []).filter(
      elem => (elem.$type || '').indexOf('dotgov') !== -1,
    );
    const html = `<b class="text-capitalize">${ioType}</b> <i class="fa fa-plus"></i>`;
    const addInput = new AbstractBPMNControl(
      this.element,
      'button',
      `add${ioType}`,
      '',
      null,
      null,
      { customHtml: html, actions: { click } },
    );
    group.entries.push(addInput.control);
    const fields = this.gatherIO(ioType, dotgovElements);
    fields.forEach((field, index) => group.entries.push(IOField(field, index)));
  }

  /**
   * Return list of Inputs/Outputs for current element
   */
  private gatherIO(ioType: IOtypes, targetElements) {
    const results = [];
    targetElements
      .filter(element => element.$type && element.$type.startsWith('dotgov:'))
      .forEach(element => {
        results.push(...((element[ioType] && element[ioType].values) || []));
      });
    return results;
  }
}
