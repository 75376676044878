import { Component } from '@angular/core';
import { BuilderService } from '../../services/builder.service';
import { ApiService } from '@dotgov/core';
// import { environment } from 'src/environments/environment';

@Component({
  selector: 'fb-builder',
  templateUrl: './builder.component.html',
  styleUrls: ['./builder.component.less'],
})
export class BuilderComponent {
  modalTitle = 'Form Builder';
  modalClass = 'modal-full';
  showModal;
  form: any;
  availableModels: any;

  private _fieldCollection: string[] = [];
  private _url: string;

  constructor(private builder: BuilderService, private api: ApiService) {
    this.builder.builderUrl.subscribe((url) => {
      this._url = url;
      this.loadForm();
    });
  }

  closeModal() {
    this.showModal = false;
    this.form = undefined;
  }

  onSave({ models, task }) {
    if (!this.modelUrl || !this.formUrl) {
      return;
    }
    Promise.all([
      this.api.post(this.modelUrl, [], {}, models),
      this.api.put(this.formUrl, [], {}, task),
    ]).then((responses) => {
      const errors = responses.map((resp) => resp.error).filter((err) => !!err);
      if (errors.length) {
        alert('Something went wrong');
        console.log(errors)
        return;
      }
      alert('Everything went fine');
    });
  }

  get options() {
    const options = {};
    // if (environment.apiUrl) {
    //   options['apiUrl'] = environment.apiUrl;
    // }
    return options;
  }

  get modelUrl() {
    const options = Object.assign({}, this.options);
    return this.api.getURL('Designer', ['model', this._url.split('/')[0]], {}, {}, options);
  }

  get formUrl() {
    const options = Object.assign({}, this.options);
    return this.api.getURL('Designer', ['forms', this._url], {}, {}, options);
  }

  private async loadForm() {
    this.closeModal();
    if (!this.modelUrl || !this.formUrl) {
      return;
    }
    const model = await this.api.get(this.modelUrl);
    const form = await this.api.get(this.formUrl);
    if ([model, form].find(response => response.error)) {
      return;
    }
    this.availableModels = model.data;
    this._fieldCollection = ((model.data || {}).fieldCollection || []).map((field) => field.name);
    this.form = form.data;
    this.showModal = true;
  }
}
