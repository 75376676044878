import { Component, ReflectiveInjector } from '@angular/core';
import { LoginService } from '../../Services/login.service';
import { NotificationService } from '../../Services/notification.service';
import { MonitorService } from '../../Services/monitor.service';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private loginService: LoginService,
    private notification: NotificationService,
    private router: Router,
    private monitorService: MonitorService, // Dont delete this line
  ) {
    const auth = JSON.parse(localStorage.getItem('auth'));
    // sometimes after mpass log in user is logged out
    const shouldCheckAuth = !window.location.href.includes('mpassloggedin');
    if (auth &&  shouldCheckAuth) {
      const expires = new Date(auth.expires);
      if (expires <= new Date()) {
        this.notification.warning(`Session expired, please login again.`);
        this.loginService.logout();
      }
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd) || !environment['appInsights']) {
        return;
      }
      // Manually rerieve the monitoring service from the injector
      // so that constructor has no dependencies that must be passed in from child
      const injector = ReflectiveInjector.resolveAndCreate([
        MonitorService,
      ]);
      this.monitorService = injector.get(MonitorService);
      this.monitorService.logPageView();
    });
  }
}
