import { LanguageService } from "../Services/language.service";

export const ERROR_MESSAGES = {
  emailAlreadyTaken: 'email address %s is associated with another mpass account'
};


export const LANGUAGE_PREFIXES = {
  en: 'en',
  ro: 'ro',
  ru: 'ru'
};
