import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class BuilderService {
  private _builderStatusEvent = new EventEmitter<string>();

  constructor() { }

  set builderUrl(url: any) {
    this._builderStatusEvent.emit(url);
  }

  get builderUrl() {
    return this._builderStatusEvent;
  }
}