import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ForeignUserService } from '../../Services/foreign-user.service';
import { NotificationService } from '../../Services/notification.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.scss'],
})
export class RestorePasswordComponent implements AfterViewInit, OnDestroy {
  submitted: boolean = false;
  restorePwdForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private foreignUserService: ForeignUserService,
    private notificationService: NotificationService, private router: Router) { }


  ngOnInit() {
    this.restorePwdForm = this.formBuilder.group({
      email: new FormControl('', { validators: Validators.compose([Validators.required, Validators.email]), asyncValidators: this.emailValidator(), updateOn: 'blur' })
    });
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
  }

  // convenience getter for easy access to form fields
  get f() { return this.restorePwdForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.restorePwdForm.invalid) {
      return;
    }
    this.foreignUserService.sendPasswordResetLink(this.restorePwdForm.value).then(res => {
      if (res && res.data) {
        this.notificationService.success('Recovery password instruction sent to your Email.');
        this.router.navigate(['/']);
      }
      if (res.error) {
        this.notificationService.error(`${res.error.message}`);
      }
    }, (err) => console.log('on error', err));
  }

  emailValidator() {
    const validator = this.foreignUserService.checkEmail.bind(this.foreignUserService);
    return function (formControl): Observable<any> {
      return new Observable((obs) => {
        if (!formControl.value) {
          obs.next(null);
          obs.complete();
          return;
        }
        validator(formControl.value).then(res => {
          if (res && res.data) {
            obs.next(null);
            obs.complete();
          }
          obs.next({ notRegistered: true });
          obs.complete();
        });
      });
    }
  }

}
