export interface IGroup {
  id: string;
  label: string;
  entries?: any[];
  groups?: any[];
}

let GENERICID = 1;

function genericId() {
  return GENERICID++;
}

export class AbstractProperty {
  // DONT REDECLARE THIS
  static readonly GENERIC_NAME = 'genericName';
  private _downloads;
  constructor(
    protected element,
    protected elementRegistry,
    protected bpmnFactory,
    protected modeler: any,
    protected translate: any,
    protected id: string,
    label?: string,
    groups: any[] = [],
  ) {
    this._tab = {
      id: id || `group_`,
      label: label || (id && id.toUpperCase()) || `label_`,
      groups,
    };
  }

  private _tab: any;

  get tab(): any {
    return this._tab;
  }

  private _groups: { [key: string]: any } = {};

  get groups(): any[] {
    return Object.keys(this._groups).map(key => this._groups[key]);
  }

  set groups(_group: any[]) {
    if (!this.isVisible) {
      return;
    }
    (_group || [])
      .filter(item => !!item)
      .forEach((group: any) => {
        this._groups[group.id] = group;
      });
    this._tab.groups = this.groups;
  }

  get visibleIn() {
    return [];
  }

  get isVisible() {
    if (!this.visibleIn || !this.visibleIn.length) {
      return true;
    }
    if (!this.element && !this.element.type) {
      return;
    }
    return this.visibleIn.indexOf(this.element.type) !== -1;
  }

  protected get targetField() {
    const dotgovElements = this.dotgovElements || [];
    return dotgovElements.find(elem => elem.taskType) || dotgovElements[0];
  }

  protected get dotgovElements() {
    const bElem = this.extensionElements(this.element);
    if (!bElem) {
      return;
    }
    const dotgovElements = (bElem.values || []).filter(
      elem => (elem.$type || '').indexOf('dotgov') !== -1,
    );
    return dotgovElements;
  }

  /**
   * Used to generate new bpmn item
   * @param config
   */
  static OPTIONS(config: { [key: string]: any } = {}) {
    return {
      extension: ['bpmn:ExtensionElements', { values: config.values || [] }],
      properties: [
        'dotgov:Properties',
        (() => {
          // Self invoking function to cover some logic
          if (config.type === 'bpmn:SequenceFlow' && !config.taskType ) {
            return '';
          }

         const prop: any =  {
            taskType: config.taskType || 'StoredProcedure',
          };
         if (config.storedProcedure) {
            prop.storedProcedure = config.storedProcedure;
          }
         return prop;
        })(),
      ],
      input: ['dotgov:Input', { values: config.values || [] }],
      output: ['dotgov:Output', { values: config.values || [] }],
      field: ['dotgov:Field', { name: config.name || `${AbstractProperty.GENERIC_NAME}${genericId()}`, value: '' }],
      conditions: ['dotgov:Conditions', { values: [] }],
      condition: [
        'dotgov:Condition',
        { name: config.name || '', value: '', logical: '', operator: '' },
      ],
      buttons: ['dotgov:Buttons', { values: config.values || [] }],
      button: ['dotgov:Button', { command: config.name || `${AbstractProperty.GENERIC_NAME}${genericId()}`, text: '' }]
    };
  }

  protected download(model): any {
    return this._downloads[model];
  }

  protected generateItem(
    modeler: any,
    key: string,
    $parent?: any,
    config: { [key: string]: any } = {},
  ) {
    const options = AbstractProperty.OPTIONS(config);
    if (!options) {
      console.error(`Unknown property '${key}' for options.`);
      return;
    }
    const target = options[key];
    const result = modeler._moddle.create(...target || {});
    result.$parent = $parent;
    if (key === 'properties') {
      // Must create extension
      const _target = result.$parent.businessObject || result.$parent;
      if (_target.$type !== 'bpmn:ExtensionElements') {
        const extension = this.generateItem(modeler, 'extension', _target);
        _target.extensionElements = extension;
        result.$parent = extension;
      }
      result.$parent.values.push(result);
    }
    return result;
  }

  protected update(element) {
    const propertiesPanel = this.modeler.get('propertiesPanel');
    setTimeout(() => {
      propertiesPanel.update(element, [this.tab]);
    }, 10);
  }

  protected extensionElements(element) {
    return (element && (element.businessObject || {}).extensionElements) || element;
  }
}
