import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { SelectModule } from 'ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { DGSCoreModule, DGSUICoreModule, IDGSEnvironment } from '@dotgov/core';
import { TreeModule } from '@circlon/angular-tree-component';
import { NgxCaptchaModule } from 'ngx-captcha';


import { SpinnerComponent } from '../../Components/Spinner/spinner.component';
import { NotFoundComponent } from '../../Components/404NotFound/404.component';
import { HomeComponent } from '../../Components/Home/home.component';
import { HomePermitsComponent } from '../../Components/Home/permits/permits.component';
import { HomeFilterComponent } from '../../Components/Home/filter/filter.component';
import { PermitsIssuedComponent } from '../../Components/permits-issued/permits-issued.component';
import { PermitsIssuedFilterComponent } from '../../Components/permits-issued/permits-issued-filter/permits-issued-filter.component';
import { PermitsIssuedListComponent } from '../../Components/permits-issued/permits-issued-list/permits-issued-list.component';
import { environment } from '../../../environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CurrentTeamInterceptor } from '../../Services/current-team.interceptor';
import { SelectAuthorityComponent } from '../../Components/select-authority/select-authority.component';
import { SameAsFieldsComponent } from '../../Components/shared/same-as-fields/same-as-fields.component';
import { DateComponent } from '../../Components/shared/date/date.component';

const DGSEnvironment: IDGSEnvironment = {
  debug: environment.debug,
  apiUrl: environment.apiUrl,
};
@NgModule({
  declarations: [
    SpinnerComponent,
    NotFoundComponent,
    HomeComponent,
    HomePermitsComponent,
    HomeFilterComponent,
    PermitsIssuedComponent,
    PermitsIssuedFilterComponent,
    PermitsIssuedListComponent,
    SelectAuthorityComponent,
    SameAsFieldsComponent,
    DateComponent
  ],
  imports: [
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    TranslateModule.forRoot(),
    CollapseModule.forRoot(),
    DGSCoreModule.forRoot(DGSEnvironment),
    DGSUICoreModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    SelectModule,
    FormsModule,
    CommonModule,
    RouterModule,
    TreeModule,
    NgxCaptchaModule
  ],
  exports: [
    BsDatepickerModule,
    BsDropdownModule,
    AccordionModule,
    TranslateModule,
    CollapseModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    SelectModule,
    FormsModule,
    DGSCoreModule,
    DGSUICoreModule,
    NotFoundComponent,
    SpinnerComponent,
    HomeComponent,
    PermitsIssuedComponent,
    TreeModule,
    SelectAuthorityComponent,
    NgxCaptchaModule,
    SameAsFieldsComponent,
    DateComponent
  ],
  providers: [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: CurrentTeamInterceptor,
        multi: true
    }
  ],
  entryComponents: [
    NotFoundComponent,
  ],
})
export class SharedModule { }

