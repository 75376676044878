import { Component, AfterViewInit, ChangeDetectorRef, ViewChild, ViewContainerRef, HostListener } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FVAbstractControl, ProcessService } from '@dotgov/formviewer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiService, ApiResponse } from '@dotgov/core';
import { DownloadHelper } from '../../../Helpers/download.helper';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'm-pay',
  templateUrl: './m-pay.component.html',
  styleUrls: ['./m-pay.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class MPayComponent extends FVAbstractControl implements AfterViewInit {
  showModal: boolean;
  actionUrl: string;
  paymentStatus: string;
  paymentInProgress: boolean;
  payBtnDisabled = true;

  @ViewChild('modalRef') modalRef: BsModalRef;
  @ViewChild('frmPayment', { read: ViewContainerRef }) frmPayment;

  private _recordData: Object = {};
  private _modal: any;

  constructor(
    private modalService: BsModalService,
    private ref: ChangeDetectorRef,
    private api: ApiService,
    process: ProcessService,
  ) {
    super(process);
  }

  ngAfterViewInit() {
    this._recordData = this.recordData;
    this._recordData['ReturnUrl'] = `${this.sanitizeApiUrl(this.api.API)}${this._recordData['ReturnUrl']}`;
    this.payBtnDisabled = false;
    this.ref.detectChanges();
  }

  @HostListener('window:message', ['$event'])
  onMessage(event) {
    if (event && event.data && event.data.ui) {
      return this.paymentRequestDone(event);
    }
    console.warn('Something send wrong message', event);
  }

  downloadInvoice(orderId: string) {
    if (!orderId) {
      return;
    }
    const options = {
      headers: {
        'Accept': '*/*',
      },
      skipCache: true,
      responseType: 'arraybuffer',
    };
    this.api.get('PDFReport', ['EP_Orders', 'Invoice', this.data['Keyfield']], {}, options).then((data: ApiResponse) => {
      if (data.error) {
        this.error('Could not download payment invoice', data.error);
        return;
      }

      const file = new Blob([data.data], { type: 'application/pdf' });
      const filename = `invoice_${this.data['OrderNumber']}.pdf`;
      DownloadHelper.triggerDownload(file, filename);
    });
  }

  openModal() {
    this.showModal = true;
    this._modal = this.modalService.show(this.modalRef, { class: 'modal-lg modal-fs', ignoreBackdropClick: true });
  }

  pay() {
    this.api.get('mpay_service_url', [], {}, { skipCache: true }).then((data: ApiResponse) => {
      if (data.error) {
        this.warn('Failed to load mpay service url', data.error);
        return;
      }
      const response = data.data;
      const actionUrl = this.actionUrl = response['actionUrl'];
      const orderKey = this.data['OrderNumber'];
      if (!actionUrl) {
        this.warn('Action url is missing from mpay response!');
        return;
      }
      if (!orderKey) {
        this.warn('Order Number is missing from mpay data!');
        return;
      }
      setTimeout(() => {
        this.frmPayment.element.nativeElement.submit();
        this.log('Submitting payment form ');
      }, 0);
    });
  }

  checkStatus() {
    this.api.get('payment_check', [], { orderId: this.data['OrderNumber'] }, { skipCache: true }).then((data: ApiResponse) => {
      if (data.error) {
        this.warn(`Trying to check payment failed`, data.error);
        return;
      }
      this.updatePayment(data.data);
    });
  }

  hide(showWarn: boolean = true) {
    this.showModal = false;
    if (!this._modal || !this._modal.hide) {
      if (showWarn) {
        this.warn('Something wrong happened with modal');
      }
      return;
    }
    this._modal.hide();
  }

  get data() {
    return this._recordData;
  }

  private sanitizeApiUrl(url) {
    return url.indexOf('://') === -1 && `${window.location.origin}/${url}` || url;
  }

  private updatePayment(_status) {
    const oldStatus = this.paymentStatus;
    const status = (typeof _status === 'number' || typeof _status === 'string') ? _status : _status.status;
    switch (Number(status)) {
      case 0:
        this.paymentStatus = 'Pending';
        this.paymentInProgress = true;
        break;
      case 1:
        this.paymentStatus = 'In Progress';
        this.paymentInProgress = true;
        break;
      case 2:
        this.paymentStatus = 'Paid';
        this.paymentInProgress = false;
        break;
      default:
        this.paymentStatus = 'In Progress';
        this.paymentInProgress = true;
        break;
    }
    if (oldStatus !== this.paymentStatus) {
      this.log(`Payment status changed from ${oldStatus} to ${this.paymentStatus}`);
    }
    this.hide(false);
  }

  private paymentRequestDone(event) {
    if (!event.data.length) {
      this.warn('Unexpected payment request result data.');
      this.paymentStatus = 'Unknown. Unexpected payment request result data';
      this.paymentInProgress = false;
      return;
    }
    this.checkStatus();
  }

}

