import { Component, ElementRef, AfterViewInit } from '@angular/core';
import { DataService } from '../../../Services/data.service';
import { LanguageService } from '../../../Services/language.service';
import { LoginService } from '../../../Services/login.service';
import { ApiService } from '@dotgov/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements AfterViewInit {
  private readonly FONT_METADATA = {
    default: 14,
    max: 20,
    min: 10,
  };

  isHeaderCollapsed = false;
  firstLoad = true;

  styleTag: any;
  fontSize = this.FONT_METADATA.default;
  notifications = [];

  private appData: Object = {};

  constructor(
    private dataService: DataService,
    private loginService: LoginService,
    private languageService: LanguageService,
    private ref: ElementRef,
    private api: ApiService,
  ) {
    this.loginService.loginChanged.subscribe(() => {
      // this.loadNotifications();
      this.dataService.forceLoadAppData().then((appData) => {
        this.appData = appData;
      });
    });
    this.loadAppData();
    const style = this.styleTag = document.createElement('style');
    const script = document.getElementsByTagName('script')[0];
    script.parentNode.insertBefore(style, script);
  }

  get logged() {
    return this.loginService.LOGIN_TYPE !== LoginService.NOT_LOGGED;
  }
  get teams() {
    return this.loginService.lastUserContext.teams;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.fontReset();
      this.languageService.init();
    }, 0);
  }

  fontIncrease() {
    if (this.fontSize >= this.FONT_METADATA.max) {
      return;
    }
    this.fontSize++;
    this.updateFont();
  }

  fontDecrease() {
    if (this.fontSize <= this.FONT_METADATA.min) {
      return;
    }
    this.fontSize--;
    this.updateFont();
  }

  fontReset() {
    this.fontSize = this.FONT_METADATA.default;
    this.updateFont();
  }

  private get style() {
    return `* { font-size: ${this.fontSize}px; }`;
  }

  private updateFont() {
    try {
      this.styleTag.innerHTML = this.style;
    } catch (error) {
      // IE fix
      this.styleTag.styleSheet.cssText = this.style;
    }
  }

  private loadAppData() {
    this.dataService.appDataChanged.subscribe(this.resolveAppData.bind(this));
  }

  private resolveAppData(appData) {
    this.appData = appData;
    if (this.firstLoad) {
      this.firstLoad = false;
      this.addJsToElement('assets/formhelper.js');
      this.addJsToElement('assets/formshared.js');
    }
  }

  // private loadNotifications() {
  //   if (this.logged) {
  //     this.api.get('notifications', [], { page: 0, start: 0, limit: 10 }).then((response) => {
  //       this.notifications = (response.data && response.data.rows || []).sort((a, b) => <any>new Date(b.Date) - <any>new Date(a.Date));
  //     });
  //   } else {
  //     this.notifications = [];
  //   }
  // }

  private addJsToElement(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.charset = 'utf-8';
    script.async = true;
    this.ref.nativeElement.appendChild(script);
    return script;
  }

  get slogan() {
    return this.appData['description'] || '';
  }

  get tabs() {
    return this.appData['tabs'];
  }

  get isAdmin() {
    return this.dataService.isAdmin;
  }

}
