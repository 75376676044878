import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, Form } from '@angular/forms';
import { flatMap, flatMapDeep } from 'lodash';
import { ForeignUserService } from '../../Services/foreign-user.service';
import { NotificationService } from '../../Services/notification.service';
import { Router } from '@angular/router';
import { Observable, of, from } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { LanguageService } from '../../Services/language.service';
import { RegistrationField } from '../../Models/registration-field.model';
import { RecaptchaCheckService } from '../../Services/recaptcha.service';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { RegistrationSection } from '../../Models/registration-section.model';
import * as moment from 'moment';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  processInstanceId: any;
  error: any;
  task: any;
  submitted = false;
  nodes = [
  ];

  constructor(public recaptchaCheckService: RecaptchaCheckService, private fb: FormBuilder, private foreignUserService: ForeignUserService,
    private noficationService: NotificationService, private router: Router, private languageService: LanguageService) { }


  rows: [RegistrationField[]];
  sections: RegistrationSection[];
  selectedAuthority;
  registrationForm: FormGroup;
  filesToUpload: {file: File, field: RegistrationField}[] = [];
  private readonly COUNTRY_FIELDS = ['residentCountry', 'postalCountry', 'docIssueCountry'];
  private readonly DISTRICT_FIELDS = ['residentDistrict', 'postalDistrict'];
  private countryList = [];
  private positionsList = [];

  ngOnInit() {
    this.foreignUserService.getRegisterUserField().then((res: {data: RegistrationSection[]}) => {
      const ff = {};
      if (res && res.data) {
        this.sections = res.data;
        const allRows = res.data.map(r => r.rows);
        this.rows = flatMap(allRows) as [RegistrationField[]];
        flatMapDeep<RegistrationField>(allRows).forEach((f) => {
          switch (f.id) {
            case 'email': {
              ff[f.id] = new FormControl('', { validators: Validators.compose(f.required ? [Validators.required, Validators.email] : null),
                asyncValidators: this.emailValidator(f),
                updateOn: 'blur' });
              break;
            }
            case 'dateOfBirth': {
              ff[f.id] = new FormControl('', Validators.compose([f.required && Validators.required, this.moreThanYearsValidator(16) ]));
              break;
            }
            case 'docIssueDate': {
              ff[f.id] = new FormControl('', Validators.compose([f.required && Validators.required, this.currentDayOrDayInPast() ]));
              break;
            }
            case 'docExpireDate': {
              ff[f.id] = new FormControl('', Validators.compose([f.required && Validators.required, this.currentDayOrDayInFuture() ]));
              break;
            }
            default: {
              ff[f.id] = this.fb.control(f.value || '', Validators.compose(f.required ? [Validators.required] : null));
            }
          }
        });
        ff['recaptcha'] = new FormControl('');
        this.registrationForm = this.fb.group(ff);
        return;
      }
    }).then(() => {
      this.foreignUserService.getPositionsList().then((res) => {
        if (res && res.data) {
          const data = res.data.map((c) => {
            return {
              label: c.name,
              value: c.id
            };
          });
          this.positionsList = data;
        }
      });

      this.foreignUserService.getCountryList().then((res) => {
        if (res && res.data) {
          const data = res.data.map((c) => {
            const translatedName = c[`name_${this.languageService.language.Code}`];
            return {
              label: !translatedName ? c.name : translatedName,
              value: c.id
            };
          });
          this.countryList = data;
        }
      });
      this.bindDistrictOptionsToCountry();
    });
  }

  onSubmit(captchaElem: InvisibleReCaptchaComponent) {
    this.submitted = true;
    if (this.registrationForm.invalid || !this.selectedAuthority) {
      window.scrollTo(0, 0);
      return;
    }
    const ff = this.registrationForm.value;
    ff['teamId'] = this.selectedAuthority;
    //parse date to string
    Object.keys(ff).forEach(key => {
      if (key.toLowerCase().includes('date')) {
        ff[key] = moment(ff[key]).format('YYYY-MM-DD');
      }
    })
    this.recaptchaCheckService.execute(captchaElem).subscribe(() => {
      this.foreignUserService.register(this.registrationForm.value).then((res) => {
        if (res && res.data) {
          Promise.all(this.filesToUpload.map(f => {
             return this.foreignUserService.registerDocument(f.file, res.data.message, f.field.documentTypeId);
            })).then(success => {
            if (success) {
              this.noficationService.success('Form submitted successfully');
              this.router.navigate(['/foreignregsuccess']);
            }
          });
        }
      });
    });

  }


  getOptions(field): any {
    return new Observable(obs => {
      if (this.COUNTRY_FIELDS.includes(field.id) && !field.options.length) {
        obs.next(this.countryList);
        field.options = this.countryList;
        obs.complete();
      } else {
        obs.next(field.options);
        obs.complete();
      }
    });
  }


  bindDistrictOptionsToCountry() {
    this.COUNTRY_FIELDS.forEach(countryId => {
      const predicate = countryId.replace('Country', '');
      const districtId = this.DISTRICT_FIELDS.find(d => d.includes(predicate));
      this.registrationForm.get(countryId).valueChanges
        .pipe(filter(v => !!v), distinctUntilChanged())
        .subscribe((id) => {
          this.foreignUserService.getDistrictList(id).then((res) => {
            const data = res.data.map((c) => {
              const translatedName = c[`name_${this.languageService.language.Code}`];
              return {
                label: !translatedName ? c.name : translatedName,
                value: c.id
              };
            });
            if (districtId) {
              const field = flatMap(this.rows).find(f => f.id === districtId);
              field.options = data;
            }
          });
        });
    });
  }

  get userType() {
    return this.registrationForm.get('userType').value;
  }
  private findCountryField(districtId) {
    const predicate = districtId.replace('District', '');
    const countryId = this.COUNTRY_FIELDS.find(d => d.includes(predicate));
    return this.registrationForm.get(countryId);
  }
  shouldShow(field) {
    if (field.id.includes('company') && this.userType !== 2) {
      return false;
    }
    return true;
  }
  handleFileInput(files: FileList, field) {
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        this.filesToUpload.push({
          file: files.item(i),
          field: field
      });
    }
  }
  }

  removeFileFromList(index) {
    this.filesToUpload.splice(index, 1);
  }

  emailValidator(control) {
    const validator = this.foreignUserService.checkEmail.bind(this.foreignUserService);
    return function (formControl): Observable<any> {
      const userType = formControl.parent && formControl.parent.get('userType');

      return new Observable((obs) => {
        validator(formControl.value).then(res => {
          // if true than email is not unique
          if (res && res.data) {
            obs.next({ emailNotUnique: true });
            obs.complete();
          }
          obs.next(null);
          obs.complete();
        });
      });
    };
  }
  corpotateEmailValidator(control) {
    const validator = this.foreignUserService.checkCorporateEmail.bind(this.foreignUserService);
    return function (formControl): Observable<any> {
      return new Observable((obs) => {
        if (!formControl.value) {
          obs.next(null);
          obs.complete();
          return;
        }
        validator(formControl.value).then(res => {
          // if true than email is not unique
          if (res && res.data) {
            obs.next({ emailNotUnique: true });
            obs.complete();
          }
          obs.next(null);
          obs.complete();
        });
      });
    };
  }

  shouldDisplayError(fieldId) {
    return (this.registrationForm.get(fieldId) && this.registrationForm.get(fieldId).touched) || this.submitted;
  }
  authoritySelected(ev) {
    this.selectedAuthority = ev;
  }

  moreThanYearsValidator(years) {
    return  (formControl: FormControl) => {
      if (formControl && formControl.value) {
        const date = moment(formControl.value);
        // ideally to take server date, beacause user can change system date
        const currentYear = moment(new Date());

        const yearDiff = currentYear.diff(date, 'year');
        if (yearDiff < years) {
          return {
            invalidAge: true
          };
        }
      }
      return null;
    };
  }

  currentDayOrDayInPast() {
    return  (formControl: FormControl) => {
      if (formControl && formControl.value) {
        const date = moment(formControl.value);
        const currentDay = moment(new Date());
        if (date.isAfter(currentDay)) {
          return {
            dateInFuture: true
          };
        }
      }
      return null;
    };
  }

  currentDayOrDayInFuture() {
    return  (formControl: FormControl) => {
      if (formControl && formControl.value) {
        const date = moment(formControl.value);
        const currentDay = moment(new Date());
        if (date.isBefore(currentDay)) {
          return {
            dateInPast: true
          };
        }
      }
      return null;
    };
  }
}
