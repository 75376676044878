import { Component, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { LoginService } from '../../../../Services/login.service';
import { Notification } from '../../../../Models';
import { ApiService } from '@dotgov/core';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';

@Component({
  selector: 'app-header-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { autoClose: false } }],
})
export class NotificationsComponent {
  @Input() notifications: Notification[] = [];
  @Output() clicked: EventEmitter<any> = new EventEmitter();
  @ViewChild('dropdown') dropdown;

  constructor(
    private api: ApiService,
    private loginService: LoginService,
  ) {
    document.body.addEventListener('click', (event: any) => {
      const inHost = Boolean((event.path || []).find((p: HTMLElement) => p.tagName === 'APP-HEADER-NOTIFICATIONS'));
      if (!inHost && this.dropdown) {
        this.dropdown.hide();
      }
    });
  }

  markAsRead(notification: Notification) {
    if (notification.Readed) {
      return;
    }
    this.api.post('notifications', ['markasread'], {}, { notifications: [notification.Id] }).then((res) => {
      if (!res) {
        return;
      }
      const not = this.notifications.find((_notification: Notification) => _notification.Id === notification.Id);
      if (!not) {
        return;
      }
      not.Readed = true;
    });
  }

  get logged() {
    return this.loginService.LOGIN_TYPE !== LoginService.NOT_LOGGED;
  }
}
