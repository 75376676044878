// Modules
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

/// Bootstrap
import { TabsModule } from 'ngx-bootstrap/tabs';
import {TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
const ngxBbootstrap = [
  TypeaheadModule.forRoot(),
  AccordionModule.forRoot(),
  BsDropdownModule.forRoot(),
  TabsModule.forRoot(),
];

import { BPMNModule } from './bpmn.module';
import { BootstrapComponent } from '../components/bootstrap/bootstrap.component';
import { FormsModule } from '@angular/forms';
import { DGSUICoreModule, DGSCoreModule } from '@dotgov/core';

@NgModule({
  declarations: [
    BootstrapComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BPMNModule,
    DGSCoreModule.forRoot({ apiUrl: '/' }),
    ...ngxBbootstrap,
    DGSUICoreModule,
  ],
  exports: [
    BootstrapComponent,
  ],
  providers: [],
  bootstrap: [BootstrapComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DevBPMNModule { }
