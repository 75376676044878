import { Component } from '@angular/core';
import { Link, BPMNStoreService } from '../../services/bpmn-store.service';

@Component({
  selector: 'bpmn-bootstrap',
  templateUrl: './bootstrap.component.html',
  styleUrls: ['./bootstrap.component.css'],
})
export class BootstrapComponent {
  loading: boolean;
  url: string;
  _urls: Link[];

  constructor(private store: BPMNStoreService) {
    this.store.listDiagrams().subscribe((links) => this.urls = links);
  }

  get urls(): Link[] {
    return this._urls;
  }

  set urls(urls: Link[]) {
    this._urls = urls;
    this.url = urls[0].href;
  }
}
