import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-404',
  templateUrl: './404.component.html',
  styleUrls: ['./404.component.scss'],
})
export class NotFoundComponent {
  constructor(activatedRoute: ActivatedRoute) {
    this._route = activatedRoute.url['value'].map((segment) => {
      return segment.path;
    }).join('/');
  }

  private _route = '';

  /**
   * @return {String}
   */
  get route(): string {
    return this._route;
  }
}
