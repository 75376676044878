import { Component, EventEmitter, Output } from '@angular/core';
import { DataService } from '../../../../Services/data.service';
import { User } from '../../../../Models/user';

@Component({
  selector: 'app-header-roles-selector',
  templateUrl: './roles-selector.component.html',
  styleUrls: ['./roles-selector.component.scss'],
})
export class RolesSelectorComponent {
  private userContext: User;

  @Output() clicked: EventEmitter<any> = new EventEmitter();

  constructor(
    private dataService: DataService,
  ) {
    this.loadUserData();
  }

  changeRole(role: string) {
    localStorage.setItem('activeRole', role);
    this.userContext.role = role;
    this.dataService.updateUserContext(this.userContext);
    this.clicked.emit();
  }

  get activeRole() {
    return this.userContext && this.userContext.role;
  }

  get roles() {
    const roles = this.userContext && this.userContext.roles;

    if (roles && roles.length > 1) {
      return roles;
    }
    return [];
  }

  private loadUserData() {
    this.dataService.userContextChanged.subscribe(this.resolveUserData.bind(this));
    this.dataService.userContext.then(this.resolveUserData.bind(this));
  }

  private resolveUserData(userContext: User) {
    const storedRole = localStorage.getItem('activeRole');
    if (storedRole && (userContext.roles || []).indexOf(storedRole) !== -1) {
      userContext.role = storedRole;
    }
    this.userContext = userContext;
  }
}
