import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'm-sign-response',
  templateUrl: './m-sign-response.component.html',
  styleUrls: ['./m-sign-response.component.scss'],
})
export class MSignResponseComponent {
  error: boolean;

  constructor(activatedRoute: ActivatedRoute) {
    activatedRoute.queryParams.subscribe((params: object) => {
      const signRequestId = params['signRequestId'];
      this.secureCheck(signRequestId);
    });
  }

  private secureCheck(signRequestId: string) {
    const err = this.error = window.opener === null || window.opener === undefined || !signRequestId;
    if (!err) {
      window.opener.postMessage({ ui: true, signRequestId }, '*');
      window.close();
      return;
    } else if (environment.debug) {
      console.log('This window does not have a parent.', err);
    }
  }
}
