import { Component } from '@angular/core';

@Component({
  selector: 'app-permits-issued-list',
  templateUrl: './permits-issued.component.html',
  styleUrls: ['./permits-issued.component.scss'],
})
export class PermitsIssuedComponent {
  itemsOnPage = 25;
  filter = {};

  constructor() { }

  updateItemsOnPage(onPage: number) {
    this.itemsOnPage = onPage;
  }

  filterChanged(filter) {
    this.filter = filter;
  }
}
