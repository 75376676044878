import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@dotgov/core';
import { LoginMetadata } from '../../Metadata/login';
import { NotificationService } from '../../Services/notification.service';
import { CookieHelper } from '../../Helpers/cookie.helper';

@Component({
  selector: 'app-mpasslogin',
  templateUrl: './mpass.component.html',
  styleUrls: ['./mpass.component.scss'],
})
export class MPassLoginComponent {
  samlRequestControl = '';
  relayStateidControl = '';
  actionUrl = '';
  mPassForm: FormGroup;

  private appData: Object = {};

  constructor(
    private apiService: ApiService,
    private notification: NotificationService,
    private router: Router,
  ) {
    this.apiService.get('app').then((appData) => {
      this.appData = appData.data;
    });

    this.mPassForm = new FormGroup({
      SAMLRequest: new FormControl(''),
      RelayState: new FormControl(''),
    });
  }

  proceed() {
    const catchHandler = (e) => {
      console.error(e);
      this.notification.error('Something went wrong, please try again later.');
    };
    this.apiService.get('login').then((_data) => {
      const data = _data.data;
      const samlRequest = data.samlRequest;
      const relayState = data.relayState;
      const actionUrl = data.url;
      CookieHelper.set(LoginMetadata.samlRequestIdKey, data.requestId, 1);
      if (!actionUrl) {
        return catchHandler(new Error('Action url not found.'));
      }
      this.samlRequestControl = samlRequest;
      this.relayStateidControl = relayState;
      this.actionUrl = actionUrl;
      // Submit outside of angular application
      setTimeout(() => {
        document.querySelector('form#mPassForm')['submit']();
      }, 1);
    }).catch(catchHandler);
  }

  back() {
    const redirectUrl = localStorage.getItem(LoginMetadata.redirectUrl);
    this.router.navigate([redirectUrl || '/']);
    localStorage.removeItem(LoginMetadata.redirectUrl);
  }

  get appTitle() {
    return this.appData['title'];
  }
}
