import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { RegistrationField } from '../../../Models/registration-field.model';
import { FormGroup, AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-same-as-fields',
  templateUrl: './same-as-fields.component.html',
  styleUrls: ['./same-as-fields.component.scss']
})
export class SameAsFieldsComponent implements OnInit, OnDestroy {

  @Input() field: RegistrationField;
  @Input() form: FormGroup;
  onDestroy$ = new Subject<boolean>();
  checked = false;
  control: AbstractControl;
  constructor() { }
  ngOnInit() {
    this.bindForFieldChanges();
    this.control = this.form.get(this.field.id);
  }

  copy(isChecked) {
    this.control.setValue(isChecked);
    this.checked = isChecked;
    this.field
    this.field.options.forEach((option) => {
      // strange behavior, local comes field 'id' from dev it comes as 'label'
      const fromId = option.label || option.id;
      const toId = option.value;
      const from  = this.form.get(fromId);
      const to = this.form.get(toId);
      if (isChecked) {
        to.setValue(from.value);
      } else {
        to.reset();
      }
    });
  }

  bindForFieldChanges () {
    // uncheck if after copy some of fields where changed manually
    const fields = this.field.options.map(o => this.form.get(o.value));
    fields.forEach((field) => {
      field.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(v => {
        if (this.checked && field.dirty) {
          this.checked = false;
        }
      });
    });

  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
