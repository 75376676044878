import { PdfWidgetComponent } from './../../Components/CustomControls/pdf-widget/pdf-widget.component';
import { environment } from '../../../environments/environment';

import { NgModule } from '@angular/core';
import { LazyRoutingModule } from './lazy.routing';
import { FormViewerModule, ProcessService, FVLanguageService } from '@dotgov/formviewer';
import { ModalModule } from 'ngx-bootstrap/modal';

import { SharedModule } from '../shared/shared.module';

import { IDGSEnvironment, DGSNotificationService, IDGSNotification, Lang, DGSCoreModule, DGSUICoreModule } from '@dotgov/core';
import { NotificationService } from '../../Services/notification.service';
import { LanguageService } from '../../Services/language.service';
import { LoginService } from '../../Services/login.service';

import { ContainerControlComponent } from '../../Components/container-control/container-control.component';
import { MyCertificatesComponent } from '../../Components/my-certificates/my-certificates.component';
import { ApplicantInfoComponent } from '../../Components/applicant-info/applicant-info.component';
import { PermitModalComponent } from '../../Components/Permit/modal/modal.component';
import { ProfileComponent } from '../../Components/Profile/profile.component';
import { PermitComponent } from '../../Components/Permit/permit.component';
import { CommonModule } from '@angular/common';
import { HomeComponent } from '../../Components/Home/home.component';
import { PermitsIssuedComponent } from '../../Components/permits-issued/permits-issued.component';
import { MSignResponseComponent } from '../../Components/CustomControls/m-sign-response/m-sign-response.component';
import { MPayResponseComponent } from '../../Components/CustomControls/m-pay-response/m-pay-response.component';
import { SupDockCheckComponent } from '../../Components/CustomControls/sup-docs-check/sup-docs-check.component';
import { MSignComponent } from '../../Components/CustomControls/m-sign/m-sign.component';
import { MPayComponent } from '../../Components/CustomControls/m-pay/m-pay.component';
import { BPMNComponent } from '../../Components/bpmn/bpmn.component';
import { TranslateModule } from '@ngx-translate/core';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BPMNModule } from '@dotgov/processbuilder';
import { DataGridModule } from '@dotgov/datagrid';
import { NgxPaginationModule } from 'ngx-pagination';
import { RegistrationComponent } from '../../Components/Registration/registration.component';
import { RegisterCompanyComponent } from '../../Components/RegisterCompany/register-company.component';
import { DataService } from '../../Services/data.service';

const fvEnviroment: IDGSEnvironment = {
  debug: environment.debug,
  apiUrl: environment.apiUrl,
  defaultLanguage: {
    Name: 'English',
    Code: 'en',
    Icon: 'fa fa-globe',
  },
  languages: [
    {
      Name: 'English',
      Code: 'en',
      Icon: 'fa fa-globe',
    },
    {
      Name: 'Romanian',
      Code: 'ro',
      Icon: 'fa fa-globe',
    },
    {
      Name: 'Russian',
      Code: 'ru',
      Icon: 'fa fa-globe',
    }],
  lowerCaseTranslates: true,
  formBuilder: false,
};


const customControlsComponentFactories = [
  {
    selector: 'pdf-widget',
    factoryClass: PdfWidgetComponent},
  {
    selector: 'm-sign',
    factoryClass: MSignComponent},
  {
    selector: 'm-sign-response',
    factoryClass: MSignResponseComponent},
  {
    selector: 'm-pay-response',
    factoryClass: MPayResponseComponent},
  {
    selector: 'm-pay',
    factoryClass: MPayComponent
  }
];

@NgModule({
  declarations: [
    ContainerControlComponent,
    MyCertificatesComponent,
    ApplicantInfoComponent,
    MSignResponseComponent,
    MPayResponseComponent,
    SupDockCheckComponent,
    PermitModalComponent,
    PdfWidgetComponent,
    ProfileComponent,
    PermitComponent,
    MSignComponent,
    MPayComponent,
    BPMNComponent,
    RegistrationComponent,
    RegisterCompanyComponent
  ],
  imports: [
    FormViewerModule.forRoot(fvEnviroment, customControlsComponentFactories),
    DGSCoreModule.forRoot(fvEnviroment),
    DGSUICoreModule,
    BPMNModule.forRoot(fvEnviroment),
    TranslateModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    DataGridModule.forRoot({}),
    PaginationModule.forRoot(),
    SharedModule,
    LazyRoutingModule,
    CommonModule,
    NgxPaginationModule  ],
  providers: [
    ProcessService,
  ],
  entryComponents: [
    RegistrationComponent,
    RegisterCompanyComponent,
    ContainerControlComponent,
    MyCertificatesComponent,
    ApplicantInfoComponent,
    MSignResponseComponent,
    PermitsIssuedComponent,
    MPayResponseComponent,
    SupDockCheckComponent,
    PermitModalComponent,
    PdfWidgetComponent,
    ProfileComponent,
    PermitComponent,
    MSignComponent,
    MPayComponent,
    HomeComponent

  ],
})
export class LazyModule {
  constructor(
    private fvLanguageService: FVLanguageService,
    private languageService: LanguageService,
    private dgsNotification: DGSNotificationService,
    private loginService: LoginService,
    private dataService: DataService,
    private notification: NotificationService,
    private process: ProcessService,
  ) {
    this.dgsNotification.subscription((notify: IDGSNotification) => {
      const allowedTypes = ['error', 'info', 'success', 'warning'];
      if (allowedTypes.indexOf(notify.type) !== -1) {
        this.notification[notify.type](notify.message, notify.title);
      }
    });
    const setupLang = (lang: Lang) => {
      this.fvLanguageService.addTranslate(lang, this.languageService.translations[lang.Code]);
    };
    setupLang(this.languageService.language);
    this.languageService.languageChanged.subscribe(setupLang);
    this.process.user = this.loginService.lastUserContext;
    this.dataService.userContextChanged.subscribe((userContext) => {
      this.process.user = userContext;
    });
  }
}
