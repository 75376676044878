
export class SVGDownloader {
  constructor() { }

  public static ExportSVG(svg, fileName) {
    const clone = svg.cloneNode(true);
    const svgDocType = document.implementation.createDocumentType('svg', "-//W3C//DTD SVG 1.1//EN", "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd");
    const svgDoc = document.implementation.createDocument('http://www.w3.org/2000/svg', 'svg', svgDocType);
    svgDoc.replaceChild(clone, svgDoc.documentElement);
    const svgData = (new XMLSerializer()).serializeToString(svgDoc);
    const a = document.createElement('a');
    a.href = 'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(svgData.replace(/></g, '>\n\r<'));
    a.download = `${fileName}.svg`;
    a.innerHTML = 'download the svg file';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
}