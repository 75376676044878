import { AbstractProperty } from './abstract.tab';

import * as idProps from 'bpmn-js-properties-panel/lib/provider/bpmn/parts/IdProps';
import * as nameProps from 'bpmn-js-properties-panel/lib/provider/bpmn/parts/NameProps';
import * as processProps from 'bpmn-js-properties-panel/lib/provider/bpmn/parts/ProcessProps';
import * as eventProps from 'bpmn-js-properties-panel/lib/provider/bpmn/parts/EventProps';
import * as linkProps from 'bpmn-js-properties-panel/lib/provider/bpmn/parts/LinkProps';
import * as documentationProps from 'bpmn-js-properties-panel/lib/provider/bpmn/parts/DocumentationProps';
import { AbstractBPMNControl } from './custom-controls';
import { get } from 'lodash';

const bpmParts = {
  idProps,
  nameProps,
  processProps,
  eventProps,
  linkProps,
  documentationProps,
};

export class GeneralTab extends AbstractProperty {
  constructor(element, elementRegistry, bpmnFactory, modeler, translate) {
    super(element, elementRegistry, bpmnFactory, modeler, translate, 'GeneralTab', 'General');

    this.groups = [this.generalGroup, this.detailsGroup, this.documentationGroup, this.styleGroup];
  }

  get debug() {
    return localStorage.getItem('___bpmn____debug__');
  }

  get generalGroup() {
    const group = {
      id: 'general',
      label: 'General',
      entries: [],
    };

    if (this.debug) {
      group.label += ` (${this.element && this.element.type})`;
    }

    bpmParts.idProps(group, this.element, this.translate, this.elementRegistry);
    bpmParts.nameProps(group, this.element, this.bpmnFactory, null, this.translate);
    bpmParts.processProps(group, this.element, this.translate);
    return group;
  }

  get detailsGroup() {
    const group = {
      id: 'details',
      label: 'Details',
      entries: [],
    };

    bpmParts.linkProps(group, this.element, this.translate);
    bpmParts.eventProps(
      group,
      this.element,
      this.bpmnFactory,
      this.elementRegistry,
      this.translate,
    );
    return group;
  }

  get documentationGroup() {
    const group = {
      id: 'documentation',
      label: 'Documentation',
      entries: [],
    };

    bpmParts.documentationProps(group, this.element, this.bpmnFactory, this.translate);
    return group;
  }

  get styleGroup() {
    const group = {
      id: 'style',
      label: 'Styles',
      entries: [],
    };

    this.styleProps(group);
    return group;
  }

  private styleProps(group) {
    if (!this.element || !this.element.type || this.element.type !== 'bpmn:SequenceFlow') {
      return;
    }
    const openBuilderBtn = new AbstractBPMNControl(
      this.element,
      'colorSelector',
      'color',
      'Vector Color (todo)',
      'color',
      this.targetField,
      { customValue: get(this.element, 'businessObject.di.stroke', '') },
    );
    openBuilderBtn.inputChanged.subscribe((color) => {
       const modeling = this.modeler.get('modeling');
       modeling.setColor(this.element, {stroke: color});
     });
    group.entries.push(openBuilderBtn.control);
  }
}
