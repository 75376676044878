
import {debounceTime} from 'rxjs/operators';
import {
  Component,
  ChangeDetectorRef,
  AfterViewInit,
  ElementRef,
  HostListener,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import { ApiService, ApiResponse } from '@dotgov/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LanguageService } from '../../../Services/language.service';
import { Lang } from '../../../Models/language';

import { ApiHelper } from '../../../Helpers/api.helper';

@Component({
  selector: 'app-home-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class HomeFilterComponent implements AfterViewInit {
  authorities: Object[] = [];
  permitTypes: Object[] = [];
  bussinessActivities: Object[] = [];
  resultsPerPage: Object[] = [
    { value: '10', label: '10' },
    { value: '15', label: '15' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
  ];
  maxWidth = '100%';
  loading = true;
  pageWidth: number;
  isCollapsed: boolean;

  // Selected inputs
  searchText: string;
  authorityId: number;
  permitTypeId: number;
  displayPerPage = '15';

  _bActivities: Object[] = [];

  filterForm: FormGroup;

  @Input('showBA') showBA: boolean;

  @Output() itemsOnPage: EventEmitter<any> = new EventEmitter();
  @Output() filterChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    private apiService: ApiService,
    private el: ElementRef,
    private ref: ChangeDetectorRef,
    private language: LanguageService,
  ) {
    Promise.all([
      this.loadAuthorities(this.language.language),
      this.loadDocumentTypes(),
      this.loadBusinessActivities(this.language.language),
    ]).then(() => {
      this.languageSubscribe();
      this.initFilter();
      this.loading = false;
    });
  }

  ngAfterViewInit() {
    this.onResize(window);
  }

  clearFilters() {
    this.filterForm.setValue({ searchControl: null, authorityControl: null, typeOfPermitControl: null, bussinessActivitiesControl: null });
    this.authorityId = null;
    this.permitTypeId = null;
    this.searchText = null;
  }

  updateItemsOnPage(onPage) {
    this.itemsOnPage.emit(onPage);
  }

  activityName(activityId) {
    return (this._bActivities.find((ba) => ba['ID'] === activityId) || {})['Name'];
  }

  removeActivity(activityId) {
    const activeBussinessActivities = this.filterForm.value['bussinessActivitiesControl'];
    this.filterForm.controls['activeBussinessActivities'].setValue(activeBussinessActivities.filter(_id => _id !== activityId));
  }

  collapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  get isMobile() {
    return Number(this.pageWidth) <= 991;
  }

  get activeBussinessActivities() {
    return this.filterForm && this.filterForm.value['bussinessActivitiesControl'];
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.pageWidth = event && event.innerWidth || event.target.innerWidth;
    this.maxWidth = `${this.el.nativeElement.offsetWidth}px`;
    this.isCollapsed = this.isMobile;
    this.ref.detectChanges();
  }

  private loadBusinessActivities(lang: Lang) {
    const defaultParams = ApiHelper.setupLanguage({ page: 0, limit: 0, start: 0 }, lang);
    return this.apiService.get('public', [`data`, `BusinessActivities`], defaultParams).then((response: ApiResponse) => {
      const bActivities = response.data && response.data.data || [];
      this._bActivities = bActivities;
      this.bussinessActivities = (bActivities || []).map((bActivity) => {
        return { value: bActivity.ID, label: bActivity.Name };
      });
      return bActivities;
    });
  }

  private languageSubscribe() {
    this.language.languageChanged.subscribe((lang: Lang) => {
      this.loading = true;
      this.clearFilters();
      Promise.all([
        this.loadAuthorities(lang),
        this.loadDocumentTypes(),
        this.loadBusinessActivities(lang),
      ]).then(() => {
        this.loading = false;
      });
    });
  }

  private loadAuthorities(lang?: Lang) {
    const defaultParams = ApiHelper.setupLanguage({ page: 0, limit: 0, start: 0 }, lang);
    return this.apiService.get('public', [`data`, `AvailableAuthorities`], defaultParams).then((response) => {
      const authorities = response.data && response.data.data || [];
      this.authorities = authorities.map((authority) => {
        const label = authority.Name || authority[`Name_${lang.Code}`];
        return { value: authority.ID, label };
      });
    });
  }

  private loadDocumentTypes() {
    const defaultParams = { page: 0, limit: 0, start: 0 };
    return this.apiService.get('public', ['data', `DocumentTypes`], defaultParams).then((response: ApiResponse) => {
      const permitTypes = response.data;
      const promises = permitTypes.data.map((permitType) => {
        return this.language.getTranslate(permitType.Name).then((label) => {
          return { value: permitType.ID, label };
        });
      });
      Promise.all(promises).then((translatedPermitTypes) => {
        this.permitTypes = translatedPermitTypes;
        return translatedPermitTypes;
      });
    });
  }

  private initFilter() {
    this.filterForm = new FormGroup({
      searchControl: new FormControl(null),
      authorityControl: new FormControl(null),
      typeOfPermitControl: new FormControl(null),
      bussinessActivitiesControl: new FormControl(null),
    });

    this.filterForm.valueChanges.pipe(debounceTime(250)).subscribe((value) => {
      this.filterChanged.emit(value);
    });
  }
}
