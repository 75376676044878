import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ForeignUserService } from '../../Services/foreign-user.service';
import { LanguageService } from '../../Services/language.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-select-authority',
  templateUrl: './select-authority.component.html',
  styleUrls: ['./select-authority.component.scss']
})
export class SelectAuthorityComponent implements OnInit, OnDestroy {
  nodes = [];
  data: any[];
  selectedAuthority;
  onChange: any = () => {}
  onTouch: any = () => {}
  onDestroy$ = new Subject<boolean>();
  @Output() selected = new EventEmitter<string>();

  constructor(private foreignUserService: ForeignUserService, private languageService: LanguageService) { }

  options = {
    useCheckbox: false
  };

  ngOnInit() {
    this.foreignUserService.getAvailableAuthorities().then(res => {
      if (res && res.data) {
        this.data = res.data;
        this.setData();
      }
    });

    this.languageService.languageChanged.pipe(takeUntil(this.onDestroy$))
    .subscribe(() => {
      this.setData();
    });
  }

  setData() {
    this.nodes = [];
    const language = this.languageService.language;
    this.data.forEach(authority => {
      this.nodes.push({
        id: authority.teamId,
        name: authority['authorityName_'+ language.Code],
        children: authority.foreignPermitsList.map((p) => {return { id: p.permitId, name: p['permitName_' + language.Code]} })
      })
    })
  }

  onAuthoritySelect($event) {
     const node = $event.node;
     let data = node.data;
     if(node.isLeaf) {
        data = node.parent.data;
     }
    this.selectedAuthority = data.id;
    this.selected.emit(this.selectedAuthority);
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

}
