import { Component, AfterViewInit, ViewChild, ViewContainerRef, HostListener } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FVAbstractControl, ProcessService, Control } from '@dotgov/formviewer';
import { NotificationService } from '../../../Services/notification.service';
import { ApiService, ApiResponse, IDGSChangeSet } from '@dotgov/core';

interface ISignResponse {
  actionUrl?: string;
  returnUrl?: string;
  relayState?: number;
  signRequestId?: string;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'm-sign',
  templateUrl: './m-sign.component.html',
  styleUrls: ['./m-sign.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class MSignComponent extends FVAbstractControl implements AfterViewInit {
  signInProgress: boolean;
  verifyInProgress: boolean;
  signatureStatus: string;

  pdfControl: Control;
  showDocument: boolean;

  @ViewChild('frmSign', { read: ViewContainerRef }) frmSign;

  private _fieldData: Object = {};
  private _responseData: ISignResponse = {};

  constructor(
    private process: ProcessService,
    private api: ApiService,
    private notification: NotificationService,
  ) {
    super(process);
  }

  ngAfterViewInit() {
    this.process.changes = { changeName: 'pdfHide', changeVal: true };
    this.fieldNames.forEach((field: { name: string, key: string }) => {
      this._fieldData[field.name] = field.key;
    });
    this.pdfControl = this.process.get('control-pdf-widget');

    this.process.onChange.subscribe((changeSet: IDGSChangeSet) => {
      if (changeSet.changeName === 'm-sign' && this.formControl) {
        this.formControl.setValue(changeSet.changeVal || undefined);
      }
    });
    const fileId = (this.task.componentConfig || {})['recordData']['FileId'];
    this.api.get('file_is_signed', [fileId], {}, { skipCache: true }).then((response: ApiResponse) => {
      if (response.error) {
        this.warn('Failed to load mpay service url', response.error);
        return;
      }
      this.formControl.setValue(Boolean(response.data));
    });
  }

  @HostListener('window:message', ['$event'])
  onMessage(event) {
    if (!event.data || !event.data.ui) {
      return;
    }
    if (!event.data.signRequestId) {
      this.notification.warning('Unexpected sign request result data.');
      this.signatureStatus = 'Unknown. Unexpected sign request result data';
      this.signInProgress = false;
      return;
    }
    this.signRequestDone(event.data.signRequestId);
  }

  sign() {
    const body = {
      caseId: this.recordData['ID'],
      fileId: this.recordData['FileId'],
      fileTableName: this.recordData['FileTableName'],
      fileTableField: this.recordData['FileTableField'],
      documentPurposeTypeId: this.recordData['DocumentPurposeTypeId'],
    };
    this.api.post('sign_request', [], {}, body, { skipCache: true }).then((response: ApiResponse) => {
      const error = this.process.errorFromResponse(response);
      if (error) {
        this.notification.error(error);
        return;
      }
      this.log('Sign request data: ', response);
      this._responseData = <ISignResponse>(response.data);

      this.signInProgress = true;
      setTimeout(() => {
        this.frmSign.element.nativeElement.submit();
        this.log('Submitting m-sign form.');
      }, 0);
    });
  }

  get formControl() {
    return this.group.controls[(this.control || {})['key']];
  }

  get fieldNames() {
    return this.control['fieldNames'];
  }

  get response() {
    return this._responseData;
  }

  private signRequestDone(signRequestId) {
    if (!signRequestId) {
      return;
    }
    this.api.get('sign_status', [signRequestId]).then((response: ApiResponse) => {
      const error = this.process.errorFromResponse(response);
      if (error || !response.data) {
        this.signInProgress = false;
        this.signatureStatus = 'Unknown';
        this.error('Sign failed', error);
        this.process.changes = { changeName: 'm-sign', changeVal: false };
        return;
      }
      this.signInProgress = false;
      this.signatureStatus = this.getStatus(response.data.status);
      this.process.changes = { changeName: 'm-sign', changeVal: true };
    });
  }

  private getStatus(status) {
    let result = 'Undefined';
    switch (status) {
      case 0:
        result = 'Pending';
        break;
      case 1:
        result = 'Signed';
        break;
      case 2:
        result = 'Failure';
        break;
      case 3:
        result = 'Timeout';
        break;
    }
    return result;
  }
}

