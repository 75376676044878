import { OnDestroy, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class RecaptchaCheckService implements OnDestroy {
  isHuman = new Subject<boolean>();
  get siteKey(): string {
    return environment.recaptchaSiteKey;
  }
  constructor() {}

  // onError() {
  // }

  onSuccess() {
    this.isHuman.next(true);
  }

  execute(component: InvisibleReCaptchaComponent) {
    if (component.getResponse()) {
      this.isHuman.next(true);
    } else {
      component.execute();
    }
    return this.isHuman.pipe(first());
  }

  ngOnDestroy(): void {
    this.isHuman.complete();
  }
}
