import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@dotgov/core';


@Component({
  selector: 'app-mpasslogin',
  templateUrl: './foreignregsuccess.component.html',
  styleUrls: ['./foreignregsuccess.component.scss'],
})
export class ForeignRegSuccessComponent {
  private appData: Object = {};

  constructor(
    private apiService: ApiService,
    private router: Router,
  ) {
    this.apiService.get('app').then((appData) => {
      this.appData = appData.data;
    });
  }

  proceed() {
    this.router.navigate(['/']);
  }

  get appTitle() {
    return this.appData['title'];
  }
}
