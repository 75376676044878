import { Component } from '@angular/core';

@Component({
  selector: 'app-permit-list',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  showBusinessActivity: boolean;
  itemsOnPage = 15;
  filter = {};

  constructor() { }

  updateItemsOnPage(onPage: number) {
    this.itemsOnPage = onPage;
  }

  filterChanged(filter) {
    this.filter = filter;
  }
}
