import { IPalette, IPaletteProvider } from './bpmn-js';

export class CustomPaletteProvider implements IPaletteProvider {
  static $inject = [
    'palette',
    'originalPaletteProvider',
    'create',
    'elementFactory',
    'spaceTool',
    'lassoTool',
    'commandQueue',
  ];

  // Note that names of arguments must match injected modules, see InjectionNames.
  // I don't know why originalPaletteProvider matters but it breaks if it isn't there.
  // I guess since this component is injected, and it requires an instance of originalPaletteProvider,
  // originalPaletteProvider will be new'ed and thus call palette.registerProvider for itself.
  // There probably is a better way.
  constructor(
    protected palette: IPalette,
    protected originalPaletteProvider: IPaletteProvider,
    protected create: any,
    protected elementFactory: any,
    protected spaceTool: any,
    protected lassoTool: any,
    protected commandQueue: any,
  ) {
    palette.registerProvider(this);
  }

  createAction(
    type: string,
    group: string,
    className: string,
    title: string = null,
    options: any = {},
  ) {
    const createListener = (event: any) => {
      const shape = this.elementFactory.createShape(Object.assign({ type }, options));
      if (options) {
        shape.businessObject.di.isExpanded = options.isExpanded;
      }
      this.create.start(event, shape);
    };
    return {
      group,
      className,
      title: title || 'Create ' + type.replace(/^bpmn\:/, ''),
      action: {
        dragstart: createListener,
        click: createListener,
      },
    };
  }

  createParticipant(event: any, collapsed: any) {
    this.create.start(event, this.elementFactory.createParticipantShape(collapsed));
  }

  getPaletteEntries() {
    return {
      'view.zoom-to-fit': {
        group: 'view',
        className: ['fa-binoculars', 'fa'],
        title: 'Zoom-To-Fit',
        action: {
          click: () => this.commandQueue.next({ action: 'reset-zoom' }),
        },
      },
      'storage.save': {
        group: 'storage',
        className: ['fa-floppy-o', 'fa'],
        title: 'Save',
        action: {
          click: () => this.commandQueue.next({ action: 'save' }),
        },
      },
      'storage.export-svg': {
        group: 'storage',
        className: ['fa-print', 'fa'],
        title: 'export to SVG',
        action: {
          click: () => this.commandQueue.next({ action: 'export' }),
        },
      },
      'create.start-event': this.createAction(
        'bpmn:StartEvent',
        'event',
        'bpmn-icon-start-event-none',
      ),
      'create.intermediate-event': this.createAction(
        'bpmn:IntermediateThrowEvent',
        'event',
        'bpmn-icon-intermediate-event-none',
      ),
      'create.end-event': this.createAction('bpmn:EndEvent', 'event', 'bpmn-icon-end-event-none'),
      'tool-separator-event': {
        group: 'event',
        separator: true,
      },
      'create.exclusive-gateway': this.createAction(
        'bpmn:ExclusiveGateway', 'gateway', 'bpmn-icon-gateway-xor',
      ),
      'create.gateway-none': this.createAction('bpmn:Gateway', 'gateway', 'bpmn-icon-gateway-none'),
      'tool-separator-gateway': {
        group: 'gateway',
        separator: true,
      },
      'create.subprocess-expanded': this.createAction(
        'bpmn:SubProcess',
        'advanced',
        'bpmn-icon-subprocess-expanded',
        'Create expanded SubProcess',
        { isExpanded: true },
      ),
      'create.subprocess-collapsed': this.createAction(
        'bpmn:SubProcess',
        'advanced',
        'bpmn-icon-subprocess-collapsed',
        'Create collapsed SubProcess',
        { isExpanded: false },
      ),
      'create.participant-expanded': {
        group: 'advanced',
        className: 'bpmn-icon-participant',
        title: 'Create Pool/Participant',
        action: {
          dragstart: this.createParticipant.bind(this),
          click: this.createParticipant.bind(this),
        },
      },
      'create.user-task': this.createAction(
        'bpmn:UserTask',
        'activity',
        'bpmn-icon-user-task',
        'UserTask',
      ),
      'create.send-task': this.createAction(
        'bpmn:SendTask',
        'activity',
        'bpmn-icon-send-task',
        'SendTask',
      ),
      'create.script-task': this.createAction(
        'bpmn:ScriptTask',
        'activity',
        'bpmn-icon-service-task',
        'ScriptTask',
      ),
      'tool-separator-activity': {
        group: 'advanced',
        separator: true,
      },
    };
  }
}
