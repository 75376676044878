import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../../Services/notification.service';
import { LoginService } from '../../Services/login.service';
import { LoginMetadata } from '../../Metadata/login';
import { CookieHelper } from '../../Helpers/cookie.helper';
import { ERROR_MESSAGES } from '../../Models';
import { LanguageService } from '../../Services/language.service';

@Component({
  selector: 'app-mpassloggedin',
  templateUrl: './mpass-loggedin.component.html',
  styleUrls: ['./mpass-loggedin.component.scss'],
})
export class MPassLoggedinComponent {
  constructor(
    private router: Router,
    private notification: NotificationService,
    private loginService: LoginService,
    private languageService: LanguageService
    ) {
    // Timeout used casue somethimes user is logged out after login
    setTimeout(() => {
      const loginResults = this.loadLoginResults();
      this.getMPassLoggedIn(loginResults);
    }, 100);
  }

  loadLoginResults(): Object {
    const getThenRemoveCookie = (name: string): any => {
      const data = CookieHelper.get(name);
      CookieHelper.remove(name);
      return data;
    };
    const samlRequestId = getThenRemoveCookie(LoginMetadata.samlRequestIdKey);
    const relayState = getThenRemoveCookie(LoginMetadata.samlRelayStateKey);
    const partsCount = getThenRemoveCookie(LoginMetadata.samlResponsePartsCountKey);
    let samlResponse = '';
    if (partsCount !== 'undefined' && partsCount !== null && partsCount !== '') {
      const count = Number(partsCount);
      for (let i = 0; i < count; i++) {
        const part = getThenRemoveCookie(`${LoginMetadata.samlResponsePrefixKey}${i}`);
        samlResponse += part;
      }
    }

    return { RequestId: samlRequestId, RelayState: relayState, SAMLResponse: samlResponse };
  }

  getMPassLoggedIn(loginResults) {
    const redirectUrl = localStorage.getItem(LoginMetadata.redirectUrl);
    const redirect = () => {
      this.router.navigate([redirectUrl || '/']);
      localStorage.removeItem(LoginMetadata.redirectUrl);
    };

    this.loginService.getMPassLoggedIn(loginResults).then((result) => {
      if (result !== 'Canceled') {
        this.loginService.loggedBy = LoginService.MPASS_LOGIN;
        this.notification.success('MPass user login ok', 'Success!');
      }
      redirect();
      this.loginService.checkForPasswordReset();
    }).catch(async (e) => {
      this.notification.error( await this.extractAndTranslateError(e) || 'MPass login failed!');
      redirect();
    });
  }

  private async extractAndTranslateError(errorMessage) {
    if (!errorMessage) {
      return '';
    }

    const emailAlreadyTakenRegex = /^Email '((?!\.)[\w-_.]*[^.]@\w+\.\w+(\.\w+)?[^.\W])' is already taken\./i;

    const emailTakenError = emailAlreadyTakenRegex.exec(errorMessage);
    if (emailTakenError) {
      const email = emailTakenError[1];
      const translatedMessage = await this.languageService.getTranslate(ERROR_MESSAGES.emailAlreadyTaken);
      return translatedMessage.replace('%s', email);
    }


    return errorMessage;
  }
}
