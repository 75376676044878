import { Component } from '@angular/core';
import { ApiService } from '@dotgov/core';
import { NotificationService } from '../../Services/notification.service';
import { LoginService } from '../../Services/login.service';
import { DataService } from '../../Services/data.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-MyCertificates',
  templateUrl: './my-certificates.component.html',
  styleUrls: ['./my-certificates.component.scss'],
})
export class MyCertificatesComponent {
  loading = true;
  certificates: any;

  constructor(
    private api: ApiService,
    private notification: NotificationService,
    private dataService: DataService
  ) {
    this.fetchData();
    this.dataService.currentUserTeamChanged.subscribe(() => {
      this.fetchData(true);
    });
  }

  onTaskDone(taskStatus: any = {}) {
    if (!taskStatus.done) {
      return;
    }
    this.notification.success('Permit successfully closed.');
  }

  private fetchData(skipCache = false) {
    this.loading = true;
    const endpoint = !this.dataService.currentUserTeam ? 'MyCertificates' : 'CorporateCertificates';
    this.api.get('profilemenu1', [endpoint],{}, {skipCache}).then((certificates) => {
      this.certificates = certificates.data;
      this.loading = false;
    });
  }
}
