// Modules
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

/// Bootstrap
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
const ngxBbootstrap = [
  TypeaheadModule.forRoot(),
  AccordionModule.forRoot(),
  BsDropdownModule.forRoot(),
  TabsModule.forRoot(),
  ModalModule.forRoot(),
];

// Utils
import 'rxjs/Subject';

import { BPMNStoreService } from '../services/bpmn-store.service';
import { HttpClientModule } from '@angular/common/http';
import { BPMNModelerComponent } from '../components/modeler/modeler.component';
import { DGSCoreModule, DGSUICoreModule } from '@dotgov/core';
import { BuilderComponent } from '../components/builder/builder.component';
import { BuilderService } from '../services/builder.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    BPMNModelerComponent,
    BuilderComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    DGSCoreModule,
    DGSUICoreModule,
    TranslateModule.forChild(),
    ...ngxBbootstrap,
  ],
  providers: [
    BPMNStoreService,
    BuilderService,
  ],
  exports: [
    BPMNModelerComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BPMNModule {
  public static forRoot(_enviroment?: Object): ModuleWithProviders<BPMNModule> {
    return {
      ngModule: BPMNModule,
      providers: [
        BPMNStoreService,
        BuilderService,
        DGSCoreModule.forRoot(_enviroment).providers,
        { provide: 'enviroment', useValue: _enviroment },
      ],
    };
  }
}
