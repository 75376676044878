export class LoginMetadata {
  static get samlRequestIdKey() {
    return 'EP_samlRequestID_key';
  }

  static get samlRelayStateKey() {
    return 'RelayState';
  }

  static get samlResponsePrefixKey() {
    return 'SAMLResponse_';
  }

  static get samlResponsePartsCountKey() {
    return 'SAMLResponsePartsCount';
  }

  static get externalSessionIndexKey() {
    return 'externalSessionIndex';
  }

  static get userContext() {
    return 'userContext';
  }

  static get redirectUrl() {
    return 'redirectUrl';
  }

  static get canceled() {
    return 'canceled';
  }
}
