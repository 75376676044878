import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ForeignUserService } from '../../Services/foreign-user.service';
import { NotificationService } from '../../Services/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements AfterViewInit, OnDestroy {
  submitted: boolean = false;
  resetPwdForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private foreignUserService: ForeignUserService,
    private notificationService: NotificationService, private router: Router) { }


  ngOnInit() {
    this.resetPwdForm = this.formBuilder.group({
      secretWord: ['', [Validators.required]],
      currentPassword: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: this.MustMatch('password', 'confirmPassword')
    });
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
  }

  // convenience getter for easy access to form fields
  get f() { return this.resetPwdForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetPwdForm.invalid) {
      return;
    }
    this.foreignUserService.resetPassword(this.resetPwdForm.value).then(res => {
      if (res && res.data && res.data.code == 200) {
        this.notificationService.success('Password resetted successfully!');
        this.router.navigate(['/login']);
      } else if (res && res.data) {
        this.notificationService.error(`${res.data.message}`);
      } else if (res.error) {
        this.notificationService.error(`${res.error.message}`);
      }
    }, (err) => console.log('on error', err));
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

}
