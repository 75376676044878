import { Lang } from '@dotgov/core';

export class ApiHelper {
  static setupLanguage(options: Object, lang?: Lang, forcedDefault?: boolean) {
    if (!lang || !lang.Code || lang.Code === 'en') {
      if (forcedDefault) {
        options['language'] = 'en';
      }
      return options;
    }
    options['language'] = lang.Code;
    return options;
  }
}
