import { Component, Output, EventEmitter } from '@angular/core';
import { LoginService } from '../../../../Services/login.service';
import { Link } from '../../../../Models/link';
import { DataService } from '../../../../Services/data.service';

@Component({
  selector: 'app-header-profile-selector',
  templateUrl: './profile-selector.component.html',
  styleUrls: ['./profile-selector.component.scss'],
})
export class ProfileSelectorComponent {
  private appData: Object = {};
  private _customLinks = [];

  @Output() clicked: EventEmitter<any> = new EventEmitter();

  constructor(
    private loginService: LoginService,
    private dataService: DataService,
  ) {
    this.loadAppData();
    this._customLinks = this.loadCustomLinks();
    this.loginService.loginChanged.subscribe((value) => {
      this._customLinks = this.loadCustomLinks(value);
    });
  }

  get customLinks() {
    return this._customLinks;
  }

  click() {
    this.clicked.emit();
  }

  get links() {
    return this.appData['links'];
  }

  private loadAppData() {
    this.dataService.appDataChanged.subscribe(this.resolveAppData.bind(this));
    this.dataService.appData.then(this.resolveAppData.bind(this));
  }

  private loadCustomLinks(loginType?: number) {
    const customLinks = [];
    loginType = loginType || this.loginService.LOGIN_TYPE;
    if (loginType === LoginService.NOT_LOGGED) {
      customLinks.push(new Link('fa fa-sign-in', 'mpasslogin', 'login', 'Mpass Login', 'PROFILE'));
      customLinks.push(new Link('fa fa-sign-in', 'login', 'login', 'Foreign Applicants', 'PROFILE'));

      // customLinks.push(new Link('fa fa-user-plus', 'register', '', 'Register for Foreign Applicants', 'PROFILE'));
    } else {

      if (loginType !== LoginService.MPASS_LOGIN) {
        customLinks.push(
          new Link(
            'fa fa-key',
            'changepwd',
            'changepwd',
            'Update password',
            'PROFILE')
        );
      }

      customLinks.push(
        new Link(
          'fa fa-sign-out',
          'logout',
          'logout',
          loginType === LoginService.CUSTOM_LOGIN ? 'Logout' : 'Mpass Logout',
          'PROFILE'),
      );
    }
    return customLinks;
  }

  private resolveAppData(appData) {
    this.appData = appData;
  }
}
