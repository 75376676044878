import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../Services/login.service';
import { NotificationService } from '../../Services/notification.service';
import { FormGroup, FormControl } from '@angular/forms';
import { ApiService } from '@dotgov/core';
import { Auth } from '../../Models';
import { LoginMetadata } from '../../Metadata/login';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent {
  samlRequestControl = '';
  relayStateidControl = '';
  actionUrl = '';
  mPassForm: FormGroup;

  constructor(
    private apiService: ApiService,
    private loginService: LoginService,
    private notification: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.mPassForm = new FormGroup({
      SAMLRequest: new FormControl(''),
      RelayState: new FormControl(''),
    });
    this.fetchRouteInfo();
  }

  private mpassLogoutHandler(): Promise<boolean> {
    const catchHandler = (e): any => {
      console.error(e);
      this.notification.error('Something went wrong, please try again later.');
      return e;
    };
    const auth: Auth = JSON.parse(localStorage.getItem('auth'));
    return this.apiService.get('logout', [], {}, {
      headers: {
        'x-Authorization': `${auth.tokenType} ${auth.token}`,
      },
    }).then((_data) => {
      const data = _data.data;
      if (!_data || !data.url) {
        const handler = catchHandler(new Error('Action url not found.'));
        return Promise.reject(handler);
      }
      this.samlRequestControl = data.samlRequest;
      this.relayStateidControl = data.relayState;
      this.actionUrl = data.url;
      return Promise.resolve(true);
    }).catch(catchHandler);
  }

  private logoutHandler() {
    if (this.loginService.LOGIN_TYPE === LoginService.MPASS_LOGIN) {
      return this.mpassLogoutHandler().then((mpassResult) => {
        if (!mpassResult) {
          return Promise.reject('Failed to logout from mpass.');
        }
        return this.localLogoutHandler(false);
      }).then(() => {
        // Submit outside of angular application
        setTimeout(() => {
          document.querySelector('form#mPassForm')['submit']();
          this.router.navigate(['/']);
        }, 1);
      });
    }
    return this.localLogoutHandler();
  }

  private localLogoutHandler(redirect: boolean = true) {
    return this.loginService.logout().then((response) => {
      this.notification.success('User logged out', 'Success!');
      if (redirect) {
        this.router.navigate(['/']);
      }
      return Promise.resolve(true);
    }).catch((e) => {
      console.log(e);
      if (redirect) {
        this.router.navigate(['/']);
      }
      this.notification.warning('User logged out, but with issues.', 'Almost ok');
      return Promise.reject(e);
    });
  }

  private fetchRouteInfo() {
    this.route.params.subscribe(params => {
      const session = params['session'];
      if (session && session === localStorage.getItem(LoginMetadata.externalSessionIndexKey)) {
        return this.localLogoutHandler().then(() => {
          window.close();
        });
      }
      this.logoutHandler();
    });
  }

}
