import { Component, EventEmitter, Output } from '@angular/core';
import { LanguageService } from '../../../../Services/language.service';
import { Lang } from '../../../../Models/language';

@Component({
  selector: 'app-header-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent {
  languages: Lang[] = [];

  @Output() clicked: EventEmitter<any> = new EventEmitter();

  constructor(
    private languageService: LanguageService,
  ) {
    this.languages = this.languageService.languages;
  }

  changeLang(lang: Lang) {
    this.languageService.language = lang;
    this.languageService.loadLanguageTranslation(lang);
    this.clicked.emit();
  }

  get activeLanguage(): Lang {
    return this.languageService.language;
  }
}
