import { Component, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Task, ProcessService } from '@dotgov/formviewer';
import { ApiService } from '@dotgov/core';
import { NotificationService } from '../../Services/notification.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements AfterViewInit {
  loading: boolean;
  error: string;
  currentTask: Task;

  @Input() profile;

  private processInstanceId: string;

  constructor(
    private process: ProcessService,
    private api: ApiService,
    private notification: NotificationService,
    private ref: ChangeDetectorRef,
  ) {
    this.process.onAnyError.subscribe((response: { processId: string, error: string }) => {
      if (response.processId !== this.processInstanceId && this.processInstanceId) {
        return;
      }
      this.error = response.error;
    });
  }

  ngAfterViewInit() {
    this.initTask();
  }

  taskDone(response: any = {}) {
    if (response.done) {
      this.notification.success('Personal data been successfully saved.');
    }
  }

  private initTask() {
    this.loading = true;
    this.ref.detectChanges();
    this.api.get('profilemenu1', [this.profile]).then((taskResponse) => {
      this.loading = false;
      this.currentTask = taskResponse.data;
      this.ref.detectChanges();
    });
  }
}
